import React, { useState } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useForm , Controller, FormProvider } from "react-hook-form";
import { Email_URL,BASE_URL } from '../components/helper'
function ImageUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const { register, watch,control,handleSubmit,setValue,reset, formState: { errors } } = useForm();
  
  const fileSelectedHandler = event => {
    setSelectedFile(event.target.files[0]);
    setPreviewUrl(URL.createObjectURL(event.target.files[0]));
  };
  
  const getUserFromToken = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return null;
    }
    try {
      const decoded = jwt_decode(token);
      return decoded;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  
  const fileUploadHandler = async (data) => {
    const formData = new FormData();
    formData.append('image', selectedFile);
    const user = getUserFromToken();
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'X-User-Data': JSON.stringify(user),
      },
    };
    try {
      const response = await axios.post('http://localhost:8080/api/check/upload', formData, config);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={fileSelectedHandler} />
      {previewUrl && (
        <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: 400 }} />
      )}
      <button onClick={handleSubmit(fileUploadHandler)}>Upload</button>
    </div>
  );
}

export default ImageUpload;
