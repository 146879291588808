import React from 'react'
import Dashboard_au from '../Dashboard_author/Dashboard_author'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import {Typography} from '@mui/material'
import {useMediaQuery} from '@mui/material'
import {TextField} from '@mui/material'
import { Button } from '@mui/material'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {Autocomplete} from '@mui/material'
import {ServicesE} from '../components/Services';
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Image} from 'mui-image'
function Instructor () {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const mobile = useMediaQuery('(max-width:600px)');
    const ipad = useMediaQuery('(min-width: 768px) and (max-width: 1180px)');
    const handleChange11 = (event, value) => {
        setSelectedOptions(value);
        navigate(`../${value}`);
    }
    const navigate = useNavigate();
    const handleExplore =  (e) => {
		navigate('/Editing')
	};
	
	const [showDialog, setShowDialog] = useState(false);
	const handleNext1= () => {
		navigate('/episode1');
	};
	const phoneNumber = '+91-7060495034';
	const emailAddress = 'Abhijeet@Hubhawks.com';
	const handleOpen = () => {
		setShowDialog(true);
	 }
	const handleDialog = () => {
		setShowDialog(false);
	 }
    const ServicesE = [
        
      ];
  return (
    <>
      <Dashboard_au/>
      <Box  sx={{ backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'auto',height:mobile?'1750px':'1100px'}}>
	  {mobile ? '' :<Box sx={{width:"78%",left: mobile?'24px':'272px',position: 'absolute',marginTop:'24px'}}>
				  <Grid container md={12} lg={12} sx={{ marginTop: "20px" }}>
				<Grid container md={6} lg={6}>
					<Grid item md={9}lg={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>MasterClass</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} lg={6} sx={{justifyContent:'end'}}>
					<Grid item md={6} lg={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={3} lg={3} sx={{display:'flex',justifyContent:"center"}}>
							  <Button onClick={handleOpen } variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
							  </Button>
							  <Dialog open={showDialog} onClose={handleDialog}>
        <DialogContent style={{backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)"}}>
							  <DialogContentText>
								  <Typography variant='h5'>Get in touch with us:</Typography>
	  <Button  href={`mailto:${emailAddress}`} style={{color:"black",textTransform:'none',fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><EmailOutlinedIcon />Abhijeet@Hubhawks.com</Button><br/>
	  		
	  <Button href={`tel:${phoneNumber}`} style={{color:"black",fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><PhoneInTalkOutlinedIcon/>+91-7060495034</Button>
					
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)"}}>
          <Button onClick={handleDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
					</Grid>
				</Grid>
				  </Grid>
		  </Box>}
            
			<Box component='form' width="1152px" height='750px' sx={{
				position: 'absolute',
width:mobile?'82%': '78%',

left: mobile?'24px':'32px',marginLeft:mobile?'24px':'240px',
top:mobile?'90px': '115px',

				borderRadius: '12px'
			  }}>
				  <Grid container lg={12} xs={12}>
					<Link to='../Masterclass'> <ArrowBackIcon style={{ color:'#333335',
						  marginBottom:"13px"}} /></Link> 
				  </Grid>
                <Grid container lg={12} xs={12} sx={{gridColumnGap:"10px"}} >
                <Grid container md={12} xs={12} lg={6}>
                <Grid item  xs={12} lg={12} md={10}  >

                <Image Duration={0}
              src="https://drive.google.com/uc?export=view&id=1pAQzoyrZegi-EHsOIkzuNHvqf9PXkgfo"
              style={{ transitionDuration:'0',animation:'0',width:mobile?'100%':"100%",height:mobile?'180px':"331px",borderRadius:"12px",marginTop:mobile?'26px':'0px',}}></Image>

        </Grid>
						  {mobile ? "" : <>
		<Grid container md={12} xs={12} lg={11}>
                <Grid item  xs={12} lg={12} md={10} sx={{margin:'auto'}} >
                <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'16px':'16px',marginTop:mobile?'36px':'28px',lineHeight:mobile?'24px':'24px',fontweight:mobile?'700px':'600'}}>About the instructor</Typography>
</Grid>
</Grid>

<Grid container md={10} xs={12} lg={11}>
                <Grid item  xs={12} lg={12} md={7}  >
									  <Typography variant='h2' sx={{ textAlign: 'initial', fontSize: mobile ? '16px' : '16px', marginTop: mobile ? '36px' : '10px', lineHeight: mobile ? '24px' : '24px', fontweight: mobile ? '700' : '400' }}>Kevin Missal is one of the youngest mythological and fantasy authors. Kevin Missal, one of the youngest mythological and fantasy authors, made his mark in the literary world with his debut book at the age of 14. His book was released by Sheila Dikshit, the then-Chief Minister of Delhi. Translated into six different languages, he has written15 best selling novels which include the Narasimha Trilogy. Some of his works have also been turned into audiobooks. His amiable personality, witty sense of humour, and sharp thinking make him one of the most loved authors. He was regarded as “a mythological phenomenon” by The Hindu, owing to the success of his books. With his diverse interests and prolific writing, he strives to pave his way ahead with many more books to write in the future. 
									  </Typography>

</Grid>
</Grid>
</>}
            
					  </Grid>
					  <Grid container lg={5.8} xs={12} sx={{background: '#FFFFFF',margin:mobile?"auto":"0px",borderRadius:'8px'}}>	  
			
<Grid container lg={12}>
<Grid container md={12} xs={12} lg={12}>
                <Grid item  xs={10} lg={11} md={10} sx={{margin:'auto'}} >
                <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'16px':'16px',marginTop:mobile?'22px':'26px',lineHeight:mobile?'24px':'66px',fontWeight:mobile?'700':'600'}}>Writing lessons by Kevin Missal </Typography>
</Grid>
</Grid>

<Grid container md={10} xs={12} lg={12}>
                <Grid item  xs={10} lg={11} md={7} sx={{margin:'auto'}} >
									  <Typography variant='h2' sx={{ textAlign: 'initial', fontSize: mobile ? '16px' : '16px', marginTop: mobile ? '8px' : '-10px', lineHeight: mobile ? '24px' : '24px', fontweight: mobile ? '700' : '400' }}>
									  Undertake the engaging and captivating seminars led by the bestselling author Kevin Missal.
Kevin Missal has written 12 Books since the age of 14. Given the world, a universe of mythology fiction bestsellers. He’s been featured in publications like the Sunday Guardian, The New Indian Express, and Millennium Post.
Understand the steps involved in the craft of fiction writing, how to give your manuscripts life, how to produce best-selling books, and how to ignite your writing career.
		  </Typography>

</Grid>
</Grid>
<Grid container md={10} xs={12} lg={12}>
                <Grid item  xs={10} lg={11} md={7} sx={{margin:'auto'}} >
                <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'16px':'16px',marginTop:mobile?'16px':'26px',lineHeight:mobile?'24px':'24px',fontWeight:mobile?'700':'600'}}>Key learnings</Typography>

</Grid>
</Grid>
<Grid container md={10} xs={12} lg={12}>
<Grid item lg={12} md={12} xs={12}  >
          
		  <ul style ={{listStyle: 'disc'}}>
		  <li>
			<Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'3px':'2px'}}>Where to get ideas from</Typography>
			</li>
			  <li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'3px':'2px'}}>How theme can make or break your story
</Typography>
  </li>
			  <li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'3px':'2px'}}>Why Research is important for writing</Typography>
  </li>
	<li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'3px':'2px'}}>What is a plot and how to use it like a pro</Typography>
  </li>
	<li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'3px':'2px'}}>Writing Characters</Typography>
  </li>
	<li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'3px':'2px'}}>Building an arc for a character</Typography>
										  </li>
										  <li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'3px':'2px'}}>Outlining your story</Typography>
										  </li>
										  <li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'3px':'2px'}}>Why Re-writing is important for a story to get better</Typography>
										  </li>
										  {/* <li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'16px':'2px'}}>Editing is a process of metamorphosis</Typography>
										  </li>
										  <li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'16px':'2px'}}>Pitching your book to publishers</Typography>
										  </li> */}
										  {/* <li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'16px':'2px'}}>Grabbing good contracts</Typography>
										  </li>
										  <li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'16px':'2px'}}>What works for Marketing and what does not</Typography>
										  </li>
										  <li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'16px':'2px'}}> How to reach your audience</Typography>
										  </li>
										  <li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'16px':'2px'}}> How a Book Cover helps to sell itself</Typography>
										  </li>
										  <li>
	 <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'16px':'2px'}}>Writing, Publishing & Selling a Best-Seller</Typography>
  </li> */}


		  </ul>
		  </Grid>
								  
							  </Grid>
							  {mobile ?  <>
		<Grid container md={12} xs={12} lg={11}>
                <Grid item  xs={10} lg={12} md={10} sx={{margin:'auto'}} >
                <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'16px':'16px',marginTop:mobile?'0px':'26px',lineHeight:mobile?'24px':'24px',fontWeight:mobile?'700':'600'}}>About the instructor</Typography>
</Grid>
</Grid>

<Grid container md={10} xs={12} lg={11}>
                <Grid item  xs={10} lg={12} md={7} sx={{margin:'auto'}} >
                <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'16px':'16px',marginTop:mobile?'8px':'0px',lineHeight:mobile?'24px':'24px',fontWeight:mobile?'400':'400'}}>Kevin Missal is one of the youngest mythological and fantasy authors. Kevin Missal, one of the youngest mythological and fantasy authors, made his mark in the literary world with his debut book at the age of 14. His book was released by Sheila Dikshit, the then-Chief Minister of Delhi. Translated into six different languages, he has written15 best selling novels which include the Narasimha Trilogy. Some of his works have also been turned into audiobooks. His amiable personality, witty sense of humour, and sharp thinking make him one of the most loved authors. He was regarded as “a mythological phenomenon” by The Hindu, owing to the success of his books. With his diverse interests and prolific writing, he strives to pave his way ahead with many more books to write in the future. 
									  
										  </Typography>

									  </Grid>
				
</Grid>
</>:""}
</Grid>
<Grid item lg={5} md={12} xs={12}   sx={{justifyContent:mobile?"start":'start',display:'flex',marginBottom:mobile?"20px":'30px',marginLeft:mobile?"26px":'26px'}}>
							  <Button variant='contained' sx={{ textAlign: 'initial', fontSize: mobile ? '16px' : '16px', lineHeight: mobile ? '20px' : '40px', fontWeight: mobile ? '700' : '500', backgroundColor: "#3A81F3", width: "155px", height: '48px', marginTop: "20px", textTransform:'none'}} onClick={() => { handleNext1() }}>Start course</Button>
        </Grid>	  
						  </Grid>  
</Grid>

      </Box>
      </Box>
    </>
  )
}

export default Instructor;