import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import React from 'react';
import { TextField, Typography }  from '@mui/material';
import Button from '@mui/material/Button';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Image from "mui-image";
import Footer from './footer';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import { Link, Navigate } from 'react-router-dom';
import SwipeableTextMobileStepper from './components/Testimonial'
import ResponsiveAppBar from './components/menu';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { useDevice } from './components/responsive/';
import { useForm, Controller, FormProvider } from "react-hook-form";
import ImageListItemBar from '@mui/material/ImageListItemBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@mui/material/MobileStepper';
import { autoPlay } from 'react-swipeable-views-utils';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from 'react-router-dom';
import Confetti from "react-confetti";
import { Email_URL } from './components/helper';
import CollectChatBot from './Chatbot';
function LandingPage()
{ 
	const mobile = useMediaQuery('(max-width:600px)');
	const m = useMediaQuery('(max-width:412px)');
	const [activeStep, setActiveStep] = React.useState(0);
	const ipad = useMediaQuery('(min-width: 768px) and (max-width: 1180px)  and (orientation: landscape)');
	const ipad1 = useMediaQuery('(min-width: 600px) and (max-width: 900px)  and (orientation: portrait)');
	const [open, setOpen] = React.useState(false);
	const [showConfetti, setShowConfetti] = React.useState(false);
	const navi = useNavigate();
	const [email, setEmail] = React.useState('');
	const { register, watch, control, handleSubmit, setValue, reset,formState: { errors } } = useForm();
	const onSubmit = async (data, e) => {
		setOpen(true);
		setShowConfetti(false);
		const formData = new FormData();
		console.log(data);
		e.preventDefault();
		const response = await fetch(`${Email_URL}/api/email/activateD`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify(data),
		}).then((res) => res.json()).then(async (res) => {
			const resData = await res;
			console.log(resData);
			if (resData.status === "success") {
				alert("Message Sent");
			} else if (resData.status === "fail") {
				alert("Message failed to send");
			}
		})
		console.log(JSON.stringify(data));
		console.log(FormData, 'hi');
	  };
	
  const handleClick = () => {
    Navigate("/Sign Up");
  }
	const handleClose = () => {
		setOpen(false);
		reset();
	};
	const handleClickOpen = () => {
		setOpen(true);
		setShowConfetti(false);
		
	  };
	const maxSteps=4;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
	const hand = () => {
		setShowConfetti(true);
		setTimeout(() => setShowConfetti(false), 3000);
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
	};
	const [checked, setChecked] = React.useState(false);

	const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
	return (
		<>
			<React.Fragment>
			<ResponsiveAppBar />
			<Box>
				<Grid container lg={9.2} md={12} xs={12} sm={10} sx={{margin:'auto'}}  >
						<Grid container md={5} lg={6} xs={8.5} sm={10} sx={{margin:'auto'}}>
							<Grid item md={12} lg={12} sm={12} xs={12} sx={{marginTop:mobile?'46px':'4px',display:'flex',justifyContent:'left'}}>
						<Typography variant='h1' sx={{ fontWeight: '700',fontSize:mobile?'32px':ipad?'48px':'56px',color:'#333335',textAlign:'initial',lineHeight:mobile?'38px': '65.63px',fontStyle:"normal"}}>One stop literary<Grid md={10} xs={2}> marketplace</Grid></Typography>	
					</Grid>
							<Grid item md={12}  lg={12} xs={12} sm={12} sx={{marginTop:mobile?"12px":"0px"}}>
						
								{mobile ? (<>
									<Grid item md={12}  lg={12} xs={12} sm={12} sx={{marginTop:mobile?"12px":"0px"}}>
								<Typography variant='h6' sx={{ fontWeight: '400', fontSize: mobile?'14px':'18px', color: '#333335',textAlign:'initial',lineHeight:"24px"}}>Bridging the gap between good to great,</Typography>	
								</Grid>
								<Grid item  lg={12} md={12} xs={12} sx={{ backgroundImage: 'url(https://drive.google.com/uc?export=view&id=1fe-sxrSCiDYou_uv53Qj60LAT1-55l6L)', backgroundRepeat: 'no-repeat', backgroundPosition: '101px 17px' }}>
								<Typography variant='h6' sx={{ fontWeight: '400', fontSize: mobile ? '14px' : '18px', color: '#333335', textAlign: 'initial', marginBottom: '36px' }}>make your book a bestseller</Typography>
							</Grid>
								</>) : (<>
										<Grid item  lg={12}md={12} sx={{display:'flex',justifyContent:'left'}}>
							<Typography variant='h6' sx={{ fontWeight: '400', fontSize: mobile?'14px':'18px', color: '#333335',textAlign:'initial',lineHeight:"24px"}}>Bridging the gap between good to great, make your 	</Typography>
							</Grid>
								<Grid item md={8}  lg={8} xs={12} sx={{ backgroundImage: 'url(https://drive.google.com/uc?export=view&id=1fe-sxrSCiDYou_uv53Qj60LAT1-55l6L)', backgroundRepeat: 'no-repeat', backgroundPosition: '65px 21px' }}>
									<Typography variant='h6' sx={{ fontWeight: '400', fontSize: mobile ? '14px' : '18px', color: '#333335', textAlign: 'initial', marginBottom: '50px' }}>book a bestseller</Typography>
								</Grid>
								</>)}
		
					</Grid>	
					<Grid item md={12}  lg={12} xs={12} sm={12} sx={{display:'flex',justifyContent:mobile?"center":'left'}} >
								<Link to='../signup' style={{ textDecoration: "none" }}>
									<Button variant='contained' sx={{ backgroundColor:'#3A81F3',borderRadius: '8px', textTransform: 'none', fontSize: mobile ? "12px" : "16px", fontWeight: "500", lineheight: "24px", width: mobile?'36vh':'138%', height: "48px" }} >Get Started</Button>
								</Link>
							</Grid>	
				</Grid>
				<Grid Container md={6} xs={12} sm={10}>
							<Grid item md={12} sm={12} xs={12} style={{display:"flex",justifyContent:mobile?'center':'left'}}>	
								<Grow in={true}
									style={{ transformOrigin: '0 0 0' }}
									{...(true ? { timeout: 7000 } : {})}>
									<Box sx={{ display: "flex" }}>
				<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1ME-L6biUpvR1C4aD-GG5X3aiJw37xLQ9" style={{
    justifyContent:'space-around',width:mobile?'272px':ipad?'465px':'560px',height:mobile?'170.4px':ipad?'294px':'352px',transitionDuration:'0',animation:'0' ,marginTop:mobile?'36px':ipad?'64px':"104px",marginBottom:mobile?"":"104px"
									}}></Image>
									</Box>
									</Grow>
									
									
						</Grid>
				</Grid>
			</Grid>
			</Box>
			<Grid container md={12} lg={10} xs={12} sm={12} sx={{margin:'auto'}}>
					<Grid item md={12} lg={12} xs={12} sm={12}>
			<Typography  sx={{fontStyle: 'normal',fontWeight: '600',fontSize: mobile?'16px':'24px',lineheight: '40px',marginTop:mobile?'62px':"57px",marginLeft:mobile?'35px':""}}>Publishers We Have Worked With</Typography>
				</Grid>	
					<Grid container lg={12} md={11} sm={12} xs={11} sx={{m:mobile?'auto':ipad?'0px':'0px',marginTop:mobile?'':'57px',marginBottom:mobile?'':'57px'}}>
				<Grid item lg={2} md={2} xs={4} sm={4}>
				<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1_R6Y-rgNyOtfvQRMofUe2X-he6a_NeYi" style={{
   justifyContent:'space-around',width:mobile?'85px':ipad?"99px":'173px',height:mobile?'45px':ipad?"81px":'120px',transitionDuration:'0',animation:'0' 
  }}></Image>
				</Grid>
				<Grid item lg={2} md={2} xs={4} sm={4}>
				<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1o06m-nr_x3hnjH8cg6Q-d3EDdb4SFi3_" style={{
   justifyContent:'space-around',width:mobile?'85px':ipad?"114px":'173px',height:mobile?'45px':ipad?"80px":'120px',transitionDuration:'0',animation:'0' 
  }}></Image>
				</Grid>
				<Grid item  lg={2} md={2} xs={4} sm={4}>
				<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1eO72ezicWb325O0sCJKozZgfSUldQ0Gx" style={{
    justifyContent:'space-around',width:mobile?'85px':ipad?"116px":'174px',height:mobile?'45px':ipad?"88px":'120px',transitionDuration:'0',animation:'0' 
  }}></Image>
				</Grid>
				<Grid item lg={2} md={2} xs={4} sm={4}>
				<Image  Duration={0} src="https://drive.google.com/uc?export=view&id=11NhcuuyvNDCtCbBlEUCyWazQePiA9fwA" style={{
    justifyContent:'space-around',width:mobile?'85px':ipad?"138px":'173px',height:mobile?'45px':ipad?"83px":'108px',transitionDuration:'0',animation:'0' 
  }}></Image>
				</Grid>
				<Grid item  lg={2} md={2} xs={4} sm={4}>
				<Image  Duration={0} src="https://drive.google.com/uc?export=view&id=1oxOpbsvHPzmZz7El_wFTVxsp6t9U7gYL" style={{
    justifyContent:'space-around',width:mobile?'85px':ipad?"123px":'173px',height:mobile?'45px':ipad?"72px":'104px',transitionDuration:'0',animation:'0' 
  }}></Image>
				</Grid>
				<Grid item  lg={2} md={2} xs={4} sm={4}>
				<Image  Duration={0} src="https://drive.google.com/uc?export=view&id=1RFPo_Uqxz8lYvx0ZSR5rtn9jdTL3kx-h" style={{
    justifyContent:'space-around',width:mobile?'85px':ipad?"171px":'173px',height:mobile?'45px':ipad?"80px":'120px',transitionDuration:'0',animation:'0' 
  }}></Image>
				</Grid>
				</Grid>
			</Grid>
				<Grid container md={12} lg={10} xs={12} sx={{margin:'auto'}}>
				<Grid item lg={12} xs={12} sx={{mb:mobile ?'10px':'0px',marginTop:mobile?"39px":""}}>
					<Typography variant={mobile ?'subtitle2':'h3'} sx={{
fontstyle: 'normal',
fontWeight: '600',
fontSize: mobile?'20px':'36px',
lineHeight: '48px'}}>Services Offered</Typography>
					</Grid>
					
					
					<Grid container md={11.5} lg={12} xs={10} sx={{ margin: 'auto',marginTop:mobile?'19px':'36px',display:'flex',backgroundImage:`url(https://drive.google.com/uc?export=view&id=1jg23sMuMUpsHmrtk4vj8syKV1XhTELd6),url(https://drive.google.com/uc?export=view&id=1jg23sMuMUpsHmrtk4vj8syKV1XhTELd6)`, backgroundRepeat: 'no-repeat',backgroundSize:mobile?'39px,42px':'62px',backgroundPosition:mobile?'top left,bottom right':'top left,bottom right' }}>
						<Grid container xs={11} md={11.5} lg={11.5} sx={{margin:'auto',marginBottom:mobile?'0px':'10px',marginTop:mobile?'0px':'10px'}}>
					<Grid item lg={3} md={3}  xs={6} sx={{marginBottom:mobile?'16px':"0px",marginTop:mobile?'11px':"0px"}}>
				<Image src="https://drive.google.com/uc?export=view&id=1PoF9e2mIKUtOJpKRYcIv1w3UtLRunnkd" style={{
    justifyContent:'space-around' ,borderRadius: mobile?"8px":'12px', width:'94%',height:'86%'
					}}></Image>	
					<Grid item md={3.6} xs={4.2} sx={{justifyContent:'center',display:'flex'}}>	
									<Typography sx={{textAlign:'center',fontSize:mobile?'12px':'18px',fontWeight:'600',lineHeight:'24px'}}>Editing</Typography>
									</Grid>
				</Grid>
				<Grid item md={3}xs={6}  sx={{marginBottom:mobile?'16px':"0px",marginTop:mobile?'11px':"0px"}}>
				<Image src="https://drive.google.com/uc?export=view&id=1gpWNGXq0L6n2ABWqfs-wEL7XX408JKjv" style={{
    justifyContent:'space-around',borderRadius:mobile?"8px": '12px', width:'94%',height:'86%'
								}}></Image>
				<Grid item md={4.8} xs={6} >	
									<Typography sx={{textAlign:'center',fontSize:mobile?'12px':'18px',fontWeight:'600',lineHeight:'24px'}}>BookStore</Typography>
									</Grid>	
							</Grid>
							<Grid item md={3}xs={6}  sx={{marginBottom:mobile?'16px':"0px",marginTop:mobile?'11px':"0px"}}>
				<Image src="https://drive.google.com/uc?export=view&id=1_EwsTrTny4Fn31AqghFgJJofiz1TtiNE" style={{
    justifyContent:'space-around',borderRadius:mobile?"8px": '12px', width:'94%',height:'86%'
								}}></Image>
				<Grid item md={4.8} xs={6} >	
									<Typography sx={{textAlign:'center',fontSize:mobile?'12px':'18px',fontWeight:'600',lineHeight:'24px'}}>Ghostwriting</Typography>
									</Grid>	
							</Grid>
							<Grid item md={3}xs={6}  sx={{marginBottom:mobile?'16px':"0px",marginTop:mobile?'11px':"0px"}}>
				<Image src="https://drive.google.com/uc?export=view&id=1DBKqfRWk32wF2mVdgElUNoMqt3VNcyLv" style={{
    justifyContent:'space-around',borderRadius:mobile?"8px": '12px', width:'94%',height:'86%'
								}}></Image>
				<Grid item md={4.8} xs={6} >	
									<Typography sx={{textAlign:'center',fontSize:mobile?'12px':'18px',fontWeight:'600',lineHeight:'24px'}}>Translation</Typography>
									</Grid>	
							</Grid>
			
							</Grid>
						</Grid>
						</Grid>
					
					<Grid container md={12} sx={{marginTop:mobile?"0px":"62px"}}>
					<Grid item md={12} sx={{margin:mobile?"auto":"auto"}}>
					<Link to='/SignUp' style={{ textDecoration: 'none', fontWeight: 550,margin:'auto' }}>
        <Button variant="contained" size='large' sx={{backgroundColor:'#3A81F3',textTransform:'none',width:mobile?"95px":"128px",height:mobile?"42px":"48px",borderRadius:"8px",padding:'12px 20px',fontSize:mobile?"12px":"16px",fontWeight:"500",marginTop:mobile?'15px':''}}>See more</Button>
				</Link>
						</Grid>
				</Grid>
		
			<Box sx={{backgroundColor:'#bb00000a',
height: mobile?'475px':'521px',
 marginTop: '70px'
			}} >
				<Box sx={{m:mobile?'0px':ipad?"0px":'80px',alignItems:'center',backgroundPosition:mobile?'26px 88px':'31px 135px',backgroundSize:mobile?'118px':"200px"}}>
			<Grid container md={11} xs={12} lg={12}></Grid>
						<Grid container md={12} xs={12} lg={12} sx={{ justifyContent: 'center' }}>
					<Typography variant='h2' component='div'sx={{
						textAlign: 'center',
fontStyle: 'normal',
fontWeight: '600',
fontSize: mobile?'20px':'36px',marginTop:mobile?'26px':"64px",
								lineHeight: '48px'
							}}>Partner With Hubhawks</Typography>
						</Grid>
						<Grid container lg={12} md={11.5} xs={12} sx={{margin:mobile?'0px':ipad?"0px":'0px',backgroundImage:mobile?"":'url(https://drive.google.com/uc?export=view&id=1SNCsWeUdLlpXUWq3cwwEbywWMbZk4BDj)',backgroundRepeat:'no-repeat'}}>
				<Grid container lg={12} md={10.8} xs={12} sx={{marginTop:"33px"}}>
					<Grid container md={4} xs={10} lg={5} sx={{backgroundImage:mobile?'url(https://drive.google.com/uc?export=view&id=1SNCsWeUdLlpXUWq3cwwEbywWMbZk4BDj)':"",backgroundRepeat:'no-repeat',margin:mobile?"auto":"0px",backgroundSize:mobile?"121px 121px":"",borderRadius:"8px"}}>
					
				<Grid item md={6} lg={12}>
								<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1wFizUuVWnSqCCa5RvVmVR6fM2NigCfsL" style={{
    justifyContent:'space-around',width:mobile?'272px':ipad?"345px":'463px',height:mobile?'144px':ipad?"192px":'244px',borderRadius: '12px',marginTop:mobile?'30px':"0px",marginLeft:mobile?'20px':'0px',
						}}></Image>
					</Grid>
				</Grid>
								<Grid container lg={7} md={7.5}  xs={12} sx={{marginLeft:'0px',marginTop:mobile?"22px":"0px"}}>
				
									<Grid item md={5} lg={5} xs={5} sx={{ backgroundColor: '#FFFFFF', borderRadius: '12px', m: 'auto', width: mobile?'128px':'20px', height: mobile?'42px':'104px' }}>
										<Grid container md={12} lg={12} xs={12} sx={{ml:'7px',mt:mobile?'0px':'18px'}}>
										<Grid item md={2} lg={2} xs={2} sm={2}>
								<Image src="https://drive.google.com/uc?export=view&id=1UFCpEs32Dv1vUZH2fe5Mi_LWzjrOQKLA" style={{
    justifyContent:'space-around',width:mobile?'22px':ipad?"42px":'52.78px',height:mobile?'22px':ipad?"42px":'52px',margin:mobile?'12px':"0px"
											}}></Image>
										</Grid>
							<Grid container md={9} lg={9} xs={8} sx={{m:"auto"}}>
							<Grid item md={12} sm={12} lg={12} xs={12} spacing={3} sx={{margin:'auto'}}>
									<Typography sx={{  fontStyle: 'normal', fontWeight: '600', fontSize:mobile?'9px': ipad?'16px':'18px', lineHeight: mobile ? '11px' :ipad?"20px": '24px',textAlign:'initial' }}>200+ Authors actively
									</Typography>
									<Grid item md={12} xs={12}>
									<Typography sx={{  fontStyle: 'normal', fontWeight: '600', fontSize:mobile?'9px': '18px', lineHeight: mobile ? '11px' : '24px',marginRight:mobile?'0px':'43px',textAlign:'initial'}}>working with us</Typography></Grid>
									</Grid>
								</Grid>
								</Grid>	

						</Grid>	

						
								<Grid item md={5} lg={5} xs={5} sx={{backgroundColor:'#FFFFFF',borderRadius:'12px', m:'auto',width: mobile?'128px':'270px', height: mobile?'42px':'104px'}}>
				<Grid container md={12} xs={12} lg={12} sx={{ml:'7px',mt:mobile?'10px':'18px'}}>
											<Grid item md={2} lg={2} xs={2} sm={2}>
								<Image src="https://drive.google.com/uc?export=view&id=10ZBFOkSfmgx1jibPD4GAZvv8gnN0wDHO" style={{
    justifyContent:'space-around',width:mobile?'22px':ipad?"42px":'52.73px',height:mobile?'22px':ipad?"42px":'52px',margin:'0px'
											}}></Image>
										</Grid>
								
							<Grid container md={9} lg={9}  xs={8} sx={{m:"auto"}}>
							<Grid item md={12} sm={12} spacing={3}  xs={12} sx={{margin:'auto'}}>
												{mobile ?
								<Typography sx={{ fontStyle: 'normal', fontWeight: '600', fontSize:mobile?'9px': '18px', lineHeight: '11px',textAlign:'initial'}}>100k+ content pieces</Typography>
						:<Typography sx={{ fontStyle: 'normal', fontWeight: '600', fontSize: mobile ? '9px' : '18px', lineHeight: '24px', textAlign: 'initial' }}>100k+ content 
						</Typography>
													}					<Grid item md={12} lg={12} xs={12}>
													{mobile ?<Typography sx={{ fontStyle: 'normal', fontWeight: '600', fontSize:mobile ? '9px' : '18px', lineHeight:mobile ? '11px' : '24px', marginRight: '43px', textAlign: 'initial' }}> delivered</Typography>
												:
										<Typography sx={{ fontStyle: 'normal', fontWeight: '600', fontSize:mobile ? '9px' : '18px', lineHeight: '24px',marginRight:'43px',textAlign:'initial'}}> pieces delivered</Typography>
									}			
														</Grid>
									
											</Grid>
								</Grid>
								</Grid>	

						</Grid>	

						<Grid item md={5} lg={5} xs={5} sx={{backgroundColor:'#FFFFFF',borderRadius:'12px', m:'auto',width: mobile?'128px':'20px', height: mobile?'42px':'104px',marginTop:mobile?'15.76px':"26px"}}>
										<Grid container md={12} lg={12}xs={12} sx={{ml:'7px',mt:mobile?'10px':'18px'}}>
										<Grid item md={2} lg={2} xs={2} sm={2}>
								<Image src="https://drive.google.com/uc?export=view&id=1DkRa9ZXUDbUsLqOc_5MIWZ1xkz1I87sw" style={{
    justifyContent:'space-around',width:mobile?'22px':ipad?"42px":'52.78px',height:mobile?'22px':ipad?"42px":'53px',margin:'0px'
									}}></Image></Grid>
								
										<Grid container lg={9} md={9} xs={8} sx={{m:"auto"}}>
											<Grid item md={12} lg={12} xs={12} sm={12} spacing={3} sx={{margin:'auto'}}>
									<Typography sx={{ fontStyle: 'normal', fontWeight: '600', fontSize: mobile ? '9px' :'18px', lineHeight: mobile ? '11px' :'24px',textAlign:'initial' }}>Connect with like
									</Typography>
									<Grid item md={12} lg={12} xs={12}>
									<Typography sx={{  fontStyle: 'normal', fontWeight: '600', fontSize:mobile ? '9px' : '18px', lineHeight:mobile ? '11px' : '24px',marginRight:mobile?'0px':'43px',textAlign:'initial'}}>-minded clients</Typography></Grid>
									</Grid>
								</Grid>
								</Grid>	

						</Grid>	
						
						<Grid item md={5}lg={5} xs={5} sx={{backgroundColor:'#FFFFFF',borderRadius:'12px', m:'auto',width: mobile?'128px':'20px', height: mobile?'42px':'104px',marginTop:mobile?'15.76px':"26px"}}>
				<Grid container md={12} lg={12}xs={12} sx={{ml:'7px',mt:mobile?'10px':'18px'}}>
											<Grid item md={2} xs={2} lg={2} sm={2}>
								<Image src="https://drive.google.com/uc?export=view&id=16M4SXr_kZ02n8n59_88qFjZD_ezAo4nd" style={{
    justifyContent:'space-around',width:mobile?'22px':ipad?"42px":'52.78px',height:mobile?'22px':ipad?"42px":'53px',margin:'0px'
												}}></Image>
											</Grid>
								
							<Grid container md={8} lg={8} xs={8} sx={{m:"auto"}}>
							<Grid item md={12} lg={12}sm={12}xs={12} spacing={3} sx={{margin:'auto'}}>
									<Typography sx={{  fontStyle: 'normal', fontWeight: '600', fontSize:mobile?"9px": '18px', lineHeight: '24px',textAlign:'initial' }}>Hassle-free Payments
									</Typography>
									</Grid>
								</Grid>
								</Grid>	

						</Grid>	
							</Grid>
							</Grid>
				</Grid>
					
					<Link to='/Partnerus' style={{textDecoration: 'none'}}>
						<Button variant='h5' sx={{
						textAlign: 'center', 
						
						fontStyle: 'normal',
						fontWeight: 500,
						fontSize: mobile?'12px':'16px',
						lineHeight: '24px',						
							color: '#3A81F3', textDecoration: 'none',textTransform:'none',marginTop:mobile?'15.76px':"62px"
						}}>
							
							Become a Creator<ChevronRightIcon /></Button>
						
					</Link>
					
					</Box>
				</Box>
			<Box sx={{ alignItems:'center',margin:'auto'}}>
			<Typography variant='h5'  id='whyhubhawks' sx={{
				textAlign: 'center', mt: '20px', fontWeight: '600',
fontSize:mobile?'20px': '36px',marginTop:mobile?"26px":"52px",
						lineHeight: mobile ? '20px' : '48px',marginBottom:mobile?"36px":"52px",
					}}>Why Hubhawks</Typography>
					{mobile ?
							
						
						<Grid container md={12} xs={12} sx={{margin:'auto'}}>
							
							<Grid container md={12} xs={11} sx={{margin:'auto'}}>
							
								<AutoPlaySwipeableViews
									 index={activeStep}
									 onChangeIndex={handleStepChange}
									 enableMouseEvents sx={{ width: '480px', height: '300px' }}>
						<Grid container xs={12}>
					<Grid item xs={5} sx={{ width: '128px', height: '150px', background: '#06B2BB1A', borderRadius: '12px', margin: "10px"}} >	
					<Grid item md={12} >
					<Image src="https://drive.google.com/uc?export=view&id=1zoezVSzeufCNtDl38mhaGFYNDFrl3ZOb" style={{
    justifyContent:'center',width:'30px',height:'30px',marginTop:'16px',marginBottom:"16px",transitionDuration:'0',animation:'0' 
							}}>
							</Image>
							</Grid>			
							<Grid item md={9} sx={{m:'auto',textAlign:'initial'}}>	
						<Typography sx={{fontSize:'12px',fontWeight:'400',lineHeight:'16px',marginLeft:"10px"}}>10X your work and grow exponentially with us</Typography></Grid>
									</Grid>
									
					<Grid item xs={5}  sx={{ width: '128px', height: '150px',background:'#FDD2191A',borderRadius: '12px',margin:"10px"}} >
					<Grid item md={12} sm={12}>
					<Image src="https://drive.google.com/uc?export=view&id=1h5IpQn5jPBMo5_-oB2MXj-1w3wAJBfjL" style={{
    justifyContent:'center',width:'30px',height:'30px',marginTop:'16px',marginBottom:"16px",transitionDuration:'0',animation:'0' 
							}}>
							</Image>
							</Grid>			
							<Grid item md={11} sx={{m:'auto',textAlign:'initial'}} >	
						<Typography sx={{fontSize:"12px",fontWeight:'400',lineHeight:'16px',marginLeft:"10px"}}>Have collaborated with over 20 bestselling authors and over 100 authors</Typography></Grid>
					
									</Grid>
									
									</Grid>
					<Grid container xs={12}>
					<Grid item xs={5} sx={{width: '128px', height: '150px',background:'#F788281A',borderRadius: '12px',margin:"10px"}} >
					
					<Grid item md={12} sm={12}>
					<Image src="https://drive.google.com/uc?export=view&id=1GpeL2-xt5ORIeJ9QpJ8YOQCLQWLvmLxw" style={{
    justifyContent:'center',width:'30px',height:'30px',marginTop:'16px',marginBottom:"16px",transitionDuration:'0',animation:'0' 
							}}>
							</Image>
							</Grid>			
							<Grid item md={11} sx={{m:'auto',textAlign:'initial'}} >	
						<Typography sx={{fontSize:'12px',fontWeight:'400',lineHeight:'16px',marginLeft:"10px"}}>Invested over 17,000+ hours on author asset creation</Typography></Grid>
					
					</Grid>
					<Grid item xs={5}  sx={{width: '128px', height: '150px',background:'#FA54561A',borderRadius: '12px',margin:"10px"}}>
					
					<Grid item md={12} sm={12}>
					<Image src="https://drive.google.com/uc?export=view&id=1vg2YBMFZNeBAFg1tLPFOqwmgh1vxSWyG" style={{
    justifyContent:'center',width:'30px',height:'30px',marginTop:'16px',marginBottom:"16px",transitionDuration:'0',animation:'0' 
							}}>
							</Image>
							</Grid>			
							<Grid item md={11} sx={{m:'auto',textAlign:'initial'}}>	
						<Typography sx={{fontSize:'12px',fontWeight:'400',lineHeight:'16px',marginLeft:"11px"}}>Guided by National bestselling author Kevin Missal who sold over 3L+ copies</Typography></Grid>
					
					</Grid>
				
									</Grid>
									<Grid container xs={12}>
					<Grid item xs={5}  sx={{width: '128px', height: '150px',background:'#06B2BB1A',borderRadius: '12px',margin:"10px"}}>
					
					<Grid item md={12} sm={12}>
					<Image src="https://drive.google.com/uc?export=view&id=1hzLWJ4fFKbyqWN_Vz6zeuhPbeR3jZ-0a" style={{
    justifyContent:'center',width:'30px',height:'30px',marginTop:'16px',marginBottom:"16px",transitionDuration:'0',animation:'0' 
							}}>
							</Image>
							</Grid>			
							<Grid item md={11} sx={{m:'auto',textAlign:'initial'}}>	
						<Typography sx={{fontSize:'12px',fontWeight:'400',lineHeight:'16px',marginLeft:"10px"}}>Pick your service and get it done with the utmost convenience</Typography></Grid>
					
					</Grid>
					<Grid item xs={5} sx={{ width: '128px', height: '150px',background:'#FDD2191A',borderRadius: '12px',margin:"10px"}}>
					
					<Grid item md={12} sm={12}>
					<Image src="https://drive.google.com/uc?export=view&id=1ghc1eYAAk-SMF_ZQVuEEHvYh3syTfZZ6" style={{
    justifyContent:'center',width:'30px',height:'30px',marginTop:'16px',marginBottom:"16px",transitionDuration:'0',animation:'0' 
							}}>
							</Image>
							</Grid>			
							<Grid item md={12} >	
						<Typography sx={{fontSize:'12px',fontWeight:'400',lineHeight:'16px',textAlign:'initial',marginLeft:"10px"}}>Have worked with top publishers like Penguin, Simon & Schuster, Fingerprint, etc.</Typography></Grid>
					
										</Grid>
									</Grid>
									

									<Grid container xs={12}>		
					<Grid item xs={5}  sx={{ width: '128px', height: '150px',background:'#F788281A',borderRadius: '12px',margin:"10px"}}>
					
										<Grid item md={12}  sm={12}>
					<Image src="https://drive.google.com/uc?export=view&id=11FkVFXbHh5SVXtejfGFO01gXMz07YXhg" style={{
    justifyContent:'center',width:'30px',height:'30px',marginTop:'16px',marginBottom:"16px",transitionDuration:'0',animation:'0' 
							}}>
							</Image>
							</Grid>			
							<Grid item md={11}  sx={{m:'auto'}}>	
						<Typography sx={{fontSize:'12px',fontWeight:'400',lineHeight:'16px',marginBottom:'16px'}}>Pocket-friendly services</Typography></Grid>
						
					
				</Grid>
				<Grid item xs={5} sx={{ width: '128px', height: '150px',background:'#FA54561A',borderRadius: '12px',margin:"10px"}}>
				
					<Grid item md={12} sm={12}>
					<Image src="https://drive.google.com/uc?export=view&id=1D-xyyOa-Bp5WwY_OovRYXKpkEINfWeSM" style={{
    justifyContent:'center',width:'30px',height:'30px',marginTop:'16px',marginBottom:"16px",transitionDuration:'0',animation:'0' 
							}}>
							</Image>
							</Grid>			
							<Grid item md={11} sx={{m:'auto',textAlign:'initial'}}>	
						<Typography sx={{fontSize:'12px',fontWeight:'400',lineHeight:'16px',marginBottom:'16px',marginLeft:"10px"}}>India’s first e-commerce platform for authors needs</Typography></Grid>	
					
									</Grid>
									</Grid>
								</AutoPlaySwipeableViews>
								<Grid item xs={12} sx={{marginLeft:'143px'}}>
								<MobileStepper
         steps={maxSteps}
        position="static"
        activeStep={activeStep}
       
      /></Grid>
								</Grid>
								
					</Grid>	
					
						:<Slide direction='up' in={true}
						style={{ transformOrigin: '0 0 0' }}
						{...(true ? { timeout: 5000 } : {})}>
						<Box sx={{ml:ipad?'0px':'150px',marginTop:'50px',marginBottom:'50px'}}>
						<Grid container lg={12} md={12} spacing={1} >
						<Grid container lg={12}  md={11} sx={{margin:'auto'}} >
						<Grid item  lg={2.5} md={2.7} sx={{ background:'#06B2BB1A',borderRadius: '12px',margin:"10px"}} >	
											<Grid item lg={12} md={12} sm={12} >
							<Image src="https://drive.google.com/uc?export=view&id=1zoezVSzeufCNtDl38mhaGFYNDFrl3ZOb" style={{
			justifyContent:'center',width:ipad?"50px":'60px',height:ipad?"50px":'60px',marginTop:'36px',marginBottom:"16px"
									}}>
									</Image>
									</Grid>			
											<Grid item lg={10} md={10} sx={{m:'auto',textAlign:'initial'}}>	
								<Typography sx={{fontSize:ipad?"16px":"18px",fontWeight:'400',lineHeight:'24px'}}>10X your work and grow exponentially with us</Typography></Grid>
							</Grid>
										<Grid item lg={2.5} md={2.7} sx={{ background:'#FDD2191A',borderRadius: '12px',margin:"10px"}} >
											<Grid item lg={12} md={12} sm={12}>
							<Image src="https://drive.google.com/uc?export=view&id=1h5IpQn5jPBMo5_-oB2MXj-1w3wAJBfjL" style={{
			justifyContent:'center',width:ipad?"50px":'60px',height:ipad?"50px":'60px',marginTop:'36px',marginBottom:"16px"
									}}>
									</Image>
									</Grid>			
									<Grid item lg={11} md={11.8} sx={{m:'auto'}} >	
								<Typography sx={{fontSize:ipad?"16px":"18px",fontWeight:'400',lineHeight:'24px',marginBottom:'28px',textAlign:'initial',marginLeft:ipad?"13px":"0px"}}>Have collaborated with over 20 bestselling authors and over 100 authors</Typography>
								</Grid>
							</Grid>
							<Grid item lg={2.5} md={2.7} sx={{ background:'#F788281A',borderRadius: '12px',margin:"10px"}} >
							
							<Grid item md={12} lg={12} sm={12}>
							<Image src="https://drive.google.com/uc?export=view&id=1GpeL2-xt5ORIeJ9QpJ8YOQCLQWLvmLxw" style={{
			justifyContent:'center',width:ipad?"50px":'60px',height:ipad?"50px":'60px',marginTop:'36px',marginBottom:"16px"
									}}>
									</Image>
									</Grid>			
									<Grid item md={10} lg={11} sx={{m:'auto',textAlign:'initial'}} >	
								<Typography sx={{fontSize:ipad?"16px":"18px",fontWeight:'400',lineHeight:'24px'}}>Invested over 17,000+ hours on author asset creation</Typography></Grid>
							
							</Grid>
							<Grid item  lg={2.5} md={2.7} sx={{ background:'#FA54561A',borderRadius: '12px',margin:"10px"}}>
							
							<Grid item md={12} sm={12} lg={12}>
							<Image src="https://drive.google.com/uc?export=view&id=1vg2YBMFZNeBAFg1tLPFOqwmgh1vxSWyG" style={{
			justifyContent:'center',width:ipad?"50px":'60px',height:ipad?"50px":'60px',marginTop:'36px',marginBottom:"16px"
									}}>
									</Image>
									</Grid>			
									<Grid item lg={11.5} md={11.5} sx={{m:'auto',textAlign:'initial'}}>	
								<Typography sx={{fontSize:ipad?"16px":"18px",fontWeight:'400',lineHeight:'24px',ml:'4px'}}>Guided by National bestselling author Kevin Missal who sold over 3L+ copies</Typography></Grid>
						
							</Grid>
						</Grid>
						<Grid container lg={12}  md={11} sx={{margin:'auto'}} >
							<Grid container lg={2.5} md={2.7} sx={{ background:'#06B2BB1A',borderRadius: '12px',margin:"10px"}}>
							
							<Grid item lg={12} md={12} sm={12}>
							<Image src="https://drive.google.com/uc?export=view&id=1hzLWJ4fFKbyqWN_Vz6zeuhPbeR3jZ-0a" style={{
			justifyContent:'center',width:ipad?"50px":'60px',height:ipad?"50px":'60px',marginTop:'36px',marginBottom:"16px"
									}}>
									</Image>
									</Grid>			
									<Grid item lg={11} md={11} sx={{m:'auto',textAlign:'initial'}}>	
								<Typography sx={{fontSize:ipad?"16px":"18px",fontWeight:'400',lineHeight:'24px',ml:ipad?'0px':'14px',marginBottom:'28px'}}>Pick your service and get it done with the utmost convenience</Typography>
								</Grid>
							
							</Grid>
							<Grid container  lg={2.5} md={2.7}  sx={{ background:'#FDD2191A',borderRadius: '12px',margin:"10px"}}>
							
							<Grid item lg={12} md={12} sm={12}>
							<Image src="https://drive.google.com/uc?export=view&id=1ghc1eYAAk-SMF_ZQVuEEHvYh3syTfZZ6" style={{
			justifyContent:'center',width:ipad?"50px":'60px',height:ipad?"50px":'60px',marginTop:'36px',marginBottom:"16px"
									}}>
									</Image>
									</Grid>			
									<Grid item lg={12} md={11} >	
								<Typography sx={{fontSize:ipad?"16px":"18px",fontWeight:'400',lineHeight:'24px',ml:ipad?'12px':'11px',textAlign:'initial',marginBottom:ipad?'14px':'28px'}}>Have worked with top publishers like Penguin, Simon & Schuster, Fingerprint, etc.</Typography></Grid>
							
							</Grid>
							<Grid container lg={2.5} md={2.7} sx={{background:'#F788281A',borderRadius: '12px',margin:"10px"}}>
							
							<Grid item md={12} lg={12} sm={12}>
							<Image src="https://drive.google.com/uc?export=view&id=11FkVFXbHh5SVXtejfGFO01gXMz07YXhg" style={{
			justifyContent:'center',width:ipad?"50px":'60px',height:ipad?"50px":'60px',marginTop:'36px',marginBottom:"16px"
									}}>
									</Image>
									</Grid>			
									<Grid item lg={12} md={11} sx={{m:'auto'}}>	
								<Typography sx={{fontSize:ipad?"16px":"18px",fontWeight:'400',lineHeight:'24px',marginBottom:ipad?"86px":'76px'}}>Pocket-friendly services</Typography></Grid>
								
							
						</Grid>
						<Grid container lg={2.5} md={2.7} sx={{ background:'#FA54561A',borderRadius: '12px',margin:"10px"}}>
						
							<Grid item lg={12} md={12} sm={12}>
							<Image src="https://drive.google.com/uc?export=view&id=1D-xyyOa-Bp5WwY_OovRYXKpkEINfWeSM" style={{
			justifyContent:'center',width:ipad?"50px":'60px',height:ipad?'50px':'60px',marginTop:'36px',marginBottom:"16px"
									}}>
									</Image>
									</Grid>			
									<Grid item lg={11} md={11} sx={{m:'auto',textAlign:'initial'}}>	
								<Typography sx={{fontSize:ipad?"16px":"18px",fontWeight:'400',lineHeight:'24px',marginBottom:ipad?"38px":'50px',marginTop:ipad?"0px":'-13px'}}>India’s first e-commerce platform for authors needs</Typography></Grid>	
							
							</Grid>
							
						</Grid>
						</Grid>
							</Box>
							</Slide>
					}
			
			
					
			</Box>
			
			{/*Testimonials */}
			<Box  >
					<Grid container lg={12} xs={12}>
				<Grid item lg={10} xs={10} sx={{margin:'auto'}}>
						<Typography variant='h2' sx={{
						fontSize: mobile?"20px":'36px',
fontStyle: 'normal',color: '#000000',marginBottom:'50px',
fontWeight: 600,marginTop:mobile?"48px":"139px",
								lineHeight: '48px'
							}}>Testimonials</Typography>	
</Grid>							
			</Grid>
					
				{/* <Grid container md={12}>
				<Grid item md={1}>
				<Image src="https://drive.google.com/uc?export=view&id=1C0q5be4_iBseQdLM_uX7uq0AJpznpjBk" style={{
    justifyContent:'space-around',width:mobile?'16.1px':'70px',height:mobile?'11px':'48px'
					}}>
					</Image>		
					</Grid>
					
				</Grid> 	 */}
			<Box  >
						<Grid container lg={12} xs={12} md={12}>
							<Grid item lg={11} xs={10} md={11.2} sx={{margin:"auto",backgroundImage:'url(https://drive.google.com/uc?export=view&id=1C0q5be4_iBseQdLM_uX7uq0AJpznpjBk),url(https://drive.google.com/uc?export=view&id=1CdOU3VwHUCO-2WT2GBkp32REKzJYxPpC)',backgroundRepeat:'no-repeat',backgroundPosition:"top left,bottom right",backgroundSize:mobile?'16.1px':'56px,56px',marginBottom:mobile?"51px":"102px"}}>
							<SwipeableTextMobileStepper style={{marginTop:"20px",marginBottom:"32px"}} />
							</Grid>
				</Grid>
						
						</Box>
				{/* <Box sx={{backgroundImage:'',backgroundRepeat:'no-repeat',backgroundSize:mobile?'16.1px':'70px',marginBottom:"50px",height:mobile?'11px':'48px',backgroundPosition:mobile?'340px':"1350px"}}>
					
				</Box> */}
				
					
						
			

</Box>

			{/*Founder story for home page*/}
			{/* <Box>
			
			<Paper sx={{backgroundColor:'#bb00000a'}}>
			<Typography variant='h5'>Founder Story</Typography>
			<Grid container md={12}>
			<Grid container md={12} sx={{m:'50px',backgroundColor:'#FFFFFF',borderRadius:'20px'}}>
				<Grid item md={6}sx={{m:'20px'}}>
					<Typography sx={{fontsize:'16px',fontStyle:'Roboto',fontWeight:'400px',color:'#000000'}}>
					Kevin Missal wrote his first book at the age of 14, and at 22, the St. Stephens graduate has turned out to be a bestselling author and a full-time writer with the first two books in his Kalki series being runaway successes. Along with the Narasimha series being published by Harper Collins, his recent release with Penguin reimagines the fabled story of Sinbad the Sailor. Kevin loves fantasy fiction and has always been a fan of mythology. His books have been featured in publications like the Sunday Guardian, The New Indian Express and Millennium Post.
						</Typography>
						</Grid>
						<Grid item md={5} sx={{mx:'0px'}} >
						
						<Image src="https://drive.google.com/uc?export=view&id=1uXojzON_Pp414rpaWSVCPOJO7E8cY9xL" style={{
    justifyContent:'space-around',width:'200px',height:'200px'
					}}></Image>	
							
							</Grid>
					

				</Grid>
				</Grid>
				</Paper>
				</Box> */}
				{mobile ?
				<Box sx={{ display: 'flex',backgroundImage: "url(https://drive.google.com/uc?export=view&id=1CO5YGilPosafgueBebd9BVDnT9lSVezW)", backgroundRepeat: '', background:mobile?'':'',backgroundSize:mobile?"100%":'100%',height:'664px',flexDirection: 'column'}}>
				<Typography component="div" variant="h2" sx={{fontWeight: '600',textAlign:'center',margin:"auto",
	fontSize: '20px',
	lineHeight: '24px'}}>
				Founder's Story
			  </Typography>
					<Card sx={{
						display: 'flex', backgroundColor: '-moz-initial', width: '272px',margin:'auto',
	height: '546px',borderRadius:'20px',marginRight:'auto',marginLeft:'auto'}}>
				  <Box sx={{ display: 'flex', flexDirection: 'column',margin:'auto'}}>
					<CardContent sx={{ flex: '1 0 auto' }}>
			  
			  <Typography variant="subtitle1" component="div" sx={{width: '226px',height: '288px',fontStyle: 'normal',fontSize: '14px',lineHeight: '18px',textAlign: 'initial',marginTop:"26px"}}>
			  Kevin Missal wrote his first book at the age of 14, and at 22, the St. Stephens graduate has turned out to be a bestselling author and a full-time writer with the first two books in his Kalki series being runaway successes. Along with the Narasimha series being published by Harper Collins, his recent release with Penguin reimagines the fabled story of Sinbad the Sailor. Kevin loves fantasy fiction and has always been a fan of mythology. His books have been featured in publications like the Sunday Guardian, The New Indian Express and Millennium Post.
						
			  </Typography>
								</CardContent>
								<Box sx={{ display: 'flex', alignItems: 'center', margin:'auto',marginTop:'26px',marginBottom:'26px'}}>
								<CardMedia
			component="img"
			sx={{ width: 128,height:156 }}
			image="https://drive.google.com/uc?export=view&id=1uXojzON_Pp414rpaWSVCPOJO7E8cY9xL"
			alt="Live from space album cover"
									/>
									</Box> 
						</Box>
			
			  
			
		  
			
					
				</Card>
					</Box> :
					<Fade in={true} style={{ transformOrigin: '0 0 0' }}
					{...(true ? { timeout: 7000 } : {})}>
				<Box sx={{ display: 'flex',backgroundImage: "url(https://drive.google.com/uc?export=view&id=1CO5YGilPosafgueBebd9BVDnT9lSVezW)", backgroundRepeat: '', background:mobile?'':'',backgroundSize:mobile?"100%":'100%',height:ipad?'431px':'527px',flexDirection: 'column'}}>
				<Typography component="div" variant="h2" sx={{fontWeight: '600',textAlign:'center',margin:"auto",
	fontSize: '36px',
	lineHeight: '48px'}}>
				Founder's Story
			  </Typography>
					<Card sx={{
						display: 'flex', backgroundColor: '-moz-initial', width: ipad?"960px":'1140px',margin:'auto',
	height: ipad?"265px":'329px',borderRadius:'20px',marginRight:'auto',marginLeft:'auto'}}>
				  <Box sx={{ display: 'flex', flexDirection: 'column',margin:'auto'}}>
					<CardContent sx={{ flex: '1 0 auto' }}>
			  
			  <Typography variant="subtitle1" component="div" sx={{width: '643px',height: '168px',fontStyle: 'normal',fontSize: '16px',lineHeight: '24px',textAlign: 'initial'}}>
			  Kevin Missal wrote his first book at the age of 14, and at 22, the St. Stephens graduate has turned out to be a bestselling author and a full-time writer with the first two books in his Kalki series being runaway successes. Along with the Narasimha series being published by Harper Collins, his recent release with Penguin reimagines the fabled story of Sinbad the Sailor. Kevin loves fantasy fiction and has always been a fan of mythology. His books have been featured in publications like the Sunday Guardian, The New Indian Express and Millennium Post.
						
			  </Typography>
						</CardContent>
						</Box>
			<Box sx={{ display: 'flex', alignItems: 'center', margin:'auto'}}>
			  
			<CardMedia
			component="img"
			sx={{ width: ipad?"191px":209,height:ipad?'205px':242 }}
			image="https://drive.google.com/uc?export=view&id=1uXojzON_Pp414rpaWSVCPOJO7E8cY9xL"
			alt="Live from space album cover"
					/>
		  
			</Box> 
					
				</Card>
						</Box>
						</Fade>}
			
			
				<Box sx={{backgroundColor:'#EFF2F5',width:mobile?"272px":ipad?"960px": '1140px',height:mobile?"264px":ipad?'265px': '272px',borderRadius: '12px',margin:'100px',marginLeft:'auto',marginRight:'auto',marginBottom:mobile?"20px":'20px'}}>
					<Grid container md={12} lg={12} xs={12}>
						<Grid item md={8} lg={7} xs={11.5} sx={{textAlign:mobile?"initial":'center',display:'flex',margin:mobile?'auto':ipad?"auto":'auto',marginTop:mobile?'36px':'26px'}}>
							<Typography variant='h3' sx={{margin:'auto',
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: mobile?'14px':'24px',
						lineHeight: mobile?'20px':'40px',
						/* or 167% */
						
						textAlign: mobile?"initial":'center',
								
						
								
								
								
					}}>Sign up for free tips and tricks to hawk your readership, and get 10% off on your first purchase</Typography>
						</Grid>
					</Grid>
					<Grid container md={12} lg={12} sx={{marginTop:mobile?"32px":"43px"}}>
						<Grid container md={6} lg={6}>
							<Grid item md={12} lg={12} sx={{marginLeft:mobile?'10px':ipad?"148px":"278px"}}>
							<Box component='form' encType="multipart/form-data"
      method="post"  onSubmit={handleSubmit} >
								<TextField fullwidth size="small" sx={{ width: mobile ? '244px' : '372px', backgroundColor: 'white', height: mobile ? '40px' : '40px' }} placeholder='Enter your email' 
									{...register('count')}
										 />
									</Box>
								<Typography variant='subtitle2' sx={{textAlign:'initial',marginTop:mobile?"8px":"0px",fontSize:mobile?"10px":"14px"}}>We know spam hurts, we won’t spam you</Typography>
							</Grid>
						</Grid>
						<Grid container md={6} xs={12} sx={{marginLeft:mobile?'10px':"-78px"}}>
						<Grid item md={12} xs={6} sx={{marginTop:mobile?'16px':"0px"}}>
								<Button variant='contained' onClick={handleSubmit(onSubmit)} size={mobile ? 'small' : "large"} sx={{ width: mobile ? '121px' : '226px', borderRadius: '8px', textTransform: "none",margin:mobile?"0px":'0px',fontSize:mobile?'12px':"",backgroundColor:'#3A81F3' }}
								>Activate 10% off</Button >
									<Dialog
									open={open}
										PaperProps={{
										style: {
										  display: "flex",
										  justifyContent: "flex-end",
										  
										}
									  }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
								>
							
        
          <DialogTitle id="alert-dialog-title" >
            {"Thank You For Registrating On Our Platform"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
				<CheckCircleOutlineIcon /> "Congratulations! You have been selected to receive a 10% discount on your next purchase. Simply click the "Activate Discount" button below to take advantage of this exclusive offer.
												Thank you for choosing us!"	
            </DialogContentText>
										</DialogContent>
									<DialogActions style={{ justifyContent: "space-between" }}>
									<Confetti
	  active={showConfetti}
	
        width={600}
        height={200}
        numberOfPieces={200}
	  
	/>
			<Link to='/signup' style={{ textDecoration: "none" }}>
				<Button color="primary" onClick={hand} style={{alignItems:'flex-start'}}>
				Activate Discount
											</Button>
										</Link>
										
			<Button onClick={handleClose} color="primary" style={{justifyContent:"flex-end"}}>
              Close
            </Button>
			
						
          </DialogActions>
         
        </Dialog>
    
							</Grid>
							<Grid item md={12} xs={8} sx={{marginTop:mobile?'0px':"0px"}}>
							<Typography variant='subtitle2' sx={{ color:'#3A81F3',fontSize: mobile?'10px':'14px', color: '#3A81F3',marginTop:mobile?'4px':"0px" }}>No thanks, discounts aren’t my thing</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			<CollectChatBot />
				<Footer />
				</React.Fragment>
			</>
	);

}
export default LandingPage;