import React from 'react'
import { Paper,Box, Typography, Button } from '@mui/material';
import Dashboard_menu from './Dashboard_menu';
import useMediaQuery from '@mui/material/useMediaQuery';
// import DashboardFooter from './DashboardFooter';
import CloseIcon from '@mui/icons-material/Close';
import {TextField} from '@mui/material';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { BASE_URL } from '../components/helper';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Confetti from "react-confetti";
import Dashboard_au from './Dashboard_author';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import { Link} from 'react-router-dom';
import {Tabs} from '@mui/material';
import {Tab} from '@mui/material';
import { useState } from 'react';
import {Autocomplete} from '@mui/material';
import { useForm , Controller, FormProvider } from "react-hook-form";
const ServicesE = [
];
function Setting1() {
	const mobile = useMediaQuery('(max-width:600px)');
	const { register, watch,control,handleSubmit,setValue,reset, formState: { errors } } = useForm();
	const [email, setEmail] = useState('');
	const [error, setError] = useState('');
	const [showConfetti, setShowConfetti] = React.useState(false);
	const styles = {
		paperContainer: {
			backgroundImage: mobile?`url(https://drive.google.com/uc?export=view&id=19eIDGRfBybfg2NM3sqpc5st9g-Wgw7YA),url(https://drive.google.com/uc?export=view&id=10AfCZlkqBJNXIqQmUSA1fV5sWtNfj242)`:`url(https://drive.google.com/uc?export=view&id=11K46_gkQ5voIg-2ia3SwujJLoDtrbIQ2),url(https://drive.google.com/uc?export=view&id=12vB0TKVYwiTQHlHQuXqcOcjwDui3fspu)`,
			backgroundSize: mobile?'30%,38%':'11%,15%',
			backgroundPosition:'top left,bottom Right'
		}
	}
	const [open, setOpen] = React.useState(false);
	const [showGrid, setShowGrid] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [value1, setValue1] = React.useState(1);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const navigate = useNavigate();
	const handleClose = () => {
		setOpen(false);
		
	};
	const handleExplore =  (e) => {
		setOpen(true);
	};
	const handleChange = (event, newValue) => {
		setValue1(newValue);
	};
	const phoneNumber = '+91-7060495034';
	const emailAddress = 'Abhijeet@Hubhawks.com';
	const handleOpen = () => {
		setShowDialog(true);
	 }
	const handleDialog = () => {
		setShowDialog(false);
	 }
	const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const getUserFromToken = () => {
		const token = localStorage.getItem("token");
		if (!token) {
		  return null;
		}
		try {
		  const decoded = jwt_decode(token);
		  return decoded;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	  };
	   
		const handleChange11 = (event, value) => {
			setSelectedOptions(value);
			navigate(`../${value}`);
	}
	const onSubmit = async () => {
		const user = getUserFromToken();
	const response = await fetch(`${BASE_URL}/api/check/update-password`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('token')}`,
       
		},
		body: JSON.stringify({
			user,
		  currentPassword,
		  newPassword,
		  confirmPassword
		})
	  });
	  const data = await response.json();
	  if (response.ok) {
		alert(data.message);
	  } else {
		setError(data.error);
	  }
	  
  };
  return (
	  <>
		   <Dashboard_au />
		  <Box sx={{  backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'auto',height:mobile?'100vh':(showGrid)?"0px":'800px'}}>
			  {mobile ? '' :
				  <Box sx={{ width: "78%", left: mobile ? '24px' : '272px', position: 'absolute', marginTop: '24px' }}>
				  <Grid container lg={12} sx={{ marginTop: "20px" }}>
				<Grid container lg={6}>
					<Grid item lg={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>Setting</Typography>	
					</Grid>
				</Grid>
				<Grid container lg={6} sx={{justifyContent:'end'}}>
					<Grid item lg={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item lg={4} sx={{display:'flex',justifyContent:"center"}}>
					<Button onClick={handleOpen} variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
								  </Button>
								  <Dialog open={showDialog} onClose={handleClose} sx={{width:'500px',height:'280px',justifyContent:'center',margin:'auto',borderRadius:'0px 0px 12px 12px'}}>
						<DialogTitle sx={{ background: '#3A81F3', color: '#FFFFFF', textAlign: 'center' }}>
						<Grid container lg={12} xs={12}>
							<Grid item lg={10} xs={10}>
							Contact Us
							</Grid>
							<Grid item lg={2} xs={2}>
							<Button onClick={handleDialog}><CloseIcon sx={{color:'#FFFFFF'}} /></Button>
			 
								</Grid>
								</Grid></DialogTitle>
        <DialogContent >
							  <DialogContentText>
								<Grid container lg={12}>
									<Grid container lg={10} sx={{margin:'auto'}}>
									
									<Grid item lg={10} sx={{margin:'auto'}}>
	  <Button  href={`mailto:${emailAddress}`} style={{color:"black",textTransform:'none',fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><EmailOutlinedIcon />Abhijeet@Hubhawks.com</Button>
	  </Grid>
	  		<Grid item lg={8} sx={{margin:'auto'}}>
	  <Button href={`tel:${phoneNumber}`} style={{color:"black",fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><PhoneInTalkOutlinedIcon/>+91-7060495034</Button>
	  </Grid>
									</Grid>
								</Grid>
								  
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
					</Grid>
				</Grid>
					  </Grid>
		  </Box>}
      <Box component='form' width="1152px" height='950px' sx={{
				position: 'absolute',
width:mobile?'275px': '75%',
height: mobile?(showGrid)?"850px":'650px':'600px',
left: mobile?'24px':'32px',marginLeft:mobile?'24px':'240px',
top:mobile?'90px': '115px',

				borderRadius: '12px'
			}}>
        {mobile?<Tabs
					value={value1}
					onChange={handleChange}
        
      >
					<Tab label="Personal Settings" value1='0' style={{textTransform:'none'}} href="/Setting"  />
        <Tab label="Account Settings" value1='1' style={{textTransform:'none'}}href="/Settings1" />
       
		</Tabs>
					: 
	  <Tabs style={{marginTop:"26px",marginLeft:'0px'}}
					value={value1}
					onChange={handleChange}
      
      >
					<Tab label="Personal Settings" value1='0' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}}component={Link} to="/setting"  />
							<Tab label="Account Settings" value1='1' style={{ textTransform: 'none', color: '#000000', fontSize: '16px', fontWeight: 600 }} component={Link} to="/Setting1" />
							
		</Tabs>
		  
		  }
		  <Grid container lg={12} md={10} xs={12}>
		  <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={8} md={8} xs={10}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'30px':'56px',fontFamily: 'Roboto', fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>Current  Password *</Typography>
			</Grid>
            <Grid item  lg={6} md={8} xs={10}>
         <label htmlFor="field-rain" style={{fontSize:'18px',lineHeight:"28px",marginTop:'8px'}}></label>
             <input
            type="Current password"
            placeholder="Enter current name"
            value={currentPassword}
            onChange={(event) => setCurrentPassword(event.target.value)}
			style={{width:mobile?"250px":'550px',marginTop:mobile?'6px':'12px',
							height:mobile?"40px":'40px',marginRight:mobile?'6px':'12px'}}					  />
								  </Grid>
                  </Grid>
				  <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={8} md={8} xs={10}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px',fontFamily: 'Roboto', fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>New Password *</Typography>
			</Grid>
            <Grid item  lg={6} md={8} xs={10}>
         <label htmlFor="field-rain" style={{fontSize:'18px',lineHeight:"28px",marginTop:'8px'}}></label>
             <input
            type="New Password *"
            placeholder="Enter new password"
            value={newPassword}
			onChange={(event) => setNewPassword(event.target.value)}
			style={{width:mobile?"250px":'550px',marginTop:mobile?'6px':'12px',
							height:mobile?"40px":'40px',marginRight:mobile?'6px':'12px'}}					  />
								  </Grid>
                  </Grid>
				  <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={8} md={8} xs={10}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px',fontFamily: 'Roboto', fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>Confirm Password *</Typography>
			</Grid>
            <Grid item  lg={6} md={8} xs={10}>
         <label htmlFor="field-rain" style={{fontSize:'18px',lineHeight:"28px",marginTop:'8px'}}></label>
             <input
            type="Confirm Password *"
            placeholder="Re-enter new password"
            value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
			style={{width:mobile?"250px":'550px',marginTop:mobile?'6px':'12px',
							height:mobile?"40px":'40px'}}					  />
								  </Grid>
                  </Grid>
				 
				  <Grid container lg={2} md={12} xs={12}>
				 
							  <Grid item md={12} xs={11} lg={6} sx={{justifyContent:mobile?"center":"initial",display:'flex'}} >				  
       
							  </Grid>
							  
				  </Grid>
		  </Grid>
		  <Grid container lg={12} xs={8}>
			<Grid container lg={10} xs={12}>
			<Grid item md={12} xs={6} lg={2} sx={{justifyContent:mobile?"center":"initial",display:'flex'}} >				  
        <Button
              variant="contained"
              
				sx={{marginTop:mobile?'16px':'26px',textTransform: 'none',fontWeight:'500',fontSize:mobile?'12px':'16px',borderRadius:'8px',
								  height: mobile ? '40px' : '48px',display:'flex',backgroundColor:'#F3F5F9',color:'#63636D',border:'1px solid #ACACAC',
							  }}
							  onClick={handleExplore}
            >
              DISCARD
							  </Button>
							  </Grid>
							  <Grid item md={12} xs={6} lg={2} sx={{justifyContent:mobile?"center":"initial",display:'flex'}} >				  
        <Button
              variant="contained"
              
				sx={{marginTop:mobile?'16px':'26px',textTransform: 'none',fontWeight:'500',fontSize:mobile?'12px':'16px',borderRadius:'8px',
								  height: mobile ? '40px' : '48px',display:'flex',backgroundColor:'#3A81F3',color:'primary'
							  }}
							  onClick={handleSubmit(onSubmit)}
            >
              Save
							  </Button>
							  </Grid>
			</Grid>
			<Grid container lg={2}>
			<Button
              variant="contained"
              
				sx={{marginTop:mobile?'16px':'26px',textTransform: 'none',fontWeight:'500',fontSize:mobile?'12px':'16px',borderRadius:'8px',
								  height: mobile ? '40px' : '48px',display:'flex',backgroundColor:'#F3F5F9',color:'#FF0000',border: '1px solid #FF0000'
							  }}
							  onClick={handleExplore}
            >
             Delete Account 
							  </Button>
			</Grid>
		  </Grid>
		  </Box>
		  </Box>
	  </>
  )
}
export default Setting1;