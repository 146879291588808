import React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Dashboard_au from '../Dashboard_author/Dashboard_author';
import { useMediaQuery } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import {Image} from 'mui-image';
import { Autocomplete } from '@mui/material';

const ServicesE = [
	{ title: "Editing" ,value:'editing'},
	{ title: "Cover Designing"},
	{ title: "Translation" },
	{ title: "Book Store" },
	{ title: "Book Reviews" },{ title: "Book Trailer" },{ title: "Ghost Writing" },{ title: "Amazon Ads" }
  ];

function Masterclass() {
	const mobile = useMediaQuery('(max-width:600px)');
	const [showGrid, setShowGrid] = useState(false);
	const navigate = useNavigate();
	const [selectedOption, setSelectedOptions] = useState("");
	const handleChange11 = (event, value) => {
		setSelectedOptions(value);
		navigate(`../${value}`);
	}
  return (<>
	<Dashboard_au />
		<Box sx={{  backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'auto',height:mobile?'1600px':(showGrid)?"2100px":'850px'}}>
		{mobile ? '' :<Box sx={{width:"78%",left: mobile?'24px':'272px',position: 'absolute',marginTop:'24px'}}>
				  <Grid container md={12} lg={12} sx={{ marginTop: "20px" }}>
				<Grid container md={6} lg={6}>
					<Grid item md={9}lg={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>MasterClass</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} lg={6} sx={{justifyContent:'end'}}>
					<Grid item md={6} lg={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={3} lg={3} sx={{display:'flex',justifyContent:"center"}}>
					<Button variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
          </Button>
					</Grid>
				</Grid>
				  </Grid>
		  </Box>}	
		  <Box   width="1152px" height='1148px' sx={{
				position: 'absolute',
width: mobile?'85%': '75%',
height: mobile?(showGrid)?"1500px":'1400px':(showGrid)?"000px":'00px',
left: mobile?'2px':'32px',marginLeft:mobile?'24px':'240px',
top: mobile?'90px': '115px',
		  }}>
			   <Grid container lg={12}>
				  <Grid container lg={6}>
				<Grid item lg={6.2} >  
			  <Link to='/Instructor' style={{textTransform:"none",textDecoration:'none'}}> 
            <Image Duration={0}
              src="https://drive.google.com/uc?export=view&id=1pAQzoyrZegi-EHsOIkzuNHvqf9PXkgfo"
              style={{ m: "auto",transitionDuration:'0',animation:'0',borderRadius:mobile?'8px':"12px",width:"100%",height:"100%"}}
								/>
						  </Link>	 
					  </Grid>
					  <Grid item lg={12} xs={12} sx={{marginTop:'8px'}}>
						  <Typography sx={{fontSize: mobile?"16px":'18px',fontWeight:"600",textAlign:'initial'}}>Writing lessons by Kevin Missal</Typography>
					  </Grid>
					  <Grid item lg={12} xs={12}>
						  <Typography sx={{fontSize: mobile?"12px":'18px',textAlign:'initial',fontWeight:"700",color:'#63636D'}}>Free</Typography>
					  </Grid>
					  
			  </Grid>
		  </Grid>
			  
		  </Box>
		 
		  
	  </Box>
	  </>
  )
}
export default Masterclass;
