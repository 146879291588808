import React,{ useRef } from 'react';
import { useState } from 'react';
import { Autocomplete, Paper, TextField, Typography } from '@mui/material';
import Dashboard_au from './Dashboard_author';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { Link, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import './styles.css';
import {Image} from 'mui-image';
import { useForm , Controller, FormProvider } from "react-hook-form";
import { Email_URL } from '../components/helper';
import useMediaQuery from '@mui/material/useMediaQuery';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import { Button, Grid } from '@mui/material';
import {  useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
const ServicesE = [
	{ title: "Editing" ,value:'editing'},
	{ title: "Cover Designing"},
	{ title: "Translation" },
	{ title: "Book Store" },
	{ title: "Book Reviews" },{ title: "Book Trailer" },{ title: "Ghost Writing" },{ title: "Amazon Ads" }
  ];
function Publish() {
	const mobile = useMediaQuery('(max-width:600px)');
	const navigate = useNavigate();
	const { register, watch,control,handleSubmit,setValue,reset, formState: { errors } } = useForm();
	const [open, setOpen] = React.useState(false);
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [showFiles, setShowFiles] = useState(false);
	const [Phonenumber, setPhonenumber] = useState('');
	const [booktitle, Setbooktitle] = useState('');
	const [Genre, SetGenre] = useState('');
	const [WordCount, SetWordCount] = useState('');
	const [Authorbio, SetAuthorbio] = useState('');
	const [Inspired, SetInspired] = useState('');
	const [isChecked, setIsChecked] = useState(false);
	const [Unique, SetUnique] = useState('');
	const [Vision, SetVision] = useState('');
	const [fileNames, setFileNames] = useState(['', '', '']);
	const [Publisher, SetPublisher] = useState('');
	const [Experience, SetExperience] = useState('');
	const [Investment, SetInvestment] = useState('');

	const [Additional, SetAdditional] = useState('');
	const [ContestHearing, SetContestHearing] = useState('');
	const [SampleChapter, SetChapter] = useState('');
	const [Synposis, SetSynposis] = useState('');
	const [checkbox, SetCheckbox] = useState('');

	const [photo, setPhoto] = useState(null);
	const [lastname, setLastName] = useState('');
	const[Phone,setPhone]=useState('');
	const [selectedOptions, setSelectedOptions] = useState("");
	const [showDialog, setShowDialog] = useState(false);
	const [files, setFiles] = useState([null, null]);
	const [userId, setUserId] = useState('');
	const fileInputRef = useRef(null);
	const fileInputRef2 = useRef(null);
	
const handleClickww = () => {
	setIsChecked(!isChecked);
  };
	const handleClick = () => {
	  fileInputRef.current.click();
	}
	const handleClick1 = () => {
		fileInputRef2.current.click();
	  }
	const getUserFromToken = () => {
	  const token = localStorage.getItem('token');
	  if (!token) {
		return null;
	  }
	  try {
		const decoded = jwt_decode(token);
		return decoded;
	  } catch (error) {
		console.error(error);
		return null;
	  }
	};
	const getUserFromToken1 = () => {
		const gogle = localStorage.getItem("user");
		console.log(gogle, 'token');
		if (!gogle)
			return null;
		try {
			const decoded = jwt_decode(gogle);
			console.log(decoded, 'toksen');
		  return decoded;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	  };
	const handleFileChange = (e, index) => {
	  const newFiles = [...files];
	  newFiles[index] = e.target.files[0];
		setFiles(newFiles);
		setFileNames(prevState => {
			const newFileNames = [...prevState];
			newFileNames[index] = e.target.files[0].name;
			return newFileNames;
		  });
	};
	const handleChange11 = (event, value) => {
		setSelectedOptions(value);
		navigate(`../${value}`);
	}
	const phoneNumber = '+91-7060495034';
	const emailAddress = 'Abhijeet@Hubhawks.com';
	const handleDropdownChange = (e) => {
		SetWordCount(e.target.value);
	};
	
	const handleDropdownChange1 = (e) => {
		SetContestHearing(e.target.value);
	  };
	// const onSubmit = async (data, e) => {
	// 	setOpen(true);
	// 	const formData = new FormData();
	// 	console.log(data);
	// 	e.preventDefault();
	// 	const user1 = getUserFromToken1();
	// 	const user = getUserFromToken();
	// 	if (user) {
			
	// 		if (!user) return;
	// 		const response = await axios.post(`${Email_URL}/api/upload/api/upload`, {
	// 			...data, user
			
	// 		}).then((res) => res.json()).then(async (res) => {
	// 			const resData = await res;
	// 			console.log(resData);
	// 			if (resData.status === "success") {
	// 				alert("Message Sent");
	// 			} else if (resData.status === "fail") {
	// 				alert("Message failed to send");
	// 			}
	// 		})
	// 		console.log(JSON.stringify(data), user, 'rajivk');
	// 	}
	// 	else {
			
	// 		if (user1) {
	// 			const user1 = getUserFromToken1();
	// 		if (!user1) return;
	// 		const response = await axios.post(`${Email_URL}/api/email/booktrailerG`, {
	// 			...data, user1
			
	// 		}).then((res) => res.json()).then(async (res) => {
	// 			const resData = await res;
	// 			console.log(resData);
	// 			if (resData.status === "success") {
	// 				alert("Message Sent");
	// 			} else if (resData.status === "fail") {
	// 				alert("Message failed to send");
	// 			}
	// 		})
	// 		console.log(JSON.stringify(data), user1, 'rajivk');
	// 		}
	// 	}
	// 	console.log(JSON.stringify(data));
	// 	console.log(FormData, 'hi');
	//   };
	const handleFileUpload = async (event, data) => {
		event.preventDefault();
		const user1 = getUserFromToken1();
		const user = getUserFromToken();
		setOpen(true);
		if (user) {
			if (!user) return;
			try {
				const formData = new FormData();
				const user = getUserFromToken();
				console.log(data);
				formData.append('Name', name);
				formData.append('Email', email);
				formData.append('Phonenumber', Phonenumber);
				formData.append('booktitle', booktitle);
				formData.append('Genre', Genre);
				formData.append('WordCount', WordCount);
				formData.append('Authorbio', Authorbio);
				formData.append('Inspired', Inspired);
				formData.append('Unique', Unique);
				formData.append('Experience', Experience);
				formData.append('Vision', Vision);
				formData.append('ContestHearing', ContestHearing);
				formData.append('Publisher', Publisher);
				formData.append('Additional', Additional);
				formData.append('Investment', Investment);

				formData.append('userId', user._id);
				formData.append('files', files[0]);
				formData.append('files1', files[1]);
				formData.append('files2', files[2]);
	
				const res = await axios.post(`${Email_URL}/api/upload/api/upload`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'Authorization': `Bearer ${localStorage.getItem('user')}`,
					},
				});
				console.log(res.data);
			} catch (err) {
				console.error(err);
			}
			const formData1 = {
				Name: name,
				Email: email,
				Phonenumber: Phonenumber,
				booktitle: booktitle,
				Genre: Genre,
				WordCount: WordCount,
				Authorbio: Authorbio,
				Inspired: Inspired,
				Unique: Unique,
				Experience: Experience,
				Vision: Vision,
				ContestHearing: ContestHearing,
				Publisher: Publisher,
				Additional: Additional,
				Investment: Investment,
				userId: user._id,
			};
			console.log(formData1)
			const res = await axios.post(
				`${Email_URL}/api/publish/submit-form`,
				formData1,
				{
				  headers: {
					'Content-Type': 'application/json',
				  },
				}
			  );
			  console.log(res.data);
		}
		else { 
			if (user1) { 
				const user1 = getUserFromToken1();
				if (!user1) return;
				try {
					const formData = new FormData();
					const user1 = getUserFromToken1();
					console.log(user1,'chekcing')
					console.log(data);
					formData.append('Name', name);
					formData.append('Email', email);
					formData.append('Phonenumber', Phonenumber);
					formData.append('booktitle', booktitle);
					formData.append('Genre', Genre);
					formData.append('WordCount', WordCount);
					formData.append('Authorbio', Authorbio);
					formData.append('Inspired', Inspired);
					formData.append('Unique', Unique);
					formData.append('Experience', Experience);
					formData.append('Vision', Vision);
					formData.append('ContestHearing', ContestHearing);
					formData.append('Publisher', Publisher);
					formData.append('Additional', Additional);
					formData.append('Investment', Investment);
	
					formData.append('userId', user1.given_name);
					formData.append('userId1', user1.email);
					formData.append('files', files[0]);
					formData.append('files1', files[1]);
					formData.append('files2', files[2]);
		
					const res = await axios.post(`${Email_URL}/api/upload/api/uploadG`, formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'Authorization': `Bearer ${localStorage.getItem('user')}`,
						},
					});
					console.log(res.data);
				} catch (err) {
					console.error(err);
				}
				const formData1 = {
					Name: name,
					Email: email,
					Phonenumber: Phonenumber,
					booktitle: booktitle,
					Genre: Genre,
					WordCount: WordCount,
					Authorbio: Authorbio,
					Inspired: Inspired,
					Unique: Unique,
					Experience: Experience,
					Vision: Vision,
					ContestHearing: ContestHearing,
					Publisher: Publisher,
					Additional: Additional,
					Investment: Investment,
					
				};
				console.log(formData1)
				const res = await axios.post(
					`${Email_URL}/api/publish/submit-form`,
					formData1,
					{
					  headers: {
						'Content-Type': 'application/json',
					  },
					}
				  );
				  console.log(res.data);
			}
		}
	}
	const handleOpen = () => {
		setShowDialog(true);
	 }
	const handleDialog = () => {
		setShowDialog(false);
	}
	const handleClose = () => {
		setOpen(false);
		setName('');
		setEmail('');
		setPhonenumber('');
		Setbooktitle('');
		SetGenre('');
		SetWordCount('');
		SetAuthorbio('');
		SetInspired('');
		setIsChecked(false);
		SetUnique('');
		SetVision('');
		setFileNames('');
		SetPublisher('');
		SetExperience('');
		SetInvestment('');
		SetAdditional('');
		SetContestHearing('');

		
	}
  return (
	  <>
		  <Dashboard_au />
		  <Box sx={{  backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'#F3F5F9':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'auto',height:mobile?'2600px':'3000px'}}>
		{mobile ? '' :
				<Box sx={{ width: "78%", left: mobile ? '24px' : '272px', position: 'absolute', marginTop: '24px' }}>
					<Grid container md={12} lg={12} sx={{ marginTop: "20px" }}>
				<Grid container md={6} lg={6}>
					<Grid item md={9} lg={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>Publish With us</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} lg={6} sx={{justifyContent:'end'}}>
					<Grid item md={6} lg={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={4} lg={4} sx={{display:'flex',justifyContent:"center"}}>
					<Button onClick={handleOpen} variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
								</Button>
								<Dialog open={showDialog} onClose={handleClose} sx={{width:'500px',height:'280px',justifyContent:'center',margin:'auto',borderRadius:'0px 0px 12px 12px'}}>
						<DialogTitle sx={{ background: '#3A81F3', color: '#FFFFFF', textAlign: 'center' }}>
						<Grid container lg={12} xs={12}>
							<Grid item lg={10} xs={10}>
							Contact Us
							</Grid>
							<Grid item lg={2} xs={2}>
							<Button onClick={handleDialog}><CloseIcon sx={{color:'#FFFFFF'}} /></Button>
			 
								</Grid>
								</Grid></DialogTitle>
        <DialogContent >
							  <DialogContentText>
								<Grid container lg={12}>
									<Grid container lg={10} sx={{margin:'auto'}}>
									
									<Grid item lg={10} sx={{margin:'auto'}}>
	  <Button  href={`mailto:${emailAddress}`} style={{color:"black",textTransform:'none',fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><EmailOutlinedIcon />Abhijeet@Hubhawks.com</Button>
	  </Grid>
	  		<Grid item lg={8} sx={{margin:'auto'}}>
	  <Button href={`tel:${phoneNumber}`} style={{color:"black",fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><PhoneInTalkOutlinedIcon/>+91-7060495034</Button>
	  </Grid>
									</Grid>
								</Grid>
								  
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
					</Grid>
				</Grid>
					</Grid>
				  </Box>}
				  <Box component='form' width="1152px" height='1148px' sx={{
				position: 'absolute',
width: mobile?'272px': 'auto',
height: mobile?'1800px':'1800px',
left: mobile?'48px':'272px',
top: mobile?'90px': '160px',
				  borderRadius: '12px'
			  }}>
				    <Grid container lg={12} style={{ display: 'flex' }}>
  <Grid item style={{ margin: '0px' }}>
						  <Image src='https://drive.google.com/uc?export=view&id=1KD7YgLIpNNwIJZ_KdPrtRV7MIwiM27LQ' width={mobile ? "43px" : '102px'} height={mobile?"54px":'200px'} style={{ marginBottom:'26px' }} />
  </Grid>
</Grid>
				  <Grid container lg={8.5} >
				  <Typography style={{fontSize:mobile?'12px':'18px',lineHeight:'26px',color:'#333335',textAlign:'initial'}}>To apply for the contest, please fill out the form below and provide us with some information about yourself and your work:</Typography>	  
				  </Grid>
				  <Grid container lg={12}>
        <Grid container lg={10}>
      <Grid container lg={6} md={12} xs={12}>
      <Grid item lg={8} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'26px':'15px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>Name*</Typography>
			</Grid>
            <Grid item  lg={6} md={8} xs={12}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
             <input
            type="Name"
            placeholder="Enter your name"
									  value={name}	
									  					  
            onChange={(e) => setName(e.target.value)}
			style={{width:mobile?"272px":'470px',marginTop:mobile?'6px':'12px',margin:mobile?'auto':'auto',
							height:mobile?"40px":'40px'}}		required  />
								  </Grid>
                  </Grid>
                  <Grid container lg={6} md={12} xs={12}>
      <Grid item lg={8} md={8} xs={10}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'15px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>Email*</Typography>
			</Grid>
            <Grid item  lg={8} md={8} xs={12}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
             <input
            type="Email"
            placeholder="Enter your Email"
									  value={email}
									  required
            onChange={(e) => setEmail(e.target.value)}
			style={{width:mobile?"272px":'470px',marginTop:mobile?'6px':'12px',margin:mobile?'auto':'auto',
							height:mobile?"40px":'40px'}}					  />
								  </Grid>
                  </Grid>
                  <Grid container lg={6} md={12} xs={12}>
      <Grid item lg={8} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>Phone Number*</Typography>
			</Grid>
            <Grid item  lg={8} md={8} xs={12}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
             <input
            type="phone number"
									  placeholder="Enter your phone Number"
									  value={Phonenumber}
									  required
									  onChange={(e) => setPhonenumber(e.target.value)}	
           
			style={{width:mobile?"272px":'470px',marginTop:mobile?'6px':'12px',margin:mobile?'auto':'auto',
							height:mobile?"40px":'40px'}}					  />
								  </Grid>
						  </Grid>
						  <Grid container lg={6} md={12} xs={12}>
      <Grid item lg={8} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>Book title*</Typography>
			</Grid>
            <Grid item  lg={8} md={8} xs={12}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
             <input
            type="Book title"
            placeholder="Enter your Book Title"
			value={booktitle}
			required				
			onChange={(e) => Setbooktitle(e.target.value)}						
									
            
			style={{width:mobile?"272px":'470px',marginTop:mobile?'6px':'12px',margin:mobile?'auto':'auto',
							height:mobile?"40px":'40px'}}					  />
								  </Grid>
                  </Grid>
				  <Grid container lg={6} md={12} xs={12}>
      <Grid item lg={8} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>Genre*</Typography>
			</Grid>
            <Grid item  lg={8} md={8} xs={12}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
             <input
            type="Genre"
            placeholder="Genre"
			value={Genre}
			required				
			onChange={(e) => SetGenre(e.target.value)}					
									  
           
			style={{width:mobile?"272px":'470px',marginTop:mobile?'6px':'12px',margin:mobile?'auto':'auto',
							height:mobile?"40px":'40px'}}					  />
								  </Grid>
                  </Grid>

                  <Grid container lg={6} md={12} xs={12}>
      <Grid item lg={8} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>WordCount*</Typography>
			</Grid>
            <Grid item  lg={8} md={8} xs={12}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
								  <select required value={WordCount} onChange={handleDropdownChange}
								  style={{width:mobile?"272px":'470px',marginTop:mobile?'6px':'12px',margin:mobile?'auto':'auto',
								  height:mobile?"40px":'40px'}}	>
        <option value="WordCount">Select Range</option>
        <option value="30k-50k">30k-50k</option>
        <option value="50k-70k">50k-70k</option>
        <option value="70k-90k">70k-90k</option>
      </select>
								  
							  </Grid>
							  
						  </Grid>
						  <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={12} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>Author Bio*</Typography>
			</Grid>
            <Grid item  lg={11.8} md={8} xs={12}sx={{display:'flex',justifyContent:'initial'}} >
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
			
								  <TextField 
							 required
							 placeholder="Write your bio"
							 value={Authorbio}	
									  fullWidth	
									  style={{
										  background:'#FFFFFF'}}
							onChange={(e) => SetAuthorbio(e.target.value)}	
									  multiline
									  inputProps={{ style: { padding:mobile?'8px 8px':'10px 16px'} }}
						rows={mobile?2:3}
							 margin="dense"		
        />
								  </Grid>
						  </Grid>
						  {/* <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={12} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>What inspired you to write this book?*</Typography>
			</Grid>
            <Grid item  lg={11.8} md={8} xs={12} sx={{display:'flex',justifyContent:'initial'}}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
							
		 <TextField 
							 required
							 placeholder="Write here"
							 value={Inspired}
									  fullWidth	
									  style={{
										  background:'#FFFFFF'}}
										  onChange={(e) => SetInspired(e.target.value)}
									  multiline
									  inputProps={{ style: { padding:mobile?'8px 8px':'10px 16px'} }}
						rows={mobile?2:3}
							 margin="dense"		
        />
								 
								  </Grid>
						  </Grid> */}
						  <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={12} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>What makes your book unique?*</Typography>
			</Grid>
            <Grid item  lg={11.8} md={8} xs={12} sx={{display:'flex',justifyContent:'initial'}}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
		 <TextField 
							
							 placeholder="Write here"
							 value={Unique}
									
			onChange={(e) => SetUnique(e.target.value)}	
									  fullWidth	
									  style={{
										  background:'#FFFFFF'}}
									  multiline
									  inputProps={{ style: { padding:mobile?'8px 8px':'10px 16px'} }}
						rows={mobile?2:3}
							 margin="dense"		
        />
					
					
								
								  </Grid>
						  </Grid>
						  {/* <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={12} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>How does your book align with the vision of Nu Voice Press?*</Typography>
			</Grid>
			<Grid item  lg={11.8} md={8} xs={12} sx={{display:'flex',justifyContent:'initial'}}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
		 <TextField 	
							placeholder="Write here"
							value={Vision}			
			onChange={(e) => SetVision(e.target.value)}		
									 fullWidth	
									 style={{
										 background:'#FFFFFF'}}
									 multiline
									 inputProps={{ style: { padding:mobile?'8px 8px':'10px 16px'} }}
					   rows={mobile?2:3}
							margin="dense"		
	   />
								 
								  </Grid>
                  </Grid> */}
				  <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={12} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>Have you submitted your book to any other publishers or contests?*</Typography>
			</Grid>
            <Grid item  lg={11.8} md={8} xs={12} sx={{display:'flex',justifyContent:'initial'}}>
								  <label htmlFor="field-rain" style={{ fontSize: mobile ? '12px' : '18px', lineHeight: "28px", marginTop: '8px' }}></label>
								  <FormControl component="fieldset">
      <RadioGroup name="publisher" value={Publisher}  onChange={(e) => SetPublisher(e.target.value)}>
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
		 {/* <TextField 	
							placeholder="Write here"
							value={Publisher}	
												  
  onChange={(e) => SetPublisher(e.target.value)}			
									 fullWidth	
									 style={{
										 background:'#FFFFFF'}}
									 multiline
									 inputProps={{ style: { padding:mobile?'8px 8px':'10px 16px'} }}
					   rows={mobile?2:3}
							margin="dense"		
	   />
								   */}
								  </Grid>
                  </Grid>
				  {/* <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={12} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>What is your writing background and experience?*</Typography>
			</Grid>
			<Grid item  lg={11.8} md={8} xs={12} sx={{display:'flex',justifyContent:'initial'}}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
		 <TextField 	
							placeholder="Write here"
							value={Experience}	
							required						  
  onChange={(e) => SetExperience(e.target.value)}				
									 fullWidth	
									 style={{
										 background:'#FFFFFF'}}
									 multiline
									 inputProps={{ style: { padding:mobile?'8px 8px':'10px 16px'} }}
					   rows={mobile?2:3}
							margin="dense"		
	   />
								  
								  
								  </Grid>
                  </Grid> */}
				  <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={12} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>Would you be willing to invest in marketing your book if chosen for publication?*</Typography>
			</Grid>
            <Grid item  lg={11.8} md={8} xs={12} sx={{display:'flex',justifyContent:'initial'}}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
		 {/* <TextField 	
							placeholder="Write here"
							value={Investment}
							required						  
  onChange={(e) => SetInvestment(e.target.value)}				
									 fullWidth	
									 style={{
										 background:'#FFFFFF'}}
									 multiline
									 inputProps={{ style: { padding:mobile?'8px 8px':'10px 16px'} }}
					   rows={mobile?2:3}
							margin="dense"		
	   /> */}
						 <FormControl component="fieldset">
      <RadioGroup name="Investment" value={Investment}   onChange={(e) => SetInvestment(e.target.value)}>
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>		 
								 
								  </Grid>
                  </Grid>

				  {/* <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={12} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>Is there anything else you would like to tell us about yourself or your book?*</Typography>
			</Grid>
			<Grid item  lg={11.8} md={8} xs={12} sx={{display:'flex',justifyContent:'initial'}}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
		 <TextField 	
							placeholder="Write here"
							value={Additional}	
							required						  
  onChange={(e) => SetAdditional(e.target.value)}				
									 fullWidth	
									 style={{
										 background:'#FFFFFF'}}
									 multiline
									 inputProps={{ style: { padding:mobile?'8px 8px':'10px 16px'} }}
					   rows={mobile?2:3}
							margin="dense"		
	   />
								
								  
								  </Grid>
                  </Grid> */}
						  <Grid container lg={12} md={12} xs={12}>
							  <Grid container lg={8}>
							  <Grid item lg={12} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>How did you hear about this contest?*</Typography>
			</Grid>
			<Grid item  lg={11.8} md={8} xs={12} sx={{display:'flex',justifyContent:'initial'}}>
         <label htmlFor="field-rain" style={{fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}></label>
								  <select required value={ContestHearing} onChange={handleDropdownChange1}
								  style={{width:mobile?"272px":'470px',marginTop:mobile?'6px':'12px',
								  height:mobile?"40px":'40px'}}	>
        <option value="ContestHearing">Select Option</option>
        <option value="Social Media">Social Media</option>
        <option value="Ads">Ads</option>
										  <option value="NewsPaper">NewsPaper</option>
										  <option value="Friend and Family">Friend and Family</option>
										  <option value="Others">Others</option>
      </select>
								  </Grid>
							  
							  <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={12} md={8} xs={12}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>Sample Chapters (Only 3)*</Typography>
			</Grid>
							  <Grid item lg={12} md={8} xs={10} sx={{display:'flex',justifyContent:'initial',marginTop:'20px'}}>
							  <input required type="file" id='fileinput' name='files' accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document" ref={fileInputRef}
 style={{ display: 'none' }} onChange={(e) => handleFileChange(e, 0)} />
							 <span>{fileNames[0]}</span>
							  <Button variant='outlined' onClick={handleClick} sx={{textTransform:'none'}}>Upload</Button>
			
								  </Grid>
						  </Grid>
						  <Grid container lg={12} md={12} xs={12}>
      <Grid item lg={12} md={8} xs={10}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:mobile?'16px':'58px', fontSize: mobile?'12px':'18px', fontWeight: '600', fontStyle: 'normal' }}>Synopsis/Concept Note (Word Limit:800 words )*</Typography>
			</Grid>
            <Grid item  lg={12} md={8} xs={10} sx={{display:'flex',justifyContent:'initial',marginTop:'20px'}}>
								  <input required type="file" name='files' accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document" ref={fileInputRef2}  style={{ display: 'none' }} onChange={(e) => handleFileChange(e, 1)} />
								  <span>{fileNames[1]}</span>
							  <Button variant='outlined' onClick={handleClick1} sx={{textTransform:'none'}}>Upload</Button>
								  </Grid>
								  </Grid>
								  <Grid container lg={12} md={12} xs={12}>
    
							  <Grid item lg={12} md={8} xs={12} sx={{display:'flex',justifyContent:'initial',marginTop:'20px'}}>
					<label style={{textAlign:"initial"}}>
  <input  required type="checkbox" checked={isChecked} onClick={handleClickww} />
  By clicking this box, you agree to our <a href="https://nuvoicepress.com/contest" target="_blank" rel="noopener noreferrer" style={{textTransform:'none',textDecoration:'none',color:'#3A81F3'}}>Submission Guidelines</a> and<Link to="/terms"  target="_blank"  style={{textTransform:'none',textDecoration:'none',color:'#3A81F3'}}>Terms and Conditions</Link>.
  </label>	

								  </Grid>
						  </Grid>
								  </Grid>
								  <Grid container lg={4} style={{ display: 'flex' }}>
  <Grid item style={{ margin: 'auto 0 auto auto' }}>
    <Image src='https://drive.google.com/uc?export=view&id=1ulunaQCtMaCePNCzzv6TdZwjgKyU1Ehi' style={{ height: mobile?"261px":'432px', width: mobile?"216px":'358px' }} />
  </Grid>
</Grid>

    
						  </Grid>
						  
						  
						
				  
      
     
							  <br />
							  <Grid container md={12} lg={12} xs={12} sx={{display:'flex',justifyContent:mobile?"center":'initial',marginTop:'20px'}}>
							  <Button type='submit'
              variant="contained"
								  color="primary"
								  disabled={!isChecked} 
				onClick={handleFileUpload}
				sx={{backgroundColor:'#3A81F3',marginTop:mobile?'12px':'56px',marginLeft:mobile?'16px':'2px',textTransform: 'none',fontWeight:'500',fontSize:'16px',borderRadius:'8px',width: '91px',
				height: '48px'}}
							  >
								  
              Submit
							  </Button>
							  <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Contest Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <CheckCircleOutlineIcon /> Your form is submitted!
We will get back to you soon.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
						</Grid>
   		  
                  </Grid>
				  </Grid>
      
			  </Box>
			  </Box>
	  </>
  )
}
export default Publish;
