
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dashboard_au from '../Dashboard_author/Dashboard_author';
import { Box } from '@mui/system';
import {useMediaQuery} from '@mui/material';
import {Grid} from '@mui/material';
import {Button} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { Typography } from '@mui/material';
import {TextField} from '@mui/material';
import { Image } from 'mui-image';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const ServicesE = [
	{ title: "Editing" ,value:'editing'},
	{ title: "Cover Designing"},
	{ title: "Translation" },
	{ title: "Book Store" },
	{ title: "Book Reviews" },{ title: "Book Trailer" },{ title: "Ghost Writing" },{ title: "Amazon Ads" }
  ];
function Episode3() {
	const [data, setData] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [showGrid, setShowGrid] = useState(false);
	const [progress, setProgress] = useState(0);
	const [canProceed, setCanProceed] = useState(false);
	const handleProgress = ({ played }) => {
		const progress = played * 100;
		setProgress(progress);
		if (progress >= 80) {
		  setCanProceed(true);
		} else {
		  setCanProceed(false);
		}
	  }
	const mobile = useMediaQuery('(max-width:600px)');
	const handleChange11 = (event, value) => {
		setSelectedOptions(value);
		navigate(`../${value}`);
	}
	// useEffect(() => {
	//   const fetchData = async () => {
	// 	const response = await fetch("http://localhost:8080/api/check/signed-url/Episode1.mp4");
	// 	  const jsonData = await response.json();
	// 	  console.log(jsonData)
	// 	setData(jsonData);
	//   };
  
	//   fetchData();
	// }, []);

	const [videoEnded, setVideoEnded] = useState(false);
	const navigate = useNavigate();
	const [signedUrl, setSignedUrl] = useState(null);
	
 const config = {
    file: {
      attributes: {
			controlsList: 'nodownload',
		  poster:"https://drive.google.com/uc?export=view&id=1Cqa_EKmPCzDlxdpyUqPeEiOizBK6qSZ3",
        onContextMenu: e => e.preventDefault()
      }
    }
	};
	const handleNext1= () => {
		navigate('/Episode4');
	};
 const handleVideoEnd = () => {
	 setVideoEnded(true);
	 navigate('/Episode4');
  };
 const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
 const handleNext = () => {
	navigate('/Episode2');
 };
	
  return (
	  <>
		  <Dashboard_au />
		  <Box sx={{  backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'auto',height:mobile?'1600px':(showGrid)?"0px":'1150px'}}>
		  {mobile ? '' :<Box sx={{width:"78%",left: mobile?'24px':'272px',position: 'absolute',marginTop:'24px'}}>
				  <Grid container md={12} lg={12} sx={{ marginTop: "20px" }}>
				<Grid container md={6} lg={6}>
					<Grid item md={9}lg={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>MasterClass</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} lg={6} sx={{justifyContent:'end'}}>
					<Grid item md={6} lg={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={3} lg={3} sx={{display:'flex',justifyContent:"center"}}>
					<Button variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
          </Button>
					</Grid>
				</Grid>
				  </Grid>
		  </Box>}
		  
			   <Box  sx={{
				position: 'absolute',
left: mobile?'48px':'272px',
top: mobile?'90px': '115px',
			  }}>
				  <Grid container lg={12} xs={12}>
					<Link to='../Episode2'> <ArrowBackIcon style={{ color:'#333335',
						  marginBottom:"13px"}} /></Link> 
				  </Grid>
        <Grid container lg={12} >
                <Grid container md={12} xs={11} lg={8.5}>
        <Grid item lg={9} md={12} xs={12}   sx={{textAlign: 'center'}}>
          <ReactPlayer
				url='https://drive.google.com/uc?export=view&id=1pzrC9wV-LXfIGNYYjmh6nBpKQa-5qYGy'
				onEnded={handleVideoEnd}
								  config={config}
								  onProgress={handleProgress}
								  controls={true}
								  width={mobile?'100%':'131%'}
								  height='100%'
			  />
						  </Grid>
				
						  <Grid item lg={7.8} md={12} xs={12} sx={{ textAlign: 'center' }}>
						  {mobile?<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant='h2' sx={{ textAlign: 'initial', fontSize: mobile ? '16px' : '24px', lineHeight: mobile ? '20px' : '40px', fontWeight: mobile ? '700' : '600', marginTop: "20px" }}>Episode 3: Where to get ideas from?</Typography>
        </AccordionSummary>
        <AccordionDetails>
		<Typography  sx={{textAlign:'initial',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':'26px',fontWeight:mobile?'700':'500',marginTop:mobile?'12px':'8px'}}>	In Episode 3 of the writing course, we explore the question that plagues many writers - where to get ideas from? Kevin Missal, a well-known author and experienced writing teacher, shares his insights and strategies for generating ideas and finding inspiration.

Through this episode, you will learn how to tap into your personal experiences, observations, and emotions to come up with unique and compelling ideas for your writing. Kevin also discusses the importance of reading widely and paying attention to the world around you as a means of gathering ideas.

With practical exercises and examples, Kevin guides you through the process of brainstorming and refining your ideas, helping you to develop a solid foundation for your writing projects. Whether you are struggling with writer's block or simply looking for new ways to spark your creativity, this episode is sure to provide you with valuable insights and strategies.

By the end of this episode, you will have a better understanding of where to get ideas from and how to develop those ideas into successful writing projects. So, join us in this exciting episode of the writing course and take the first step towards unleashing your creative potential.
				  </Typography>
        </AccordionDetails>
      </Accordion>
:""}
      {mobile?"": <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'16px':'24px',lineHeight:mobile?'20px':'40px',fontWeight:mobile?'700':'600',marginTop:"20px"}}>Episode 3: Where to get ideas from?</Typography>
	  }	  </Grid>
						  <Grid item lg={4} md={12} xs={12}   sx={{textAlign: 'center',justifyContent:"space-between",display:'flex'}}>
							  <Grid>
							  <Button  variant='contained' sx={{ textAlign: 'initial', fontSize: mobile ? '16px' : '16px', lineHeight: mobile ? '20px' : '40px', fontWeight: mobile ? '700' : '500', backgroundColor: "#3A81F3", width: "100%", height: '40px', marginTop: "20px", textTransform:'none'}} onClick={() => { handleNext() }}>Previous</Button>
        
							  </Grid>
							  <Grid>
							  <Button disabled={!canProceed} variant='contained' sx={{ textAlign: 'initial', fontSize: mobile ? '16px' : '16px', lineHeight: mobile ? '20px' : '40px', fontWeight: mobile ? '700' : '500', backgroundColor: "#3A81F3", width: "110px", height: '40px', marginTop: "20px", textTransform: 'none' }} onClick={() => { handleNext1() }}>Next</Button>
							  </Grid>
							  </Grid>
        </Grid>
        <Grid container md={12} xs={11} lg={3}>
        {mobile?'':<Grid item lg={9} md={12} xs={12}   sx={{textAlign: 'center'}}>
        <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'32px':'24px',lineHeight:mobile?'36px':'40px',fontWeight:mobile?'700':'600'}}>Up next</Typography>
        </Grid>}
       
        <Grid container md={12} xs={12} lg={12}>
        <Grid item lg={12} md={12} xs={6}   sx={{textAlign: 'center'}}>
          
        <Image Duration={0}
              src="https://drive.google.com/uc?export=view&id=14UUPc8-486E-DY8YuRciDZYz8FIhzpT7"
              style={{ transitionDuration:'0',animation:'0',width:mobile?'128px':"100%",height:mobile?'88px':"180px",borderRadius:"12px",marginTop:mobile?'12px':'26px'}}></Image> 
        </Grid>
        <Grid item lg={12} md={12} xs={6}   sx={{textAlign: 'center'}}>
        <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':'22px',fontWeight:mobile?'700':'600',marginTop:mobile?'12px':'8px'}}>Episode 4: Choose a theme</Typography>

        </Grid>
						  </Grid>
						  <Grid container md={12} xs={12} lg={12}>
        <Grid item lg={12} md={12} xs={6}   sx={{textAlign: 'center'}}>
          
        <Image Duration={0}
              src="https://drive.google.com/uc?export=view&id=1acPYix99V22OKT8oJTdYaPDTrG98PFua"
              style={{ transitionDuration:'0',animation:'0',width:mobile?'128px':"100%",height:mobile?'88px':"180px",borderRadius:"12px",marginTop:mobile?'12px':'12px'}}></Image> 
        </Grid>
        <Grid item lg={12} md={12} xs={6}   sx={{textAlign: 'center'}}>
        <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':'22px',fontWeight:mobile?'700':'600',marginTop:mobile?'12px':'8px'}}>Episode 5: Research is a Key</Typography>
        </Grid>
        </Grid>
        </Grid>
        
				  </Grid>
				  <Grid container lg={8}>
					  {mobile ? "" : <Typography sx={{ textAlign: 'initial', fontSize: mobile ? '12px' : '18px', lineHeight: mobile ? '16px' : '26px', fontWeight: mobile ? '700' : '500', marginTop: mobile ? '12px' : '8px' }}>	In Episode 3 of the writing course, we explore the question that plagues many writers - where to get ideas from? Kevin Missal, a well-known author and experienced writing teacher, shares his insights and strategies for generating ideas and finding inspiration.

						  Through this episode, you will learn how to tap into your personal experiences, observations, and emotions to come up with unique and compelling ideas for your writing. Kevin also discusses the importance of reading widely and paying attention to the world around you as a means of gathering ideas.

						  With practical exercises and examples, Kevin guides you through the process of brainstorming and refining your ideas, helping you to develop a solid foundation for your writing projects. Whether you are struggling with writer's block or simply looking for new ways to spark your creativity, this episode is sure to provide you with valuable insights and strategies.

						  By the end of this episode, you will have a better understanding of where to get ideas from and how to develop those ideas into successful writing projects. So, join us in this exciting episode of the writing course and take the first step towards unleashing your creative potential.
					  </Typography>}
				  </Grid>
      </Box>
			  </Box>
	  </>
  )
}
export default Episode3;