import React from 'react'
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import Dashboard_au from '../Dashboard_author/Dashboard_author';
import {useMediaQuery} from '@mui/material';
import {Grid} from '@mui/material';
import {TextField} from '@mui/material';
import {Button} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {Paper} from '@mui/material';
import Image from 'mui-image';
import { Link } from '@mui/material';
const ServicesE = [
	{ title: "Editing" ,value:'editing'},
	{ title: "Cover Designing"},
	{ title: "Translation" },
	{ title: "Book Store" },
	{ title: "Book Reviews" },{ title: "Book Trailer" },{ title: "Ghost Writing" },{ title: "Amazon Ads" }
  ];
function Learnbest () {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const mobile = useMediaQuery('(max-width:600px)');
    const ipad = useMediaQuery('(min-width: 768px) and (max-width: 1180px)');
    const handleChange11 = (event, value) => {
        setSelectedOptions(value);
        navigate(`../${value}`);
    }
    const navigate = useNavigate();
    const handleExplore =  (e) => {
		navigate('/Masterclass')
	};
    
    
  return (
    <>
    <Dashboard_au/>
    
      <Box sx={{ backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'100%',height:mobile?'912px':'1000px'}}>
			  {mobile ? '' :<Box sx={{width:"78%",left: mobile?'24px':'272px',position: 'absolute',marginTop:'24px'}}>
				  <Grid container md={12} lg={12} sx={{ marginTop: "20px" }}>
				<Grid container md={6} lg={6}>
					<Grid item md={9}lg={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>MasterClass</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} lg={6} sx={{justifyContent:'end'}}>
					<Grid item md={6} lg={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={3} lg={3} sx={{display:'flex',justifyContent:"center"}}>
					<Button variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
          </Button>
					</Grid>
				</Grid>
				  </Grid>
		  </Box>}
            
			<Box component='form' sx={{
				position: 'absolute',
width:mobile?'82%': '78%',

left: mobile?'24px':'32px',marginLeft:mobile?'24px':'240px',
top:mobile?'90px': '115px',

				borderRadius: '12px'
			}}>
        <Grid container lg={12}  xs={12}>
                <Grid container md={12} xs={12} lg={5}>
        <Grid item lg={12} md={12} xs={12} >
          
       <Typography variant='h2' sx={{textAlign:mobile?"center":'initial',fontSize:mobile?'32px':'64px',marginTop:mobile?'36px':'132px',color: '#3A81F3',lineHeight:mobile?'36px':'66px',fontWeight:mobile?'700':'700'}}>LEARN FROM THE BEST</Typography> 
        </Grid>
       
        <Grid item md={12} xs={12} lg={10.5} >
          
          
           {mobile?
           <Link to='learnbest' style={{textDecoration:'none',color:'black'}}>
           
									  <Typography variant='h2' sx={{ textAlign: 'initial', fontSize: mobile ? '20px' : '16px', marginTop: mobile ? '16px' : '26px', lineHeight: "24px" }}>
									  Have you thought about writing a fictional book only to get halfway through and hit a wall? Or if you have a plot idea for a book but don't know how to start? Have you finished a first proposal but believe it lacks the polish of a well-written novel? Don’t know how to find a publisher and sign profitable contracts? Or getting stuck while marketing your book on social media? Transform your ideas into a finished successful book step by step.
Author’s from around the world are here to help you write a successful book.Join our Master Class today!</Typography>
          
          </Link>
       :
        
        <Link to='../learnbest' style={{textDecoration:'none',color:'black'}}>
        
       
									  <Typography variant='h2' sx={{ textAlign: 'initial', fontSize: mobile ? '20px' : '16px', marginTop: mobile ? '35px' : '26px', lineHeight: "24px" }}>
									  Have you thought about writing a fictional book only to get halfway through and hit a wall? Or if you have a plot idea for a book but don't know how to start? Have you finished a first proposal but believe it lacks the polish of a well-written novel? Don’t know how to find a publisher and sign profitable contracts? Or getting stuck while marketing your book on social media? Transform your ideas into a finished successful book step by step.
Author’s from around the world are here to help you write a successful book.Join our Master Class today!  </Typography> 
       
       </Link>
           }
						  </Grid>
						  <Grid item md={12} xs={11} lg={12} sx={{justifyContent:mobile?"center":"initial",display:'flex'}} >				  
        <Button
              variant="contained"
              color="primary"
				sx={{marginTop:mobile?'16px':'26px',textTransform: 'none',fontWeight:'500',fontSize:mobile?'12px':'16px',borderRadius:'8px',
								  height: mobile ? '40px' : '48px',display:'flex',backgroundColor:'#3A81F3'
							  }}
							  onClick={handleExplore}
            >
              Explore now
							  </Button>
							  </Grid>
            </Grid>
            
            
                      <Grid container md={12} xs={12} lg={7}  >
			  <Grid item  xs={12} lg={12} md={10} sx={{marginTop:'146px',backgroundSize:"101% 100%",backgroundImage:"url(https://drive.google.com/uc?export=view&id=1Aj5dGHo53EbXsa0bGnG92G1EIaLJ8QWH)"}} >
			  {/* <Image Duration={0}
              src="https://drive.google.com/uc?export=view&id=1Aj5dGHo53EbXsa0bGnG92G1EIaLJ8QWH"
              style={{ transitionDuration:'0',animation:'0',width:mobile?'98%':"100%",height:mobile?'auto':"282px",borderRadius:"12px",marginTop:mobile?'26px':'100px',}}
            /> */}
			  </Grid>
		  </Grid>
		  </Grid>
            </Box>
          
                </Box>
    </>
  )

  }
export default Learnbest;