import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Card, CardMedia, CardContent, Typography, Button,Paper,Autocomplete } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dashboard_au from './Dashboard_author';
const ServicesE = [
	{ title: "Editing" ,value:'editing'},
	{ title: "Cover Designing"},
	{ title: "Translation" },
	{ title: "Book Store" },
	{ title: "Book Reviews" },{ title: "Book Trailer" },{ title: "Ghost Writing" },{ title: "Amazon Ads" }
  ];
function BookNewsFeed() {
  const [bookNews, setBookNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
	const mobile = useMediaQuery('(max-width:600px)');
	const phoneNumber = '+91-7060495034';
	const emailAddress = 'Abhijeet@Hubhawks.com';
	const [showDialog, setShowDialog] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const navigate = useNavigate();
	const handleChange11 = (event, value) => {
		setSelectedOptions(value);
		navigate(`../${value}`);
	}
	
	const handleClose = () => {
		setOpen(false);
		
		
	};
  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://gnews.io/api/v4/search?q=books&lang=en&max=100&apikey=4395035c9ad2265af9b9bec665c9f8c4`)
      .then((response) => {
        setBookNews([...bookNews, ...response.data.articles]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [page, bookNews]);

  useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        setPage(page + 1);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [page]);

  return (
	<>
		  <Dashboard_au />
		  <Box sx={{  backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'auto',height:mobile?'912px':'1400px'}}>
			  {mobile ? '' :<Box sx={{ width: "78%", left: mobile ? '24px' : '272px', position: 'absolute', marginTop: '24px' }}>
				
				  <Grid container md={12} sx={{ marginTop: "20px" }}>
				<Grid container md={6}>
					<Grid item md={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>News</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} sx={{justifyContent:'end'}}>
					<Grid item md={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={4} sx={{display:'flex',justifyContent:"center"}}>
					<Button variant="contained"  onClick={() => setShowDialog(true)} color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
						</Button>
						<Dialog open={showDialog} onClose={handleClose}>
        <DialogContent style={{backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)"}}>
							  <DialogContentText>
								  <Typography variant='h5'>Get in touch with us:</Typography>
	  <Button  href={`mailto:${emailAddress}`} style={{color:"black",textTransform:'none',fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><EmailOutlinedIcon />Abhijeet@Hubhawks.com</Button>
	  		
	  <Button href={`tel:${phoneNumber}`} style={{color:"black",fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><PhoneInTalkOutlinedIcon/>+91-7060495034</Button>
					
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)"}}>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
					</Grid>
				</Grid>
				  </Grid>
			  </Box>}
			  <Box component='form' width="1152px" height='950px' sx={{
				position: 'absolute',
width:mobile?'272px': '75%',
height: mobile?'650px':'1200px',
left: mobile?'24px':'32px',marginLeft:mobile?'24px':'240px',
top:mobile?'90px': '115px',
				borderRadius: '12px'
			}}>
    <Grid container spacing={3}>
      {bookNews.map((article, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Card style={{borderRadius: '16px',height:'550px'}}>
            <CardMedia
              component="img"
              height="200"
              image={article.image || 'https://via.placeholder.com/200x200?text=No+Image'}
              alt={article.title}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {article.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {article.description}
              </Typography>
            </CardContent>
            <Button variant="contained" color="primary" href={article.url}>
              Read More
            </Button>
          </Card>
        </Grid>
      ))}
      {loading && <p>Loading more articles...</p>}
				  </Grid>
				  </Box>
			  </Box>
		  </>
  );
}

export default BookNewsFeed;
