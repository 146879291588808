import React from 'react'
import Dashboard_menu from './Dashboard_menu'

const Dashboard_au = (profile) => {
	return (
	  
	< Dashboard_menu  />
  )
}

export default Dashboard_au;