import React from "react";
import "./styles1.css";
import { Grid ,Button, Typography,Box,Paper} from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const Accordion = ({ question, answer,answer2, accordionId, openAccordionId, setOpenAccordionId }) => {
	
	const isCollapsed = accordionId !== openAccordionId;
  const [isButtonClicked, setIsButtonClicked] = React.useState(false);

  const toggleAccordion = () => {
	setOpenAccordionId(isCollapsed ? accordionId : null);
	  setIsButtonClicked(!isButtonClicked);
	  console.log('kkk')
  };

  const toggleButton = () => {
    setIsButtonClicked(!isButtonClicked);
  };

  const buttonStyle = {
    backgroundColor: !isCollapsed ? "#3A81F3" : "#A1C0F0"
	};
	const buttonStyle1 = {
		color: !isCollapsed ? "#3A81F3" : "#A1C0F0"
	};
	
	const buttonStyle2 = {
		backgroundColor: !isCollapsed ? "#FAFBFF" : ""
	};
  const bulletClass = isCollapsed ? "" : "bullet";

  return (
	  <Grid container lg={12}  >
		
		  <Grid container lg={6} sx={{
			  width: '640px',display: 'flex',
			  flexDirection: 'column',
			  alignItems: 'flex-start',
			  padding: '24px 16px',
			  height: '72px',
			  background: '#FFFFFF',
			  boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.15)'
		  }}   style={buttonStyle2}  className={"accordion-header " + bulletClass} onClick={toggleAccordion}>
			  
			  <Grid item lg={1}>
			  <div className="button" style={buttonStyle} onClick={toggleButton} ></div>  
			  </Grid>
			  <Grid item lg={9} sx={{textAlign:'initial',width:'640px',height:'72px'}}>
			  <Typography className="question" sx={{fontSize:'18px',textAlign:'center',lineHeight:'28px',textAlign:'initial'}}>
				  {question}{isCollapsed ? <KeyboardArrowRightIcon style={buttonStyle1}  onClick={toggleButton} /> : <KeyboardArrowRightIcon style={buttonStyle1} onClick={toggleButton} />}</Typography> 
				  
			  </Grid>  
			  </Grid>
		  <Box container lg={6} className="Rajiv2" sx={{
				  position: 'absolute',
				  left: '48.11%',
				  right:' 2.63%',width:'600px',height:'494px',
				  top: '-20.8%',
			  bottom: '13.07%',
				  boxShadow: '0px 0px 4px 1px #FAFBFF',
				  borderRadius: '16px',height:'500px'
			  }} >
		  {!isCollapsed && (
			
        <Grid item lg={12} >
					  <Typography style={{
						  marginLeft: '0px', color: '#18191F',marginTop:'40px',
						  fontWeight: '700',
						  fontSize: '18px',
						  lineHeight: '28px'}}>{answer}</Typography>
				  <Typography style={{marginLeft:'56px',marginTop:'32px', color: '#000000',width: '399px',textAlign:'initial',
						  height: '322px',
						  fontWeight: '400',
						  fontSize: '16px',
						  lineHeight: '24px'}}>{answer2}</Typography>
				  </Grid>
				
			 )}
			  </Box>
	  </Grid>
	  
  );
};

export default Accordion;
