import React from 'react'
//import * as React from 'react';
import { useRef } from "react";
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useForm, Controller, FormProvider } from "react-hook-form";
import ImageList from "@mui/material/ImageList";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import ImageListItem from "@mui/material/ImageListItem";
import {  useNavigate } from 'react-router-dom';
import Autocomplete from "@mui/material/Autocomplete";
import { Paper } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ResponsiveAppBar from '../components/menu';
import Image from "mui-image";
import Button from '@mui/material/Button';
import DashboardFooter from './DashboardFooter';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link, Navigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from 'react';
import { borderColor } from '@mui/system';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import jwt_decode from "jwt-decode";
import { green, blue, grey, red,common,yellow } from "@mui/material/colors";
import Dashboard_au from './Dashboard_author';
import { Email_URL } from '../components/helper';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Imagestr from '../components/Imagestr';
import SliderComponent from './CoverDesignslider';
import CarouselComponent from './CoverDesignslider';
import CoverD from './CoverD';
// const pages = ['Home', 'Services', 'About', 'Contact', 'Blog', 'Signin'];
// const dashboard1=['Editing','CoverDesigning','Translation','GhostWriting','AmazonAds','BookReviews','BookTrailer','BookStore']
// const drawerWidth = 240;
const ServicesE = [
	{ title: "Editing" ,value:'editing'},
	{ title: "Cover Designing"},
	{ title: "Translation" },
	{ title: "Book Store" },
	{ title: "Book Reviews" },{ title: "Book Trailer" },{ title: "Ghost Writing" },{ title: "Amazon Ads" }
  ];


function CoverDesigning(props, status, r, profile,dash,dash1) {
	const { register, watch,control,handleSubmit,setValue,reset, formState: { errors } } = useForm();
	const { window } = props;
	const form = useRef();
	const navigate = useNavigate();
	const mobile = useMediaQuery('(max-width:600px)');
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [adventurous, setAdventurous] = useState(null);
	const [image1, setImage1] = useState(null);
	const [image2, setImage2] = useState(null);
	const [image3, setImage3] = useState(null);
	const [image4, setImage4] = useState(null);
	const [image5, setImage5] = useState(null);
	const [image6, setImage6] = useState(null);
	const [image7, setImage7] = useState(null);
	const [image8, setImage8] = useState(null);
	const [image9, setImage9] = useState(null);
	const [image10, setImage10] = useState(null);
	const [image11, setImage11] = useState(null);
	const [image12, setImage12] = useState(null);
	const [image13, setImage13] = useState(null);
	const [image14, setImage14] = useState(null);
	const [image15, setImage15] = useState(null);

	const [image16, setImage16] = useState(null);
	const [image17, setImage17] = useState(null);
	const [image18, setImage18] = useState(null);
	const [Autobiographical, setAutobiographical] = useState(null);
	const [Biography, setBiography] = useState(null);
	const [Candid, setCandid] = useState(null);
	const [Classic, setClassic] = useState(null);
	const [Coming, setComing] = useState(null);
	const [Dark, setDark] = useState(null);
	const [Deep, setDeep] = useState(null);
	const [Diverse, setDiverse] = useState(null);
	const [Educational, setEducational] = useState(null);
	const [Empowering, setEmpowering] = useState(null);
	const [Fictional, setFictional] = useState(null);
	const [Futuristic, setFuturistic] = useState(null);
	const [Funny, setFunny] = useState(null);
	const [Emotional, setEmotional] = useState(null);
	
	const [Historical, setHistorical] = useState(null);
	const [Inspiring, setInspiring] = useState(null);
	const [modern, setModern] = useState(null);
	const [nonFiction, setFiction] = useState(null);
	const [spiritual, setSpiritual] = useState(null);
	const [thriller, setThriller] = useState(null);
	const [witty, setWitty] = useState(null);
	const [mythological, setMythological] = useState(null);
	const [showDialog, setShowDialog] = useState(false);
	const [selectedOption, setSelectedOption] = useState("");
	const [textInput, setTextInput] = useState("");
	const [value1, setValue1] = React.useState(1);
	const [user, setUser] = useState({
		to: "rajeevkhanduja@gmail.com",
		subject: "",
		description: "",
		
	});
	const [open, setOpen] = React.useState(false);

	const black = grey;
	const getUserFromToken = () => {
		const token = localStorage.getItem("token");
		console.log(token, 'token');
		if (!token)
			return null;
		try {
			const decoded = jwt_decode(token);
			console.log(decoded, 'toksen');
		  return decoded;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	};
	const getUserFromToken1 = () => {
		const gogle = localStorage.getItem("user");
		console.log(gogle, 'token');
		if (!gogle)
			return null;
		try {
			const decoded = jwt_decode(gogle);
			console.log(decoded, 'toksen');
		  return decoded;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	};
	const [selectedOptions, setSelectedOptions] = useState([]);
	const handleChange11 = (event, value) => {
		setSelectedOptions(value);
		navigate(`../${value}`);
	}
	const handleClose = () => {
		setOpen(false);
		reset();
		setAdventurous(null);
		setAutobiographical(null);
		setBiography(null);
		setCandid(null);
		setComing(null);
		setDark(null);
		setDeep(null);
		setDiverse(null);
		setEducational(null);
		setEmotional(null);
		setEmpowering(null);
		setFiction(null);
		setFictional(null);
		setFunny(null);
		setFuturistic(null);
		setHistorical(null);
		setInspiring(null);
		setMythological(null);
		setSpiritual(null);
		setClassic(null);
		setThriller(null);
		setWitty(null);
		setModern(null);
		setValue(null);
		setImage1(null);
		setImage2(null);
		setImage3(null); setImage10(null);setImage11(null);setImage12(null);setImage13(null);setImage14(null);setImage15(null);setImage16(null);setImage17(null);setImage18(null);
		setImage4(null); setImage5(null); setImage6(null);setImage7(null);setImage8(null);setImage9(null);
	};
// 	const [color, setColor] = useState("");
	const [write, setWrite] = useState("");
	const [showGrid, setShowGrid] = useState(false);
	const [showGriddesk, setshowGriddesk] = useState(false);
	const [msg,setMsg] = useState('');
//   const handleColor = () => {
//     setColor("#3A81F3");
//     setTextColor("white");
//   };

const handleTextInputChange = (event) => {
    setTextInput(event.target.value);
	};
	const handlewrite = (event) => {
		setWrite(event.target.value);
	}

	const onSubmit = async (data, e) => {
		setOpen(true);
		const formData = new FormData();
		console.log(data);
		e.preventDefault();
		const user1 = getUserFromToken1();
		const user = getUserFromToken();
		if (user) {
			
			if (!user) return;
			const response = await axios.post(`${Email_URL}/api/email/coverdesign`, {
				...data, user,
			}).then((res) => res.json()).then(async (res) => {
				const resData = await res;
				console.log(resData);
				if (resData.status === "success") {
					alert("Message Sent");
				} else if (resData.status === "fail") {
					alert("Message failed to send");
				}
			})
			console.log(JSON.stringify(data), user, 'rajivk');
		}
		else {
			
			if (user1) {
				const user1 = getUserFromToken1();
			if (!user1) return;
			const response = await axios.post(`${Email_URL}/api/email/coverdesignG`, {
				...data, user1
			
			}).then((res) => res.json()).then(async (res) => {
				const resData = await res;
				console.log(resData);
				if (resData.status === "success") {
					alert("Message Sent");
				} else if (resData.status === "fail") {
					alert("Message failed to send");
				}
			})
			console.log(JSON.stringify(data), user1, 'rajivk');
			}
		}
		console.log(JSON.stringify(data));
		console.log(FormData, 'hi');
	  };
	  function colorForStatus(status) {
		switch (status) {
		  case "Adventurous":
		  case "Autobiographical":
			case "Biography":
			case "Candid":
			case "Classic":
			case "Coming-of-age":
			case "Dark":
			case "Deep":
			case "Diverse":
			case "Educational":
			case "Empowering":
			case "Fictional":
			case "Futuristic":
			case "Funny":
			case "Emotional":
			case "Emotional":
				case "Inspiring":
					case "Historical":
						case"modern":
					case "nonFiction":
					case "spiritual":
					case "witty":
					case "mythological":
						case "thriller":
				
				
				return common;
			case "rj":
				return yellow;
		  
		  default:
			return grey;
		}
	}
	function colorForStatus(dash) {
		switch (dash) {
		  case "Adventurous":
		  case "Autobiographical":
			case "Biography":
			case "Candid":
			case "Classic":
			case "Coming-of-age":
			case "Dark":
			case "Deep":
			case "Diverse":
			case "Educational":
			case "Empowering":
			case "Fictional":
			case "Futuristic":
			case "Funny":
			case "Emotional":
			case "Inspiring":
			case "Historical":
				case"modern":
			case "nonFiction":
			case "spiritual":
			case "witty":
			case "mythological":
				case "thriller":
				
				return common;
			case "rj":
				return yellow;
		  
		  default:
			return black;
		}
	}
	

	function colorForStatus(data1) {
		switch (data1) {
			case "image1":
				
		  case "Autobiographical":
			case "Biography":
			case "Candid":
			case "Classic":
			case "Coming-of-age":
			case "Dark":
			case "Deep":
			case "Diverse":
			case "Educational":
			case "Empowering":
			case "Fictional":
			case "Futuristic":
			case "Funny":
			case "Emotional":
				case "Emotional":
			case "Inspiring":
			case "Historical":
				case"modern":
			case "nonFiction":
			case "spiritual":
			case "witty":
			case "mythological":
				case "thriller":
				
				
				return {
					border: '5px solid #3A81F3', // replace with desired border style and color
					// assuming this is the background color you want
				  };
			
		  
		  default:
			return grey;
		}
	}
	const [selectedImage, setSelectedImage] = useState(null);

//   const handleImageClick = (image) => {
//     setSelectedImage(image);
//   };
	const handleLogout = () => {
		localStorage.removeItem("token");
		
		
	};
	const onValueChange=(event)=> {
		setSelectedOption(event.target.value);
		console.log(event.target.value);	
	  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
	};
	const handleClick = () => {
		console.info('You clicked the Chip.');
	  };

	  const handleChange = (event, newValue) => {
		setValue1(newValue);
	};
	const phoneNumber = '+91-7060495034';
	const emailAddress = 'Abhijeet@Hubhawks.com';
	const handleOpen = () => {
		setShowDialog(true);
	 }
	const handleDialog = () => {
		setShowDialog(false);
	 }

  const container = window !== undefined ? () => window().document.body : undefined;

	return (<>
		<Dashboard_au />
		<Box sx={{  backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'auto',height:mobile?'1600px':(showGrid)?"2100px":'2400px'}}>
		{mobile ? '' :
				<Box sx={{ width: "78%", left: mobile ? '24px' : '272px', position: 'absolute', marginTop: '24px' }}>
					<Grid container md={12} lg={12} sx={{ marginTop: "20px" }}>
				<Grid container md={6} lg={6}>
					<Grid item md={9} lg={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>Marketplace</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} lg={6} sx={{justifyContent:'end'}}>
					<Grid item md={6} lg={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={4} lg={4} sx={{display:'flex',justifyContent:"center"}}>
					<Button onClick={handleOpen} variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
								</Button>
								<Dialog open={showDialog} onClose={handleClose} sx={{width:'500px',height:'280px',justifyContent:'center',margin:'auto',borderRadius:'0px 0px 12px 12px'}}>
						<DialogTitle sx={{ background: '#3A81F3', color: '#FFFFFF', textAlign: 'center' }}>
						<Grid container lg={12} xs={12}>
							<Grid item lg={10} xs={10}>
							Contact Us
							</Grid>
							<Grid item lg={2} xs={2}>
							<Button onClick={handleDialog}><CloseIcon sx={{color:'#FFFFFF'}} /></Button>
			 
								</Grid>
								</Grid></DialogTitle>
        <DialogContent >
							  <DialogContentText>
								<Grid container lg={12}>
									<Grid container lg={10} sx={{margin:'auto'}}>
									
									<Grid item lg={10} sx={{margin:'auto'}}>
	  <Button  href={`mailto:${emailAddress}`} style={{color:"black",textTransform:'none',fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><EmailOutlinedIcon />Abhijeet@Hubhawks.com</Button>
	  </Grid>
	  		<Grid item lg={8} sx={{margin:'auto'}}>
	  <Button href={`tel:${phoneNumber}`} style={{color:"black",fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><PhoneInTalkOutlinedIcon/>+91-7060495034</Button>
	  </Grid>
									</Grid>
								</Grid>
								  
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
					</Grid>
				</Grid>
					</Grid>
			</Box>}
			
			<Paper component='form' width="1152px" height='1148px' sx={{
				position: 'absolute',
width: mobile?'272px': '75%',
height: mobile?(showGrid)?"1500px":'1400px':(showGrid)?"2000px":'2100px',
left: mobile?'24px':'32px',marginLeft:mobile?'24px':'240px',
top: mobile?'90px': '115px',
background: '#FFFFFF',
borderRadius: '12px'}}>
				{mobile ?
					<Tabs
       value={value1}
		variant="scrollable"
		onChange={handleChange}
	   scrollButtons
	   allowScrollButtonsMobile
	   aria-label="scrollable force tabs example"
	 >
	  	<Tab label="Editing" value1='0' style={{textTransform:'none'}} href="/Editing"  />
        <Tab label="CoverDesigning" value1='1' style={{textTransform:'none'}}href="/CoverDesigning" />
        <Tab label="Translation" value1='2' style={{textTransform:'none'}}href="/Translation"/>
        <Tab label="Ghostwriting"value1='3'style={{textTransform:'none'}} href= "/GhostWriting"/>
        <Tab label="Amazon Ads"value1='4' style={{textTransform:'none'}}href=  "/AmazonAds"/>
        <Tab label="Book Reviews"value1='5' style={{textTransform:'none'}} href= "/BookReviews"/>
		<Tab label="Book Trailer"value1='6' style={{textTransform:'none'}}href="/BookTrailer" />
		<Tab label="Bookstore"value1='7' style={{textTransform:'none'}}href= "/Bookstore" />
		 </Tabs>
				   : 
		  <Tabs style={{marginTop:"26px",marginLeft:'38px'}}
						value={value1}
						onChange={handleChange}
			variant="scrollable"
			scrollButtons
			allowScrollButtonsMobile
			aria-label="scrollable force tabs example"
		  >
						<Tab label="Editing" value1='0' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link} to="/Editing"  />
						<Tab label="CoverDesigning" value1='1' style={{ textTransform: 'none', color: '#000000', fontSize: '16px', fontWeight: 600 }} component={Link} to="/CoverDesigning" />
							<Tab label="Translation" value1='2' style={{ textTransform: 'none', color: '#000000', fontSize: '16px', fontWeight: 400 }} component={Link } to="/Translation"/>
        <Tab label="Ghostwriting"value1='3'style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to= "/GhostWriting"/>
        <Tab label="Amazon Ads"value1='4' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to=  "/AmazonAds"/>
        <Tab label="Book Reviews"value1='5' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to= "/BookReviews"/>
		<Tab label="Book Trailer"value1='6' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link }to="/BookTrailer" />
		<Tab label="Bookstore"value1='7' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to= "/Bookstore" />
			</Tabs>
			  
			 }
    <Box component='form' encType="multipart/form-data"
      method="post"  onSubmit={handleSubmit}  >
		
				 
		<Grid container md={12} xs={12}>
					<Grid item md={12} xs={12} sx={{textAlign:'initial'}}>
					  <Typography sx={{ml:mobile?"16px":'84px',marginTop:mobile?"12px":'56px',fontSize:mobile?'12px': '18px'}} >What is the genre of your book?</Typography>
				</Grid>
				<Grid item md={6} xs={10} sx={{ marginLeft: mobile ? "16px" : "84px", marginTop: mobile ? '12px' : "32px", '& .MuiTextField-root': {  width: mobile ? "222px":'582px',height:'40px' },}}>
						<TextField placeholder='Fiction, Mythological, Thriller, etc'
							id="Genreofbook"
							name="Genreofbook"
							    
							margin="dense"
							{...register('Genreofbook')}
							error={errors.Comments ? true : false}
							inputProps={{ style: { padding:mobile?'8px 8px':'14.5px 16px' } }}
							 />
					  </Grid>
						<Grid item md={12} xs={12} sx={{ justifyContent: 'initial', marginTop: '16px' }}>
							
				{/* <Grid item lg={10.5} xs={11} sx={{margin:'auto',textAlign:'left'}}>
						<Button label="Adventurous" variant='outlined'
							 style={{
								backgroundColor: colorForStatus((status = adventurous))[100],
								color:colorForStatus((dash = adventurous))[900], width: mobile?'73px':'134px',
								height:mobile?'24px': '40px',borderRadius:'20px',marginLeft:mobile?"0px":'16px',fontSize:mobile?'10px':"18px",textTransform:'none'
								,minWidth:"40px"  }} 
								  value={user.description}
							  onClick={(e) => {
								  if (adventurous === null) {
      setAdventurous("Adventurous");
      setValue("words1", "Adventurous");
    } else {
      setAdventurous(null);
      setValue("words1", null);
    }
								  
							  }} >Adventurous </Button>
						  <Button variant='outlined' 
							  style={{
								backgroundColor: colorForStatus((status = Autobiographical))[100],
								
								color:colorForStatus((dash = Autobiographical))[900], width:  mobile?'92px':'168px',
								height: mobile?'24px': '40px',borderRadius:'20px',marginLeft:mobile?"6px":'16px',fontSize:mobile?'10px':'18px',textTransform:'none'
								,minWidth:"40px" }} 
							  label="Autobiographical"
							onClick={
								(e) => {
									if (Autobiographical === null) {
									  setAutobiographical("Autobiographical");
									  setValue("words2", "Autobiographical");
									} else {
									  setAutobiographical(null);
									  setValue("words2", null);
									}
								  }
							} >
							Autobiographical
							 </Button>
						
						  <Button label="Biography" variant='outlined' 
							  style={{
								backgroundColor: colorForStatus((status = Biography))[100],
								
								color:colorForStatus((dash = Biography))[900], width:  mobile?'61px':'113px',
								height: mobile?'24px': '40px',borderRadius:'20px',marginLeft:mobile?"6px":'16px',fontSize:mobile?'10px':'18px',textTransform:'none'
								,minWidth:"40px" }}
							onClick={(e) => {
								if (Biography === null) {
									setBiography("Biography");
									setValue("words3", "Biography");
								  } else {
									setBiography(null);
									setValue("words2", null);
								  }
								  }} >Biography</Button>
						  <Button label="Candid" variant='outlined' 
							  style={{
								backgroundColor: colorForStatus((status = Candid))[100],
								
								color:colorForStatus((dash = Candid))[900], width:  mobile?'48px':'89px',
								height:  mobile?'24px':'40px',borderRadius:'20px',marginLeft:mobile?"0px":'16px',fontSize:mobile?'10px':'18px',textTransform:'none',marginTop:mobile?"6px":"0px"
								,minWidth:"40px"
							}} onClick={(e) => {
								if (Candid === null) {
									setCandid("Candid");
							  setValue("word4","Candid")  
								}
								else {
									setCandid(null);
							  setValue("word4",null)
								}
								  }} >Candid</Button>
						  
						  <Button  variant='outlined' label="Classic"
							  style={{
								backgroundColor: colorForStatus((status = Classic))[100],
								
								color:colorForStatus((dash = Classic))[900], width:mobile?'40px': '91px',
								height: mobile?'24px':'40px',borderRadius:'20px',marginLeft:mobile?"3px":'16px',fontSize:mobile?'10px':'18px',textTransform:'none',marginTop:mobile?"6px":"0px"
								,minWidth:"40px"
							}} onClick={(e) => {
								if (Classic === null) {
									setClassic("Classic");
									setValue("word5","Classic")
								}
								else {
									setClassic(null);
								  setValue("word5",null)
								}
								
							  }} >Classic</Button>
						  <Button variant='outlined' 
							  style={{
								backgroundColor: colorForStatus((status = Coming))[100],
								
								color:colorForStatus((dash = Coming))[900], width: mobile?'102px':'162px',
								  height:mobile?'24px': '40px', borderRadius: '20px', marginLeft:mobile?"3px": '16px', fontSize:mobile?'10px': '18px', textTransform: 'none',marginTop:mobile?"6px":"0px",
								  minWidth:"40px"
							  }}
							onClick={(e) => {
								if (Coming == null) {
									setComing("Coming-of-age");
									setValue("word6", "Coming-of-age")
								}
								else {
									setComing(null);
								setValue("word6", null)
								}
							}} >Coming-of-age</Button>
						
						{mobile ? '' :<>
						<Button label="Dark" variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Dark))[100],
						 
						  color:colorForStatus((dash = Dark))[900], width: '69px',
						  height: mobile?'24px':'40px',borderRadius:'20px',marginLeft:mobile?"6px":'16px',fontSize:mobile?'10px':'18px',textTransform:'none'
						  ,minWidth:"40px"}}
								onClick={(e) => {
									if (Dark === null) {
										setDark("Dark");
										setValue("word7","Dark")
									}
									else {
										setDark(null);
						setValue("word7",null)
									}
							}} >Dark</Button>
					<Button label="Deep"  variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Deep))[100],
						  
						  color:colorForStatus((dash = Deep))[900], width: '74px',
						  height: mobile?'24px':'40px',borderRadius:'20px',marginLeft:'16px',fontSize:mobile?'10px':'18px',textTransform:'none'
						  ,minWidth:"40px"}}
								onClick={(e) => {
									if (Deep === null) {
										setDeep("Deep");
										setValue("word8", "Deep")
									}
									else {
										setDeep(null);
					 					setValue("word8", null)
									}
							
				  }} >Deep</Button>
				  
				 
					<Button label="Diverse" variant='outlined'
					   style={{
						  backgroundColor: colorForStatus((status = Diverse))[100],
						 
						  color:colorForStatus((dash = Diverse))[900], width: '92px',
						   height:mobile?'24px': '40px', borderRadius: '20px', marginLeft:  '16px',fontSize:mobile?'10px':'18px',textTransform:'none',marginTop:'10px',marginBottom:"10px"
										, minWidth: "40px"
									}} onClick={(e) => {
										if (Diverse === null) {
											setDiverse("Diverse");
											setValue("word9","Diverse")
										} else {
											setDiverse(null);
											setValue("word9",null)	
							   }
							}} >Diverse</Button>
					<Button label="Educational" variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Educational))[100],
						 
						  color:colorForStatus((dash = Educational))[900], width: '127px',
						  height:mobile?'24px': '40px',borderRadius:'20px',marginLeft:'16px',fontSize:mobile?'10px':'18px',textTransform:'none'
										, minWidth: "40px"
									}} onClick={(e) => {
										if (Educational === null) {
											setEducational("Educational");
											setValue("word10","Educational")
										}
										else {
											setEducational(null);
							setValue("word10",null)
							  }
							}}>Educational</Button>
					<Button label="Empowering"  variant='outlined'
					  style={{
						  backgroundColor: colorForStatus((status = Empowering))[100],
						
						  color:colorForStatus((dash = Empowering))[900],width: '133px',
						  height:mobile?'24px': '40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						  
						}} onClick={(e) => {
							if (Empowering === null) {
								setEmpowering("Empowering");
							setValue("words11", "Empowering");
							}
							else {
								setEmpowering(null);
							setValue("words11", null);
							}
						}} >Empowering</Button>
				  <Button label="Emotional" variant='outlined'
					  style={{
						  backgroundColor: colorForStatus((status = Emotional))[100],
						  
						  color:colorForStatus((dash = Emotional))[900],width: '113px',
						  height:mobile?'24px': '40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						}} onClick={(e) => {
							if (Emotional === null) {
								setEmotional("Emotional");
							setValue("word12", "Emotional");
							}
							else {
								setEmotional(null);
								setValue("word12", null);
							}
						}} >Emotional</Button>
					<Button label="Fictional" variant='outlined'
					   style={{
						  backgroundColor: colorForStatus((status = Fictional))[100],
						  
						  color:colorForStatus((dash = Fictional))[900],width: '101px',
						  height:mobile?'24px': '40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						}} onClick={(e) => {
							if (Fictional === null) {
								setFictional("Fictional");
							setValue("words13", "Fictional");
							}
							else {
								setFictional(null);
								setValue("words13", null);
							}
									}} >Fictional</Button>
					<Button label="Funny"  variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Funny))[100],
					
						  color:colorForStatus((dash = Funny))[900],width: '81px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'12px':"18px",textTransform:'none',marginLeft:'16px'
						}} onClick={(e) => {
							if (Funny === null) {
								setFunny("Funny");
						setValue("words14","Funny")
										}
							else {
								setFunny(null);
						        setValue("words14",null)
									}}}>Funny </Button>
					<Button label="Futuristic" variant='outlined'
						style={{backgroundColor: colorForStatus((status = Futuristic))[100],
						  
						  color:colorForStatus((dash = Futuristic))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (Futuristic === null) {
								setFuturistic("Futuristic");
							  setValue("words15", "Futuristics")
							}
							else {
								setFuturistic(null);
							  setValue("words15", null)
							}
									}}>Futuristic</Button>
								{!showGriddesk && <Button style={{ width: '70px', height: "6px", fontSize: "16px", marginTop: '6px', textTransform: "none" }} onClick={() => setshowGriddesk(!showGriddesk)}>See all</Button>
								}	</>}
							{showGriddesk && <>
								<Button label="Historical" variant='outlined'
						style={{backgroundColor: colorForStatus((status = Historical))[100],
						  
						  color:colorForStatus((dash = Historical))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px',marginTop:'10px',marginBottom:'10px'
						   
						}} onClick={(e) => {
							if (Historical === null) {
								setHistorical("Historical");
							  setValue("words16", "Historical")
							}
							else {
								setHistorical(null);
							  setValue("words16", null)
							}
									}}>Historical</Button>
								<Button label="inspiring" variant='outlined'
						style={{backgroundColor: colorForStatus((status = Inspiring))[100],
						  
						  color:colorForStatus((dash = Inspiring))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (Inspiring === null) {
								setInspiring("Inspiring");
							  setValue("words17", "Inspiring")
							}
							else {
								setInspiring(null);
							  setValue("words17", null)
							}
									}}>Inspiring</Button>
								<Button label="modern" variant='outlined'
						style={{backgroundColor: colorForStatus((status = modern))[100],
						  
						  color:colorForStatus((dash = modern))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (modern === null) {
								setModern("modern");
							  setValue("words18", "modern")
							}
							else {
								setModern(null);
							  setValue("words18", null)
							}
									}}>Modern</Button>
								<Button label="mythological" variant='outlined'
						style={{backgroundColor: colorForStatus((status = mythological))[100],
						  
						  color:colorForStatus((dash = mythological))[900],width: '137px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (mythological === null) {
								setMythological("mythological");
							  setValue("words19", "mythological")
							}
							else {
								mythological(null);
							  setValue("words19", null)
							}
									}}>Mythological</Button>
								<Button label="Non-fiction" variant='outlined'
						style={{backgroundColor: colorForStatus((status =nonFiction ))[100],
						  
						  color:colorForStatus((dash = nonFiction))[900],width: '150px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (nonFiction === null) {
								setFiction("nonFiction");
							  setValue("words20", "nonFiction")
							}
							else {
								setFiction(null);
							  setValue("words20", null)
							}
									}}>Non-fiction
								</Button>
								<Button label="Non-fiction" variant='outlined'
						style={{backgroundColor: colorForStatus((status =witty ))[100],
						  
						  color:colorForStatus((dash = witty))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (witty === null) {
								setWitty("witty");
							  setValue("words23", "Witty")
							}
							else {
								setWitty(null);
							  setValue("words23", null)
							}
									}}>Witty
								</Button>
								<Button label="Spiritual" variant='outlined'
						style={{backgroundColor: colorForStatus((status = spiritual ))[100],
						  
						  color:colorForStatus((dash = spiritual))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (spiritual === null) {
								setSpiritual("spiritual");
							  setValue("words21", "Spiritual")
							}
							else {
								setSpiritual(null);
							  setValue("words21", null)
							}
									}}>Spiritual
								</Button>
								<Button label="thriller" variant='outlined'
						style={{backgroundColor: colorForStatus((status = thriller))[100],
						  
						  color:colorForStatus((dash = thriller))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px',marginTop:"10px"
						   
						}} onClick={(e) => {
							if (thriller === null) {
								setThriller("thriller");
							  setValue("words22", "thriller")
							}
							else {
								setThriller(null);
							  setValue("words22", null)
							}
									}}>Thriller
								</Button>
							</>}
							
						{mobile &&
							<Button   style={{width:'30px',height:"6px",fontSize:"10px",marginTop:'6px',textTransform:"none",marginLeft:"-12px"}} onClick={() => setShowGrid(!showGrid)}>See all</Button>	
						}
						{(mobile && showGrid) &&<>
						
							<Grid item md={12}   sx={{ marginTop: mobile?"6px":'22px' }}>
							<Button label="Dark" variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Dark))[100],
							
							minWidth:"40px",
						  color:colorForStatus((dash = Dark))[900], width: '49px',
						  height: mobile?'24px':'40px',borderRadius:'20px',marginLeft:'6px',fontSize:mobile?'10px':'18px',textTransform:'none'
						}}
									onClick={(e) => {
										if (Dark === null) {
											setDark("Dark");
											setValue("word7", "Dark")
											
										}
										else {
											setDark(null);
						setValue("word7",null)
										}
							}} >Dark</Button>
					<Button label="Deep"  variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Deep))[100],
							
							minWidth:"40px",
						  color:colorForStatus((dash = Deep))[900], width: '54px',
						  height: mobile?'24px':'40px',borderRadius:'20px',marginLeft:'6px',fontSize:mobile?'10px':'18px',textTransform:'none'
						}}
									onClick={(e) => {
										if (Deep === null) {
											setDeep("Deep");
											setValue("word8", "Deep")
										}
										else {
											setDeep(null);
					                    setValue("word8", null)
										}
							
				  }} >Deep</Button>
					<Button label="Diverse" variant='outlined'
					   style={{
						  backgroundColor: colorForStatus((status = Diverse))[100],
						   
						   minWidth:"40px",
						  color:colorForStatus((dash = Diverse))[900], width: '52px',
						   height:mobile?'24px': '40px', borderRadius: '20px', marginLeft:'6px',fontSize:mobile?'10px':'18px',textTransform:'none'
									}} onClick={(e) => {
										if (Diverse === null) {
											setDiverse("Diverse");
											setValue("word9","Diverse")
										}
										else {
											setDiverse(null);
											setValue("word9", null)
										}
							}} >Diverse</Button>
					<Button label="Educational" variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Educational))[100],
							
							minWidth:"40px",
						  color:colorForStatus((dash = Educational))[900], width: '72px',
						  height:mobile?'24px': '40px',borderRadius:'20px',marginLeft:'6px',fontSize:mobile?'10px':'18px',textTransform:'none',amrginTop:"10px"
									}} onClick={(e) => {
										if (Educational === null) {
											setEducational("Educational");
											setValue("word10", "Educational")
										}
										else {
											setEducational(null);
										setValue("word10", null)
										}
							
									}}>Educational</Button>
								
					<Button label="Empowering"  variant='outlined'
					  style={{
						  backgroundColor: colorForStatus((status = Empowering))[100],
						
						  minWidth:"40px",
						  color:colorForStatus((dash = Empowering))[900],width: '72px',
						  height:mobile?'24px': '40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'6px',marginTop:"6px"
						  
									}} onClick={(e) => {
										if (Empowering === null) {
											setEmpowering("Empowering");
											setValue("words11", "Empowering");
										}
										else {
											setEmpowering(null);
							setValue("words11", null);
										}
							
						}} >Empowering</Button>
				  <Button label="Emotional" variant='outlined'
					  style={{
						  backgroundColor: colorForStatus((status = Emotional))[100],
						 
						  minWidth:"40px",
						  color:colorForStatus((dash = Emotional))[900],width: '73px',
						  height:mobile?'24px': '40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'6px',marginTop:"6px"
									}} onClick={(e) => {
										if (Emotional === null) {
											setEmotional("Emotional");
											setValue("word12", "Emotional");
										}
										else {
											setEmotional(null);
							setValue("word12", null);
										}
							
						}} >Emotional</Button>
					<Button label="Fictional" variant='outlined'
					   style={{
						  backgroundColor: colorForStatus((status = Fictional))[100],
						   
						   minWidth:"40px",
						  color:colorForStatus((dash = Fictional))[900],width: '71px',
						  height:mobile?'24px': '40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'6px',marginTop:"6px"
									}} onClick={(e) => {
										if (Fictional === null) {
											setFictional("Fictional");
											setValue("words13", "Fictional");
										}
										else {
											setFictional(null);
										setValue("words13", null);
										}
							          	
									}} >Fictional</Button>
					<Button label="Funny"  variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Funny))[100],
							
							minWidth:"40px",
						  color:colorForStatus((dash = Funny))[900],width: '51px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':"18px",textTransform:'none',marginLeft:'6px',marginTop:"6px"
									}} onClick={(e) => {
										if (Funny === null) {
											setFunny("Funny");
											setValue("words14","Funny")
										}
										else {
											setFunny(null);
						setValue("words14",null)
										}
							}}>Funny </Button>
					<Button label="Futuristic" variant='outlined'
						style={{backgroundColor: colorForStatus((status = Futuristic))[100],
						
							minWidth:"40px",
						  color:colorForStatus((dash = Futuristic))[900],width: '71px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'6px',marginTop:"6px"
						   
									}} onClick={(e) => {
										if (Futuristic === null) {
											setFuturistic("Futuristic");
											setValue("words15", "Futuristics")
										}
										else {
											setFuturistic(null);
											setValue("words15", null);
										}
							
										}}>Futuristic</Button>
									<Button label="Historical" variant='outlined'
						style={{backgroundColor: colorForStatus((status = Historical))[100],
						  
						  color:colorForStatus((dash = Historical))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px',marginTop:'10px',marginBottom:'10px'
						   
						}} onClick={(e) => {
							if (Historical === null) {
								setHistorical("Historical");
							  setValue("words16", "Historical")
							}
							else {
								setHistorical(null);
							  setValue("words16", null)
							}
									}}>Historical</Button>
								<Button label="inspiring" variant='outlined'
						style={{backgroundColor: colorForStatus((status = Inspiring))[100],
						  
						  color:colorForStatus((dash = Inspiring))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px'
						   
						}} onClick={(e) => {
							if (Inspiring === null) {
								setInspiring("Inspiring");
							  setValue("words17", "Inspiring")
							}
							else {
								setInspiring(null);
							  setValue("words17", null)
							}
									}}>Inspiring</Button>
								<Button label="modern" variant='outlined'
						style={{backgroundColor: colorForStatus((status = modern))[100],
						  
						  color:colorForStatus((dash = modern))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px'
						   
						}} onClick={(e) => {
							if (modern === null) {
								setModern("modern");
							  setValue("words18", "modern")
							}
							else {
								setModern(null);
							  setValue("words18", null)
							}
									}}>Modern</Button>
								<Button label="mythological" variant='outlined'
						style={{backgroundColor: colorForStatus((status = mythological))[100],
						  
						  color:colorForStatus((dash = mythological))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px',marginBottom:"8px"
						   
						}} onClick={(e) => {
							if (mythological === null) {
								setMythological("mythological");
							  setValue("words19", "mythological")
							}
							else {
								mythological(null);
							  setValue("words19", null)
							}
									}}>Mythological</Button>
								<Button label="Non-fiction" variant='outlined'
						style={{backgroundColor: colorForStatus((status =nonFiction ))[100],
						  
						  color:colorForStatus((dash = nonFiction))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px',marginBottom:"8px"
						   
						}} onClick={(e) => {
							if (nonFiction === null) {
								setFiction("nonFiction");
							  setValue("words20", "nonFiction")
							}
							else {
								setFiction(null);
							  setValue("words20", null)
							}
									}}>Non-fiction
								</Button>
								<Button label="Non-fiction" variant='outlined'
						style={{backgroundColor: colorForStatus((status =witty ))[100],
						  
						  color:colorForStatus((dash = witty))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px'
						   
						}} onClick={(e) => {
							if (witty === null) {
								setWitty("witty");
							  setValue("words23", "Witty")
							}
							else {
								setWitty(null);
							  setValue("words23", null)
							}
									}}>Witty
								</Button>
								<Button label="Spiritual" variant='outlined'
						style={{backgroundColor: colorForStatus((status = spiritual ))[100],
						  
						  color:colorForStatus((dash = spiritual))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px'
						   
						}} onClick={(e) => {
							if (spiritual === null) {
								setSpiritual("spiritual");
							  setValue("words21", "Spiritual")
							}
							else {
								setSpiritual(null);
							  setValue("words21", null)
							}
									}}>Spiritual
								</Button>
								<Button label="thriller" variant='outlined'
						style={{backgroundColor: colorForStatus((status = thriller))[100],
						  
						  color:colorForStatus((dash = thriller))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px',marginTop:"10px"
						   
						}} onClick={(e) => {
							if (thriller === null) {
								setThriller("thriller");
							  setValue("words22", "thriller")
							}
							else {
								setThriller(null);
							  setValue("words22", null)
							}
									}}>Thriller
								</Button>

								</Grid>
								
						  </>}
						  </Grid>		 */}
    </Grid>
				  </Grid>
				  {/* <Grid container xs={12} md={12}>
						<Grid item md={12} xs={12} sx={{textAlign:'initial'}}>
						<Typography sx={{marginTop:mobile?'16px':"62px",marginLeft:mobile?'16px':"84px",fontSize:mobile?'12px':"18px"}}>What visual representation suits your story?</Typography>
						</Grid>

						<Grid item md={5} xs={5} sx={{marginTop:"20px"}}>
							<Box sx={{ backgroundColor: "#F3F5F9", width:mobile?'232px': "100%", height: mobile?'216px': '388px',marginLeft:mobile?'20px':'80px' }}>
								<Grid container md={12} xs={12}>
								<Grid item md={12} xs={12}>
								<Typography sx={{ fontSize: mobile?"12px":'18px', fontWeight: '600', marginTop:mobile?"12px": '20px' }}>Stock</Typography>
								</Grid>
									<ImageList
        sx={{ marginTop:mobile?"12px": "20px",marginLeft:mobile?"16px":"46px" }}
        cols={4} gap={mobile?6:12}
        style={{ overflow: "hidden",marginLeft:mobile?"":"28px" }}
								>
									<ImageListItem>
			<Image Duration={0}
              src="https://drive.google.com/uc?export=view&id=1GlaycojE_etaB_ZHknV8hyHblCXjpEUU"
												style={{border: selectedImage === image1  ? '' : '5px solid #3A81F3',
													m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px'
												}}
			  onClick={(e) => { 
				if (image1 === null) {
					setImage1("image");
					setValue("image1", "selected");
				}
				else {
					setImage1(null);
					setValue("image", null);
				}
	}}					/>
											<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1GHdsN1tUjUalMXrQETIhC6RzAJ8p8-BY" style={{ border: selectedImage === image2 ? '' : '5px solid #3A81F3', m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px' }}
											  onClick={(e) => { 
												if (image2 === null) {
													setImage2("image");
													setValue("image2", "selected");
												}
												else {
													setImage2(null);
													setValue("image2", null);
												}
									}}/>
            
											<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1Mo4jYlisQ34mRfW6-hfEQ3st4Cm_kOpX" style={{
											border: selectedImage === image3 ? '' : '5px solid #3A81F3',	m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px'
											}}
											onClick={(e) => { 
												if (image3 === null) {
													setImage3("image");
													setValue("image3", "selected");
												}
												else {
													setImage3(null);
													setValue("image3", null);
												}
									}}	/>
            
									</ImageListItem>
									<ImageListItem>
            <Image  Duration={0}
              src="https://drive.google.com/uc?export=view&id=1DyvBDqmVVPatcw3ZOexXDox3LOpGMjTf"
              style={{border: selectedImage === image4  ? '' : '5px solid #3A81F3', m: "auto",transitionDuration:'0',animation:'0',width:mobile?'56px':'109px',height:mobile?'40px':'81px',borderRadius:'12px',marginBottom:mobile?'16px':'26px' }}
          	onClick={(e) => { 
				if (image4 === null) {
					setImage4("image");
					setValue("image4", "selected");
				}
				else {
					setImage4(null);
					setValue("image4", null);
				}
	}}  />
            
											<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1gUkz0QMj8xHH4REhOzSOz8p_IkXe8UAw" style={{ border: selectedImage === image5  ? '' : '5px solid #3A81F3',m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px' }}
												onClick={(e) => { 
													if (image5 === null) {
														setImage5("image");
														setValue("image5", "selected");
													}
													else {
														setImage5(null);
														setValue("image5", null);
													}
										}}/>
											<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1WR0KPYr9HDIK0_uayJVgDclKQdCFl1dQ" style={{border: selectedImage === image6  ? '' : '5px solid #3A81F3', m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px' }}
												onClick={(e) => { 
													if (image6 === null) {
														setImage6("image");
														setValue("image6", "selected");
													}
													else {
														setImage6(null);
														setValue("image6", null);
													}
										}}/>
            
									</ImageListItem>
									<ImageListItem>
            <Image  Duration={0}
              src="https://drive.google.com/uc?export=view&id=1CmJAAl96Kge4xQ-RCd4JKABvItfQTxAD"
              style={{ border: selectedImage === image7 ? '' : '5px solid #3A81F3',m: "auto",transitionDuration:'0',animation:'0',width:mobile?'56px':'109px',height:mobile?'40px':'81px',borderRadius:'12px',marginBottom:mobile?'16px':'26px' }}
          	onClick={(e) => { 
				if (image7 === null) {
					setImage7("image");
					setValue("image7", "selected");
				}
				else {
					setImage7(null);
					setValue("image7",  null);
				}
	}}  />
            
											<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1H4pVsEQh9LcRAhv51FPHCr6Jit11RRRs" style={{border: selectedImage === image8  ? '' : '5px solid #3A81F3', m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px' }}
												onClick={(e) => { 
													if (image8 === null) {
														setImage8("image");
														setValue("image8", "selected");
													}
													else {
														setImage8(null);
														setValue("image8", null);
													}
										}}/>
											<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1M9U_VoCc_AZqsB_aPKbuSn_eIbvC_UQy" style={{ border: selectedImage === image9  ? '' : '5px solid #3A81F3',m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px' }}
												onClick={(e) => { 
													if (image9 === null) {
														setImage9("image");
														setValue("image9", "selected");
													}
													else {
														setImage9(null);
														setValue("image9", null);
													}
										}}/>
            
									</ImageListItem>
	  </ImageList></Grid>
							</Box>
							
						</Grid>
						<Grid item md={1} sx={{textAlign:'center'}}>
							<Typography sx={{marginTop:mobile?"250px":"191px",fontWeight:mobile?"600":"400",fontSize:mobile?"12px":"18px",marginLeft:mobile?"12px":"75px",fontStyle:"normal"}}>OR</Typography>
						</Grid>
						<Grid item md={5} xs={6} sx={{marginTop:mobile?"12px":"20px"}}>
							<Box sx={{ backgroundColor: "#F3F5F9", width:mobile?'232px': "100%", height: mobile?'216px': '388px',marginLeft:'20px' }}>
								<Grid container md={12} xs={12}>
								<Grid item md={12} xs={12}>
								<Typography sx={{ fontSize: mobile?"12px":'18px', fontWeight: '600', marginTop:mobile?"12px": '20px' }}>Illustrations</Typography>
								</Grid>
									<ImageList
        sx={{ marginTop:mobile?"12px": "20px",marginLeft:mobile?"16px":"21px" }}
        cols={4} gap={mobile?6:12}
        style={{ overflow: "hidden" }}
								>
									<ImageListItem>
			<Image Duration={0}
              src="https://drive.google.com/uc?export=view&id=1k_QCBn-c5M8rh7eK1mKvEe8zkqgy1PD5"
              style={{  border: selectedImage === image10  ? '' : '5px solid #3A81F3',m: "auto",transitionDuration:'0',animation:'0',width:mobile?'56px':'109px',height:mobile?'40px':'81px',borderRadius:'12px',marginBottom:mobile?'16px':'26px'}}
			  onClick={(e) => { 
				if (image10 === null) {
					setImage10("image");
					setValue("image10", "selected1");
				}
				else {
					setImage10(null);
					setValue("image10", null);
				}
	}}					/>
											<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1693Xd7pw0UNLG5cmjJRNMo2WW30k67op" style={{border: selectedImage === image11  ? '' : '5px solid #3A81F3', m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px' }}
											 onClick={(e) => { 
												if (image11 === null) {
													setImage11("image");
													setValue("image11", "selected1");
												}
												else {
													setImage11(null);
													setValue("image11", null);
												}
									}}	
											/>
											<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1y4q1tCVityNBRg9Czd_r8twiTTTLxco6" style={{border: selectedImage === image12  ? '' : '5px solid #3A81F3', m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px' }}
											  onClick={(e) => { 
												if (image12 === null) {
													setImage12("image");
													setValue("image12", "selected1");
													
												}
												else {
													setImage12(null);
													setValue("image12", null);
												}
									}}	
											/>
            
										
									
            
									</ImageListItem>
									<ImageListItem>
            <Image  Duration={0}
              src="https://drive.google.com/uc?export=view&id=1sgxHVaB4wF2Y0Ngametpv8aF5-3jmU-0"
              style={{border: selectedImage === image13  ? '' : '5px solid #3A81F3', m: "auto",transitionDuration:'0',animation:'0',width:mobile?'56px':'109px',height:mobile?'40px':'81px',borderRadius:'12px',marginBottom:mobile?'16px':'26px' }}
			  onClick={(e) => { 
				if (image13 === null) {
					setImage13("image");
					setValue("image13", "selected1");
				}
				else {
					setImage13(null);
					setValue("image13", null);
				}
	}}	  />
            
											<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1P9pgROpV7Xj9yjEx_EUv87VAfpjY1ElW" style={{ border: selectedImage === image14  ? '' : '5px solid #3A81F3',m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px' }}
											 onClick={(e) => { 
												if (image14 === null) {
													setImage14("image");
													setValue("image14", "selected1");
												}
												else {
													setImage14(null);
													setValue("image14", null);
												}
									}}	/>
											<Image Duration={0} src="https://drive.google.com/uc?export=view&id=15I1NA76_-tUw1jLkyIrsnTQ6E4aJGaeg" style={{border: selectedImage === image15  ? '' : '5px solid #3A81F3', m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px' }}
											 onClick={(e) => { 
												if (image15 === null) {
													setImage15("image");
													setValue("image15", "selected1");
												}
												else {
													setImage15(null);
													setValue("image15", null);
												}
									}}	/>
            
									</ImageListItem>
									<ImageListItem>
           
												<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1UOOAcyOwKCFwbuV4ysY29tfP31vNoCOc" style={{border: selectedImage === image16  ? '' : '5px solid #3A81F3', m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px' }}
											onClick={(e) => { 
												if (image16 === null) {
													setImage16("image");
													setValue("image16", "selected1");
												}
												else {
													setImage16(null);
													setValue("image17", null);
												}
									}}	/>
            
											<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1u3UoCzADr_cSr_Q_1Cgg9ICymqdcsSOs" style={{border: selectedImage === image17  ? '' : '5px solid #3A81F3', m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px' }}
											 onClick={(e) => { 
												if (image17 === null) {
													setImage17("image");
													setValue("image17", "selected1");
												}
												else {
													setImage17(null);
													setValue("image17", null);
												}
									}}	/>
											<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1Jpeq4pmN39g8n2K_EUfUJf3oIoRhcIjn" style={{ border: selectedImage === image18  ? '' : '5px solid #3A81F3',m: "auto", transitionDuration: '0', animation: '0', width: mobile ? '56px' : '109px', height: mobile ? '40px' : '81px', borderRadius: '12px', marginBottom: mobile ? '16px' : '26px' }}
											 onClick={(e) => { 
												if (image18 === null) {
													setImage18("image");
													setValue("image18", "selected1");
												}
												else {
													setImage18(null);
													setValue("image18", null);
												}
									}}	/>
            
									</ImageListItem>
	  </ImageList></Grid>
							</Box>
							
						</Grid>
			</Grid> */}
					<Grid container md={12}>
				<Grid item md={12} sx={{textAlign:'initial'}}>
					<Typography sx={{marginTop:mobile?'16px':'56px',marginLeft:mobile?'16px':'84px',fontSize:mobile?'12px':"18px"}}>What visual representation suits your story? </Typography>
				</Grid>
				<Grid item md={6} sx={{textAlign:'initial',marginLeft:mobile?'16px':'84px',marginTop:mobile?'12px':'16px'}}>
				<label htmlFor="looking" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("timeline1")}
                        type="radio"
                        value="Scene-Setter"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Scene-Setter
					  </label><br/>
					  <label htmlFor="looking" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("timeline1")}
                        type="radio"
                        value="Portraiture"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Portraiture
					  </label><br/>
					  <label htmlFor="looking" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("timeline1")}
                        type="radio"
                        value="Silhouetted"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Silhouetted
							</label><br />
							<label htmlFor="looking" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("timeline1")}
                        type="radio"
                        value="Double Exposure"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Double Exposure
							</label><br />
							<label htmlFor="looking" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("timeline1")}
                        type="radio"
                        value="Object-Based"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Object-Based
							</label><br />
							<label htmlFor="looking" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("timeline1")}
                        type="radio"
                        value="Symbol-Based"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Symbol-Based
			</label><br/>

			<label htmlFor="looking" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("timeline1")}
                        type="radio"
                        value="Landscape"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                  Landscape
			</label><br/>
			<label htmlFor="looking" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("timeline1")}
                        type="radio"
                        value="Conceptual"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Conceptual
			</label><br/>
			<label htmlFor="looking" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("timeline1")}
                        type="radio"
                        value="Typographic"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Typographic
			</label><br/>
			<label htmlFor="looking" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("timeline1")}
                        type="radio"
                        value="Hybrid"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Hybrid
			</label><br/>

				</Grid>
					</Grid>
					<Grid container md={12}>
				<Grid item md={12} sx={{textAlign:'initial'}}>
					<Typography sx={{marginTop:mobile?'16px':'56px',marginLeft:mobile?'16px':'84px',fontSize:mobile?'12px':"18px"}}>What is the expected timeline?</Typography>
				</Grid>
				<Grid item md={6} sx={{textAlign:'initial',marginLeft:mobile?'16px':'84px',marginTop:mobile?'12px':'16px'}}>
				<label htmlFor="looking" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("timeline")}
                        type="radio"
                        value="Stock"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Stock
					  </label><br/>
					  <label htmlFor="looking" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("timeline")}
                        type="radio"
                        value="Illustration"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                Illustration
					  </label><br/>
					 
				</Grid>
			</Grid>
				  <Grid container xs={12}>
					  <Grid item md={12} sx={{textAlign:'initial'}}>
						  <Typography sx={{ marginTop:mobile?'16px':'56px',marginLeft:mobile?'16px':'84px',fontSize:mobile?'12px':'18px'}}>What is the “Title of the Story”?</Typography>
						 </Grid>
						  <Grid item md={6} sx={{marginLeft:mobile?'16px':'84px',marginTop:mobile?'8px':'16px'}}>
							<TextField
								placeholder='Write the title here'
							  id="Wordsbook"
							  name="Title"
								fullWidth
								
							  margin="dense"
							  {...register('title')}
							  error={errors.Comments ? true : false}
							  inputProps={{ style: { padding:mobile?'8px 8px':'14.5px 16px' } }}
							 />
					  </Grid>
				  </Grid>
				  <Grid container xs={12}>
					  <Grid item md={12} sx={{textAlign:'initial'}}>
						  <Typography sx={{marginLeft:mobile?'16px':"84px",marginTop:mobile?'16px':"56px",fontSize:mobile?'12px':"18px"}}>What is the summary of the Story?</Typography>
						</Grid>
						<Grid item md={6}   sx={{marginLeft:mobile?'16px':"84px",marginTop:"16px"}}>
						  <TextField 
							 required
							 id="Summary"
							 name="Summary"
							 placeholder='Write the summary here'
							 margin="dense"
								{...register('Summary')}
								rows={mobile?3:4}
								sx={{width: mobile?'242px':'679px'
									 ,height:mobile?'90px': '130px'}}
							multiline
							 error={errors.Summary ? true : false}
        />
				</Grid>
				</Grid>
				<Grid container xs={12}>
				<Grid item md={12} sx={{textAlign:'initial'}}>
						 
				<Typography sx={{marginTop:mobile?'16px':"62px",marginLeft:mobile?'16px':'84px',fontSize:mobile?'12px':'18px'}}>What is the expected timeline? </Typography>		  
		</Grid>
		<Grid item md={6} xs={12} sx={{textAlign:'initial',marginTop:mobile?'0px':'16px'}}>
		<label htmlFor="field-rain"style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'10px'}}>
                    <input
                        {...register("timeline")}
                        type="radio"
                        value="1 week"
                        id="field-rain"
						style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    1 week
                </label><br/>
				
                <label htmlFor="field-wind" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'10px'}}>
                    <input
                        {...register("timeline")}
                        type="radio"
                        value="2-3 week"
                        id="field-wind"
						style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    2-3 week
                </label><br/>
                <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'10px'}}>
                    <input
                        {...register("timeline")}
                        type="radio"
                        value="3-4 week"
                        id="field-sun"
						style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    3-4 week
					  </label><br/>
					  </Grid>	
					  
				 </Grid>
				 
				 <Grid container md={12}>
					 
					 <Grid item md={12}>
							   <Typography sx={{textAlign:'initial',marginLeft:mobile?'16px':'84px',marginTop:'16px',fontSize:mobile?'12px':"18px"}}>Additional comments (Optional)</Typography>
						 </Grid>
						 <Grid item md={6} sx={{textAlign:'initial',marginLeft:mobile?'16px':'84px',marginTop:mobile?'16px':'32px'}}>
						 <TextField 
								  required
								  id="Comments"
								  name="Comments"
								 placeholder='Write additional comments here'
							 fullWidth
								 size="small"
								 rows={mobile?3:4}
									 multiline
									 sx={{width: mobile?'242px':'679px'
									 ,height:mobile?'90px': '130px'}}
								  margin="dense"
								  {...register('Comments')}
								  error={errors.Comments ? true : false}
			 />
					 </Grid>
					 
					</Grid>	 
					<Grid container md={12}>
				<Grid item md={12} sx={{textAlign:'initial'}}>
					<Typography sx={{marginTop:mobile?'16px':'56px',marginLeft:mobile?'16px':'84px',fontSize:mobile?'12px':"18px"}}>Our Bookcover Designs</Typography>
				</Grid>
				<Grid item lg={12} >
			<CoverD />
				</Grid>
			</Grid>
					
						   <Grid container md={12}>
					  <Button
              variant="contained"
              color="primary"
				onClick={handleSubmit(onSubmit)}
				sx={{backgroundColor:'#3A81F3',marginTop:mobile?'12px':'56px',marginLeft:mobile?'16px':'82px',textTransform: 'none',fontWeight:'500',fontSize:mobile?'12px':'16px',borderRadius:'8px',width: mobile?'52px':'91px',
				height: mobile?'36px':'48px'}}
            >
              Submit
						</Button>
						<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Order Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <CheckCircleOutlineIcon /> Thank you for your order! We have
              received your order and will process it shortly.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
			</Grid>	
			
			</Box>
			</Paper>
			{/* {mobile?<DashboardFooter />:''}	 */}
			</Box>
		</>
  );
}
export default CoverDesigning;