import React from 'react';
import Paper from '@mui/material/Paper';
import  Grid  from '@mui/material/Grid';
import { Typography }  from '@mui/material'
import Image from 'mui-image';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import {Box} from '@mui/material';
import Card from '@mui/material/Card';
import ResponsiveAppBar from './menu';
import Footer from '../footer';
import { useDevice } from '../responsive/devices';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
// import LinearProgress from "@mui/material/LinearProgress";
function About()
{
	const Devices = useDevice();
	const mobile = useMediaQuery('(max-width:600px)');
	const ipad = useMediaQuery('(min-width: 768px) and (max-width: 1180px)');
	return (<>
		<ResponsiveAppBar />
		<Grid container md={12} lg={12} xs={12} >
			<Grid container md={8} lg={8} xs={12} >
				<Grid item md={10} lg={8} xs={8} sx={{margin:'auto'}}>
					<Typography variant='h1' sx={{ textAlign: 'initial', fontWeight: mobile ? 600 : 800, fontSize: mobile ? '32px' : '60px', lineHeight: mobile ? '37.5px' : '72px', fontStyle: 'normal', marginLeft: mobile ? '0px' : "0px", marginTop: mobile ? "35px" : '90px' }}>One-stop literary marketplace for
						<Typography variant='h1' sx={{ color: '#BB0000', fontWeight: mobile ? 600 : 800, fontSize: mobile ? '32px' : '60px', lineHeight: mobile ? '37.5px' : '72px', fontStyle: 'normal' }}>every book-related service</Typography></Typography>
				</Grid>
				<Grid item md={10} lg={8} xs={8}sx={{margin:'auto'}} >
					<Typography variant='h1' sx={{ fontSize: mobile ? '14px' : '18px', lineHeight: mobile ? '20px' : '28px', color: '#333335', textAlign: 'initial', marginLeft: mobile ? '0px' : "0px", marginTop: '26px', alignSelf: 'stretch' }}>Hubhawks is your go-to platform, that has all the book-related service providers and people seeking the same. The centralized solution to the literary problems a person can face. It is convenient, user-friendly, and a blessing in disguise for budding authors and the ones who are seeking to add more to their lists.</Typography>				
					</Grid>
				</Grid>
			<Grid container md={4} lg={4} xs={9} sx={{marginTop:mobile?"41px":'112px',marginLeft:"0px",margin:mobile?" 30px auto":"",display:'flex',justifyContent:'unset'}}>
			<CardMedia
					component="img"
					height="194"
					image="https://drive.google.com/uc?export=view&id=18bbHzcnVPOggF3pC9x7DJP5DCDKkLReF"
					alt="Paella dish" style={{
						justifyContent: 'space-around', width: mobile?'247px':ipad?"345px":'416px', height: mobile?'233px':ipad?"309px":'371px', position: 'absolute',
						marginLeft:mobile?'69px':ipad?"-9px":'-50px',marginTop:mobile?'-35px':ipad?"-49px":'-60px'
					}}
      />
      <CardContent style={{justifyContent:'center',display:'flex'}}>
	  <CardMedia
		component="img"
					image="https://drive.google.com/uc?export=view&id=1C2MwRR31WJdraHSncktA4XzJXVpExaPf"
					alt="Paella dish" style={{
						justifyContent: 'space-around', width:mobile?'272px':ipad?"358px":'448px', height:mobile?'185px': ipad?"246px":'296px',marginLeft:mobile?'-8px':ipad?"-74px":'-140px'
					}}
      />
      </CardContent>
			
			</Grid>
		</Grid>
		<Grid container md={12}lg={12} xs={10} sx={{margin:'auto'}}>
		<Grid item md={12} lg={12} xs={12} sx={{marginTop:mobile?'2px':'150px'}}>
		<Typography variant='h2' sx={{

fontWeight: '600',
fontSize: mobile?"24px":'36px',
lineHeight: '48px',
textAlign: 'center',color: '#000000'
				}}> Hubhawks’ Journey </Typography>	
			</Grid>
		</Grid>
		<Grid container md={8.2} lg={7} xs={8} sx={{margin:'auto'}}>
			<Grid item md={12} xs={12} lg={12} sx={{margin:'auto',marginTop:'16px'}} >
		<Typography variant='h2' sx={{fontWeight: '400',fontSize:mobile?'14px': '18px',
lineHeight: mobile?'20px':'28px',
textAlign: 'center',color: '#333335'
				}}> The idea of this platform began with the realization that in almost every step of writing and publishing
				a book an organized system doesn’t exist. Things that can be done in a few days are taking months and the only thing which lacks is the system and guidance. </Typography>	
				
			</Grid>
			</Grid>
		
		<Box sx={{margin:mobile?"0px":ipad?"0px":"81px",marginTop:mobile?"44px":'125px'}}> 
		<Box sx={{marginLeft:mobile?"0px": ipad?"0px":"100px"}}> 
		{/* icon with button */}
		
			<Grid container md={11.1} lg={11.1} xs={8} sx={{margin:'auto'}}>
			<TimelineSeparator>
          <TimelineDot sx={{ bgcolor: "#3A81F3" }} />
        </TimelineSeparator>
        <TimelineContent sx={{ color: "#3A81F3" }}>Step 2022</TimelineContent>
			  {/* <Grid item md={1}>
	<Image src="https://drive.google.com/uc?export=view&id=1F9ecUqb8Olwfpj5bcpLZFQNiNohbKbw1" style={{
justifyContent:'space-around',borderRadius:'20px',width:'10px',height:'10px'
			  }}>
			</Image>
		
			  </Grid>
			  <Grid item md={3}>
			  <Typography sx={{  fontWeight: '600', fontSize: '18px', color: '#3A81F3', fontStyle: 'normal' }}>Sept 2020</Typography>  
				</Grid> */}
			
			</Grid>
			<Box sx={{marginLeft:mobile?"0px": ipad?"0px":"50px"}}>
					<Grid container md={11.1} lg={12} xs={8} sx={{margin:'auto'}} >
					<TimelineSeparator sx={{marginTop:"-16px"}}>
							<TimelineConnector sx={{ bgcolor: "#3A81F3" }} />
							</TimelineSeparator>
			{/* <Divider orientation="vertical" flexItem sx={{ bgcolor: "#3A81F3"}}></Divider> */}
			<Grid container md={8} lg={8} xs={10}>
					<Grid item md={12}  lg={12}xs={12}>
				  <Typography sx={{fontWeight: 600,fontSize: '18px',textAlign:'initial',marginLeft:"20px",marginTop:mobile?"10px":"0px"}}>In times gone by</Typography>
					</Grid>
					<Grid item md={12} lg={12}xs={12}>
				  <Typography sx={{fontSize:mobile?'14px':'18px',color:"#333335",textAlign:'initial',marginLeft:"20px",marginTop:mobile?"10px":"0px",lineHeight:mobile?'20px':'28px'}}>Kevin Missal, the bestselling author thought of the hindrances being faced by authors and started Hubhawks with a team of 3 people.</Typography>
					</Grid>
					<Grid item md={12} lg={12} xs={12}>
				  <Typography variant='h4' sx={{fontSize:'18px',fontWeight:600,color:"#333335",textAlign:'initial',marginLeft:"20px",marginTop:mobile?"10px":"0px"}}>Initial Kickstarter point</Typography>
					</Grid>
					<Grid item md={12} lg={12} xs={12}>
				  <Typography  sx={{fontSize:mobile?'14px':'18px',color:"#333335",textAlign:'initial',marginLeft:"20px",marginTop:mobile?"10px":"0px",lineHeight:mobile?'20px':'28px'}}>Within a few months, we got our angel investment of 15 Lacs by Veteran of the publishing industry: Mr. Anuj Bahri - Founder of Bahrisons Booksellers.
                 </Typography>
							</Grid>
							
				
				</Grid>
						<Grid container md={3} lg={3}xs={10}>
							{mobile ? <TimelineSeparator>
								<TimelineConnector sx={{ bgcolor: "#3A81F3" }} />
							</TimelineSeparator> : ''}
							<Grid item md={6} sx={{ margin: 'auto' }}>
							
					<Image src="https://drive.google.com/uc?export=view&id=1X3vlqyrfXkSXYCeZ2ky8TUDBuQqTiBvn" style={{
									justifyContent: 'space-around', borderRadius: '20px', width: mobile ?'150px': '250px',marginTop:mobile?"30px":"0px",
height:mobile ?'150px': '250px'
			  }}></Image>
				  </Grid>
					</Grid>
			</Grid>
			</Box>
		</Box>
		<Box sx={{marginLeft:mobile?"0px":ipad?"0px":"100px"}}> 
		{/* icon with button */}
				<Grid container lg={6} md={11.1} xs={8} sx={{margin:'auto'}} >
			<TimelineSeparator>
							<TimelineConnector sx={{ bgcolor: "#3A81F3" }} />
							</TimelineSeparator>
					<Grid container md={4} xs={8} sx={{ ml: '32px' }}>
					<TimelineSeparator>
							<TimelineConnector sx={{ bgcolor: "#3A81F3" }} />
							</TimelineSeparator>
			  {/* <Grid item md={1}>
	<Image src="https://drive.google.com/uc?export=view&id=1F9ecUqb8Olwfpj5bcpLZFQNiNohbKbw1" style={{
justifyContent:'space-around',borderRadius:'20px',width:'10px',height:'10px'
			  }}>
				  </Image>
			  </Grid> */}
			  {/* <Grid item md={3}>
			  <Typography sx={{  fontWeight: '600', fontSize: '18px', color: '#3A81F3', fontStyle: 'normal' }}>2021-2022</Typography>  
				</Grid> */}
			</Grid> 
			</Grid>
				<Box sx={{ marginLeft: mobile ? "0px" :ipad?"0px": "50px" }}>
				<TimelineSeparator>
							<TimelineConnector sx={{ bgcolor: "#3A81F3" }} />
							</TimelineSeparator>
			<Grid container lg={12}  md={11.1} xs={8} sx={{margin:'auto'}}>
			<TimelineSeparator>
							<TimelineConnector sx={{ bgcolor: "#3A81F3" }} />
							</TimelineSeparator>
			<Grid container md={8} xs={10}>
			<TimelineSeparator>
          <TimelineDot sx={{ bgcolor: "#3A81F3",marginLeft:'-6px' }} />
        </TimelineSeparator>
	<TimelineContent sx={{ color: "#3A81F3" }}>2021-2022</TimelineContent>	
							
		<Grid item md={12} xs={12} >
		<TimelineSeparator>
							<TimelineConnector sx={{ bgcolor: "#3A81F3" }} />
							</TimelineSeparator>		
				  <Typography variant='h4' sx={{fontSize:'18px',fontWeight:600,color:"#333335",textAlign:'initial',marginLeft:mobile?"20px":"20px",marginTop:mobile?"10px":"0px"}}> Dream of removing the geographical barrier</Typography>
					</Grid>
					<Grid item md={12} xs={12} >
				  <Typography sx={{fontSize:mobile?'14px':'18px',color:"#333335",textAlign:'initial',marginLeft:mobile?"10px":"20px",marginTop:mobile?"20px":"0px",lineHeight:mobile?'20px':'28px'}}>In the year 2021, we got our first International client and over the months it kept increasing now we have a good number of international clients from UAE, Australia, USA, Canada, Pakistan, Kuwait, Indonesia, etc.</Typography>
				</Grid>
				<Grid item md={12} xs={12}>
				  <Typography variant='h4' sx={{fontSize:'18px',fontWeight:600,color:"#333335",textAlign:'initial',marginLeft:mobile?"20px":"20px",marginTop:mobile?"10px":"0px"}}>Spike in author count</Typography>
					</Grid>
					<Grid item md={12} xs={12} >
				  <Typography  sx={{fontSize:mobile?'14px':'18px',color:"#333335",textAlign:'initial',marginLeft:mobile?"10px":"20px",marginTop:mobile?"20px":"0px",lineHeight:mobile?'20px':'28px'}}>In just the past two years we have worked with over 100 authors, out of which more than 10 are bestselling authors.</Typography>
							</Grid>
							<Grid container ></Grid>
					
					
				</Grid>
						{mobile ?''
			:<Grid container md={3} lg={3}>
			<Grid item md={6} lg={6}sx={{margin:'auto',marginTop:mobile?'0px':'50px'}}>
			<Image src="https://drive.google.com/uc?export=view&id=1wkcDJdKLdBaKojvlbJCwu07zB1B0-DbJ" style={{
justifyContent:'space-around',borderRadius:'20px',width: '250px',margin:'auto',
height: '250px'
	  }}></Image>
			</Grid>
		</Grid>}	

					</Grid>
					<Grid container lg={12} md={11.1} xs={8} sx={{margin:'auto'}}  >
					<TimelineSeparator>
							<TimelineConnector sx={{ bgcolor: "#3A81F3" }} />
							</TimelineSeparator>
			<Grid container md={8} lg={8} xs={10}>
					
			
				<Grid item md={12} lg={12} xs={12}>
				  <Typography variant='h4' sx={{fontSize:'18px',fontWeight:600,color:"#333335",textAlign:'initial',marginLeft:"20px",marginTop:mobile?"20px":"0px"}}>Influential partnerships</Typography>
					</Grid>
					<Grid item md={12} lg={12} xs={12} >
				  <Typography  sx={{fontSize:mobile?'14px':'18px',color:"#333335",textAlign:'initial',marginLeft:"20px",marginTop:mobile?"20px":"0px",lineHeight:mobile?'20px':'28px'}}>Hubhawks has partnered with the biggest bookstores in India: Sapna, WH Smith, Crossword, Wilco, etc.
</Typography>
							</Grid>
							{mobile?<Grid container md={4}>
					<Grid item md={6} sx={{margin:'auto'}}>
					<Image src="https://drive.google.com/uc?export=view&id=1wkcDJdKLdBaKojvlbJCwu07zB1B0-DbJ" style={{
justifyContent:'space-around',borderRadius:'20px',width: mobile?'150px':'250px',
height:mobile?'150px': '250px'
			  }}></Image>
					</Grid>
				</Grid>:''}
							<Grid item md={12} lg={12} xs={12} >
				  <Typography variant='h4' sx={{fontSize:'18px',fontWeight:600,color:"#333335",textAlign:'initial',marginLeft:mobile?'20px':"20px",marginTop:mobile?"20px":"0px",marginRight:mobile?'0px':'0px'}}> Remarkable Work
</Typography>
							</Grid>
							<Grid item md={12} lg={12} xs={12} sx={{marginTop:'20px',marginBottom:"20px"}}>
				  <Typography sx={{fontSize:mobile?'14px':'18px',color:"#333335",textAlign:'initial',marginLeft:"20px",marginTop:mobile?"20px":"0px",lineHeight:mobile?'20px':'28px'}}>Hubhawks has had the opportunity to work with the biggest publishing houses like Penguin Random House India, Harper Collins India, Bloomsbury India, Simon and Schuster India, Audible India, leadstart, RedInk and etc.</Typography>
				</Grid>
				<Grid item md={12} xs={12} lg={12} sx={{margin:'auto'}}>
				  <Typography variant='h4' sx={{fontSize:'18px',fontWeight:600,color:"#333335",textAlign:'initial',marginLeft:"20px",marginTop:mobile?"10px":"0px"}}>Rocketing the literary space with technology
</Typography>
					</Grid>
							<Grid container ></Grid>
					
					
				</Grid>
						{mobile ?'' : <Grid container lg={3} md={3} sx={{ marginTop: '50px' }}>
							<Grid item lg={12} md={12} sx={{ marginTop: '20px', marginBottom: "20px" }}>
								<Image src="https://drive.google.com/uc?export=view&id=1HuQPY58Jm_i5-ql2D5K_-2bB2Bpmw87h" style={{
									justifyContent: 'space-around', borderRadius: '116px', width: '250px',marginLeft:'83px',
									height: '250px'
								}}></Image>
							</Grid>
						</Grid> }

					</Grid>


					<Grid container md={11.1} lg={12} xs={8} sx={{margin:"auto"}}>
					<TimelineSeparator>
							<TimelineConnector sx={{ bgcolor: "#3A81F3" }} />
					</TimelineSeparator>
			<Grid container md={11.1} lg={12} xs={10} sx={{marginLeft:'-2px'}}>
					<Grid item md={8} lg={8} xs={12} sx={{marginTop:'20px',marginBottom:"20px"}} >
				  <Typography  sx={{fontSize:mobile?'14px':'18px',color:"#333335",textAlign:'initial',marginLeft:"20px",marginTop:mobile?"10px":"0px",lineHeight:mobile?'20px':'28px'}}>One of a kind platform for budding authors and freelancers, wherein the authors can avail any literary services pre or post writing and the freelancers can easily get their desired projects of their niche.
								</Typography>
									
							</Grid>
								
							
					
				</Grid>
						{mobile ?
							<Grid container md={4} xs={12} sx={{ marginTop: mobile ? '0px' : '50px' }}>
								<TimelineSeparator>
							<TimelineConnector sx={{ bgcolor: "#3A81F3" }} />
							
							
							
					</TimelineSeparator>
			<Grid item md={6} sx={{ marginTop: mobile?'0px':'20px', marginBottom: "20px", margin: 'auto' }}>
				<Image src="https://drive.google.com/uc?export=view&id=1HuQPY58Jm_i5-ql2D5K_-2bB2Bpmw87h" style={{
					justifyContent: 'space-around', borderRadius: '116px', width: mobile?'150px':'250px',
					height: mobile?'150px':'250px'
				}}></Image>
			</Grid>
		</Grid>:''}	

					</Grid>










					
					
					

					

					

			</Box>




		</Box>
		</Box>
			
		
		<Grid container md={12} xs={12} lg={12}spacing={2}>
			<Grid item md={12} xs={12} lg={12} >
				<Typography sx={{
fontStyle: 'normal',
fontWeight: '600',textAlign: 'center',
fontSize: mobile?'24px':'36px',margin:'auto',marginLeft:'10px',
lineHeight: '48px'}}>Hubhawks Cultural Benefits</Typography>
</Grid>
			</Grid>
			<Grid container md={12} lg={12} xs={12} spacing={2} sx={{margin:'auto',marginBottom:'50px'}}>
			<Grid container xs={8} lg={10}md={10} spacing={2} sx={{marginTop:'50px',margin:'auto'}} >
				<Grid item md={4} lg={4}>
						<Card sx={{
							display: "flex",background: '#BB00000A',width:mobile?'215px':"100%",height:mobile?'63px':'100%',margin:'auto',borderRadius:'12px',marginRight:mobile?'50px':'19px'
}}>
       <CardMedia
          component="img"
          sx={{ width: 60,height:60,marginTop: mobile ?'13px':'17px',marginLeft:mobile ?'13px':"20px"}}
          image="https://drive.google.com/uc?export=view&id=1GM3Nab1kIVdArfdKhcgQwehQ9Epfi78k"
						alt="Live from space album cover"
						
        />
        <CardContent sx={{ flex: "1 0 auto" ,margin:"auto"}}>
     {mobile ?
							<>
								<Typography component="div" variant="h4" sx={{ textAlign: 'initial', fontSize: mobile ? '12px' : "18px", fontWeight: '600', color: '#333335' }}>
            Author-friendly and<br/>centric
						</Typography>
							
							</>:
						<Typography component="div" variant="h4"  sx={{textAlign:'initial',fontSize:mobile?'12px':"18px",fontWeight:'600',color:'#333335'}}>
								Author-friendly and centric
								</Typography>	}
         
          
        </CardContent>
     
						</Card>
						
				</Grid>
				<Grid item md={4} lg={4}>
				<Card sx={{ display: "flex",background: '#BB00000A',width:mobile?'215px':"100%",height:mobile?'63px':'100%',marginRight:'19px',borderRadius:'12px',marginTop:mobile?'20px':'0px' }}>
       <CardMedia
          component="img"
						sx={{ width: 60, height: 60, marginTop: mobile ?'13px':'17px',marginLeft:mobile ?'13px':"20px"}}
          image="https://drive.google.com/uc?export=view&id=1w2Qj6ovduVGtvlqefNDMmPqtnHU19qQW"
          alt="Live from space album cover"
        />
        <CardContent sx={{ flex: "1 0 auto",margin:'auto' }}>
          <Typography component="div" variant="h4" sx={{textAlign:'initial',fontSize:mobile?'12px':"18px",fontWeight:'600',color:'#333335'}}>
		  Desirable workplace 
          </Typography>
          <Typography component="div" variant="h4" sx={{textAlign:'initial',fontSize:mobile?'12px':"18px",fontWeight:'600',color:'#333335'}}>
		  for creators
          </Typography>
        </CardContent>
     
						</Card>
						</Grid>
		<Grid item md={4} lg={4}>
	<Card sx={{ display: "flex",background: '#BB00000A',width:mobile?'215px':"100%",height:mobile?'63px':'100%',marginRight:mobile?'0px':'150px',borderRadius:'12px',marginTop:mobile?'20px':'0px' }}>
       <CardMedia
          component="img"
          sx={{ width: 60 ,height:60,margin:'auto',marginTop: mobile ?'13px':'17px',marginLeft:mobile ?'10px':"20px" }}
          image="https://drive.google.com/uc?export=view&id=1KZWFBbg-TPHOlSoAfOdbf0envmNPSxZi"
          alt="Live from space album cover"
        />
        <CardContent sx={{ flex: "1 0 auto",margin:'auto',marginLeft:mobile?'-8px':"0px"}}>
          <Typography component="div" variant="h4"  sx={{textAlign:'initial',fontSize:mobile?'12px':"18px",fontWeight:'600',color:'#333335'}}>
		  Efforts for perfection, 
          </Typography>
          <Typography component="div" variant="h4"  sx={{textAlign:'initial',fontSize:mobile?'12px':"18px",fontWeight:'600',color:'#333335'}}>
		  quality, and supremacy
          </Typography>
        </CardContent>
     
						</Card>
						</Grid>
					</Grid>
				</Grid>
				
			
			<Footer/>
		  </>
  )
}
export default About;