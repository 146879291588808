import React from 'react'
import { Button, Grid, Typography,Paper} from '@mui/material';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useForm } from 'react-hook-form';
import Image from 'mui-image';
import MenuItem from '@mui/material/MenuItem';
import ResponsiveAppBar from './menu';
import Select from '@mui/material/Select';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Footer from '../footer';
import Carousel from 'react-material-ui-carousel'
import Partnerslider from './slider';
import Editing from '../Dashboard_author/Editing';
import CoverDesigning from '../Dashboard_author/CoverDesigning';
import { useDevice } from '../responsive/devices';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Email_URL, BASE_URL } from '../components/helper';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from 'axios';
function Partnerus() {
	const { register, handleSubmit,reset,formState: { errors } } = useForm();
	const onSubmit = async (data,e) => {
		const formData = new FormData();
		console.log(data);
		setOpen(true);
		e.preventDefault();
		
		const response = await fetch(`${Email_URL}/api/email/partnerus`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify(data),
		}).then((res) => res.json()).then(async (res) => {
			const resData = await res;
			console.log(resData);
			if (resData.status === "success") {
				alert("Message Sent");
			} else if (resData.status === "fail") {
				alert("Message failed to send");
			}
		})
		console.log(JSON.stringify(data));
		console.log(FormData, 'hi');
	  };
	const mobile = useMediaQuery('(max-width:600px)');
	const Devices = useDevice();
	const [open, setOpen] = React.useState(false);

	
  
	const handleClose = () => {
		setOpen(false);
		reset();
	};
	const [service, setServices] = React.useState('');
	const handleChange = (event) => {
		setServices(event.target.value);
	  };
	console.log(errors);
	return (<>
	  <ResponsiveAppBar />
	  <>
	  <Grid container md={12} xs={12}>
		  <Grid container md={6} xs={12}>
			<Box sx={{backgroundColor:' #BB00000A',width: mobile?'375px':'704px',height: mobile?'800px':'912px',marginTop:"20px",marginBottom:'74px'}}>
			
			<Grid container md={12} spacing={2} >
				  <Grid item md={12} spacing={2} sx={{}}>
				  <Typography variant='h2' sx={{
					  fontStyle: 'normal', fontWeight: '600', fontSize:mobile?'32px': '36px', lineHeight: '48px', textAlign: 'center',
					  color: '#BB0000',marginTop:'56px',marginLeft:mobile?'32px':"110px",marginRight:'40px'
				  }}>Why you should partner with us</Typography>   
							</Grid>
					<Box sx={{width:'500px',marginLeft:mobile?'45px':"110px",marginRight:mobile?'0px':'40px'}}>
					<Grid container spacing={2}>	
						<Grid item md={1} style={{marginTop:"37px"  }}>
					  <CheckCircleOutlineRoundedIcon sx={{color:'green',backgroundColor:'white',borderRadius:"20px"}} />
						  </Grid>
					<Grid item >
					  <Typography sx={{marginTop:"37px"}}>200+ authors actively working with us</Typography>
					  </Grid>
					  
					  </Grid>
					  
					 
					  <Grid container spacing={2}>
					<Grid item md={1} style={{marginTop:mobile?'6px':"21px"  }} >
					  <CheckCircleOutlineRoundedIcon sx={{color:'green',backgroundColor:'white',borderRadius:"20px"}} />
						  </Grid>
					<Grid item >
					  <Typography sx={{marginTop:mobile?'6px':"21px"}} >100k+ content pieces delivered</Typography>
					  </Grid>

					  </Grid>


					  <Grid container spacing={2} >
						<Grid item md={1}  style={{marginTop:mobile?'6px':"21px"  }}>
					  <CheckCircleOutlineRoundedIcon sx={{color:'green',backgroundColor:'white',borderRadius:"20px"}} />
						  </Grid>
					<Grid item >
					  <Typography sx={{marginTop:mobile?'6px':"21px"}}>Hassle-free payments</Typography>
					  </Grid>

					  </Grid>
						<Grid container spacing={2} >
						<Grid item md={1}  style={{marginTop:mobile?'6px':"21px"  }}>
					  <CheckCircleOutlineRoundedIcon sx={{color:'green',backgroundColor:'white',borderRadius:"20px"}} />
						  </Grid>
					<Grid item >
					  <Typography sx={{marginTop:mobile?'6px':"21px"}}>Connect with like-minded clients</Typography>
					  </Grid>

					  </Grid>
					  <Grid container spacing={2} >
						<Grid item md={1}  style={{marginTop:mobile?'6px':"21px"  }}>
					  <CheckCircleOutlineRoundedIcon sx={{color:'green',backgroundColor:'white',borderRadius:"20px"}} />
						  </Grid>
					<Grid item >
					  <Typography sx={{marginTop:mobile?'6px':"21px"}}>Excel in your niche</Typography>
					  </Grid>

					  </Grid>
					  </Box>
						</Grid>

					 <Grid container md={12} sx={{marginLeft:mobile?'25px':"100px",marginTop:mobile?'36px':'56px'}}>
						  <Typography variant='h4' sx={{fontSize:mobile?'16px':'18px',fontWeight:"600"}}>Trusted By</Typography>
							</Grid>	
				  <Grid container md={12} sx={{marginTop:"16px"}}>		
					  <Grid item md={3} sx={{marginLeft:mobile?'24px':'70px'}} >
				<Image src="https://drive.google.com/uc?export=view&id=1oxOpbsvHPzmZz7El_wFTVxsp6t9U7gYL" style={{
    justifyContent:'space-around',borderRadius:'12px',width:mobile?'73px': '130px',
							  height:mobile?'38px': '48px'
						  }}>
							  
	</Image>
					  </Grid>
					  <Grid item md={3}sx={{marginLeft:mobile?'24px':'0px'}}>
				<Image src="https://drive.google.com/uc?export=view&id=1RFPo_Uqxz8lYvx0ZSR5rtn9jdTL3kx-h" style={{
    justifyContent:'space-around',borderRadius:'12px',width:mobile?'73px': '130px',
	height: mobile?'38px':'48px'
  }}></Image>
					  </Grid>
					  <Grid item md={3} sx={{marginLeft:mobile?'24px':'0px'}}>
				<Image src="https://drive.google.com/uc?export=view&id=1_R6Y-rgNyOtfvQRMofUe2X-he6a_NeYi" style={{
    justifyContent:'space-around',borderRadius:'12px',width:mobile?'73px': '130px',
	height: mobile?'38px':'48px'
  }}></Image>
			  </Grid>




				  </Grid>
  						<Grid item md={12} sx={{marginTop:mobile?'56px':"60px",marginLeft:mobile?'24px':"90px",marginBottom:mobile?'90px':'0px'}}>
						<Partnerslider />
						</Grid>
			  </Box>		  
		  </Grid>
		  <Grid container md={6}>
			<Box component="form" noValidate onSubmit={handleSubmit}>
			<Typography variant='h2' sx={{fontSize:"36px",fontWeight:600,marginTop:'67px',textAlign:'center'}}> Partner with Us </Typography> 
		
						<Grid container md={7}  xs={10} sx={{margin:'auto'}}>
							
			<Grid container md={12} xs={12} sx={{marginLeft:Devices?'24px':"125px"}}>
								<Grid item md={12} xs={12} sx={{marginTop:'36px',textAlign:'initial'}}>
			<Typography variant='h4' sx={{  fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>Name</Typography>
			</Grid>
			<Grid item md={5} xs={6} >
                <TextField
                  autoComplete="given-name"
                  name="Name"
                  required
                  fullWidth
                  id="Name"
                  placeholder="Enter your full name"
										size='small'
										{...register('Name')}
										sx={{width:Devices?'274px':"363px",height:"40px"}}
                />
              </Grid>
              </Grid>
			
							<Grid container md={12} xs={12} sx={{marginLeft:Devices?'24px':"125px"}}>
								<Grid item md={12} xs={12} sx={{marginTop:'16px',textAlign:'initial'}} >
					<Typography variant='h4' sx={{  fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>Email</Typography>
				</Grid>
              <Grid item md={5} xs={6} >
                <TextField
                  required
                  fullWidth
                  name="email"
                  placeholder="Enter your email"
                  type="email"
                  id="email"
													autoComplete="new-password"
										size='small'
										{...register('Email')}
										sx={{width:Devices?'274px':"363px",height:"40px"}}
                />
				</Grid>
							</Grid>
							<Grid container md={12} xs={12} sx={{marginLeft:Devices?'24px':"125px"}}>
				<Grid item md={12} xs={12} sx={{marginTop:'16px',textAlign:'initial'}}>
					<Typography variant='h4' sx={{  fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>Phone Number</Typography>
				</Grid>
              <Grid item md={5} xs={6} >
                <TextField
                  required
                  fullWidth
                  name="password"
                  placeholder="Enter your Phone number"
                 
                  id="password"
				  autoComplete="new-password"
										size='small'
										sx={{width:Devices?'274px':"363px",height:"40px"}}
                />
				</Grid>
				</Grid>
					  <Grid container md={12} xs={12} sx={{marginLeft:Devices?'24px':"125px"}}>
				<Grid item md={12} xs={12} sx={{marginTop:'16px',textAlign:'initial'}}>
					<Typography variant='h4' sx={{  fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>Services Offered</Typography>
				</Grid>
								<Grid item md={5} xs={6} >
								<select  style={{width:mobile?'274px':'364px',height:"40px",textAlign:"initial"}}{...register("Services")}>
									<option value="Editing">Editing</option>
									<option value="Cover Designing">CoverDesigning</option>
		  <option value={"Ghost Writing"}>Ghost Writing</option>
		<option value={"BookStore"}>BookStore</option> 
		<option value={"BookReview"}>BookReview</option>							
		<option value={"BookTrailer"}>BookTrailer</option>
		<option value={"AmazonAds"}>AmazonAds</option>
        <option value={"Translation"}>Translation</option>
      </select>			
			  {/* <Select
          labelId="demo-simple-select-label"
										id="demo-simple-select"
										displayEmpty
          value={service}
          sx={{width:Devices?'274px':'364px',height:"40px",textAlign:'initial'}}
										onChange={handleChange}
										{...register('Services')}	
									>
										 <MenuItem value="">
            Please select
          </MenuItem>
          <MenuItem value="Editing">Editing</MenuItem>
          <MenuItem value={"Cover Designing"}>CoverDesigning</MenuItem>
		  <MenuItem value={"Ghost Writing"}>Ghost Writing</MenuItem>
		<MenuItem value={"BookStore"}>BookStore</MenuItem> 
		<MenuItem value={"BookReview"}>BookReview</MenuItem>							
		<MenuItem value={"BookTrailer"}>BookTrailer</MenuItem>
		<MenuItem value={"AmazonAds"}>AmazonAds</MenuItem>
        </Select> */}
				</Grid>
					  </Grid>
					  
				<Grid container md={12} xs={12} sx={{marginLeft:Devices?'24px':"125px"}}>
				<Grid item md={12}  xs={12} sx={{marginTop:'16px',textAlign:'initial'}} >
					<Typography variant='h4' sx={{ fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>Portfolio/Work samples</Typography>
				</Grid>
              <Grid item md={5}  xs={6}>
                <TextField
                  required
                  fullWidth
             
                  placeholder="work sample"
                  {...register('WorkSample')}	
                  id="password"
													autoComplete="new-password"
										size='small'
										sx={{width:Devices?'274px':"363px",height:"40px"}}
                />
				</Grid>
				</Grid>
				<Grid container md={12}  xs={12} sx={{marginLeft:Devices?'24px':"125px"}}>
								<Grid item md={12} xs={12} sx={{marginTop:'16px',textAlign:'initial'}} >
					<Typography variant='h4' sx={{  fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>Additional Comments</Typography>
				</Grid>
              <Grid item md={5}  xs={6} >
                <TextField
                  required
                  fullWidth
                  name="password"
				  placeholder="Write your comments "
                  type="password"
										id="password"
										{...register('Comments')}
										autoComplete="new-password"
										multiline
										rows={4}
										size='small'
										sx={{width:Devices?'274px':"363px",height:"86px"}}
                />
				</Grid>
					  </Grid>
					  <Grid container md={12}  xs={12}>
							<Grid item md={12}  xs={11}sx={{marginTop:'32px',
							width: "330px",height:"48px",marginLeft:mobile?'24px':"0px",textAlign:'initial'}}>
							<FormControlLabel control={<Checkbox defaultChecked />} label="By clicking this box, you agree to our T&C and Privacy Policy" />
							</Grid>
							</Grid>
              <Grid item md={6}  xs={12}  >
			  <Grid container justifyContent="flex-end" md={12}  xs={12}>
              <Grid item  xs={12}>
			  <Button
              type="submit"
											fullWidth
											onClick={handleSubmit(onSubmit)}
              variant="contained"sx={{
				mt: '24px', background: '#3A81F3',width:"160px",height:"48px",textTransform:"none",
				borderRadius: '8px'}}
			>
			Submit
										</Button>
										<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Order Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <CheckCircleOutlineIcon /> Thank you for Joining on our platform
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
              </Grid>
            </Grid>
								</Grid>
								
						</Grid>		
							
          </Box>
		  </Grid>
	  </Grid>	  
  </>
		<Footer />
	</>
  )
}
export default Partnerus;
