import React, { useState } from "react";
import axios from "axios";
import useRazorpay from "react-razorpay";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Email_URL,BASE_URL } from '../components/helper'
const Payment = () => {
  const [selectedOption, setSelectedOption] = useState(null);
	const [words, setWords] = useState("");
	const onSubmit = data => console.log(data);

	const [totalMoney, setTotalMoney] = useState("");
	const { register, watch,control,handleSubmit,setValue,reset, formState: { errors } } = useForm();
  const Razorpay = useRazorpay();
  const options = [
    { id: 'sustainable-editing', label: "R", value: 2,price:0.80},
    { id: 2, label: "OA", value: 0.8,price:0.80 },
    { id: 3, label: "O", value: 0.8 ,price:0.80},
    { id: 4, label: "Opton 4", value: 0.8 ,price:0.80},
  ];

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleWordsChange = (event) => {
    setWords(event.target.value);
  };

  const handlePayment = async (data,e) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/payment/api/payment`, {
        data
      });
      const options = {
        key: 'rzp_test_Jtpvppi2NrnCCK',
        amount: response.data.amount,
        currency: response.data.currency,
        name: "Example App",
        description: "Example payment",
        order_id: response.data.order_id,
        handler: function (response) {
          alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
        },
        prefill: {
          name: "John Doe",
          email: "johndoe@example.com",
        },
		};

		const rzp = new Razorpay(options);
		console.log(rzp);
		console.log(options);
		rzp.on("payment.failed", function (response) {
			alert(response.error.code);
			alert(response.error.description);
			alert(response.error.source);
			alert(response.error.step);
			alert(response.error.reason);
			alert(response.error.metadata.order_id);
			alert(response.error.metadata.payment_id);
		  });
      
      rzp.open();
    } catch (error) {
      console.log(error);
      alert("Error processing payment");
    }
  };

	const calculateTotalMoney = () => {
		console.log(selectedOption)
    const costPerWord = selectedOption.price;
    const wordsCount = parseInt(words);
    const total = costPerWord * wordsCount;
    setTotalMoney(total.toFixed(2));
  };
	const mobile = false;
  return (
	  <div>
		  <form onSubmit={handleSubmit(onSubmit)}>
      <h1>Select an option:</h1>
      {options.map((option) => (
        <div key={option.id}>
          <input
				  type="radio"
				  {...register("EditingType")}
            id={option.id}
            name="options"
            value='88888'
            checked={selectedOption && selectedOption.id === option.id}
            onChange={() => handleOptionChange(option)}
          />
			  <label htmlFor={option.id}>{option.label}</label>
			  
        </div>
	  ))}
			  <div key={options[0].id}>
  <input
    type="radio"
    {...register("EditingType")}
    id={options[0].id}
    name="options"
    value='88888'
    checked={selectedOption && selectedOption.id === options[0].id}
    onChange={() => handleOptionChange(options[0])}
  />
  <label htmlFor={options[0].id}>{options[0].label}</label>
</div>

<div key={options[1].id}>
  <input
    type="radio"
    {...register("EditingType")}
    id={options[1].id}
    name="options"
    value='88888'
    checked={selectedOption && selectedOption.id === options[1].id}
    onChange={() => handleOptionChange(options[1])}
  />
  <label htmlFor={options[1].id}>{options[1].label}</label>
</div>

<div key={options[2].id}>
  <input
    type="radio"
    {...register("EditingType")}
    id={options[2].id}
    name="options"
    value='88888'
    checked={selectedOption && selectedOption.id === options[2].id}
    onChange={() => handleOptionChange(options[2])}
  />
  <label htmlFor={options[2].id}>{options[2].label}</label>
</div>

<div key={options[3].id}>
  <input
    type="radio"
    {...register("EditingType")}
    id={options[3].id}
    name="options"
    value='88888'
    checked={selectedOption && selectedOption.id === options[3].id}
    onChange={() => handleOptionChange(options[3])}
  />
  <label htmlFor={options[3].id}>{options[3].label}</label>
</div>

			  <div key='sustainable-editing'>
			  <label htmlFor="sustainable-editing" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("EditingType")}
                        type="radio"
						  value="sustainable-editing"
						  name="options"
						  id="sustainable-editing"
						  
						  checked={selectedOption && selectedOption.id === 'sustainable-editing'} 
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Substantive Editing
				  </label><br />
				  </div>
			  <div>
                <label htmlFor="field-wind" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("EditingType")}
                        type="radio"
                        value="Copyediting"
							id="field-wind"
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Copyediting
                </label><br/>
                <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("EditingType")}
                        type="radio"
                        value="Proofreading"
							id="field-sun"
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Proofreading
					  </label><br />
					  <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("EditingType")}
                        type="radio"
                        value="Beta-reading"
							id="field-sun"
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Beta-reading
					  </label><br />
					  <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("EditingType")}
                        type="radio"
                        value="Editorial Evaluation"
							id="field-sun"
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Editorial Evaluation
					  </label><br/>
					  <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("EditingType")}
                        type="radio"
                        value="Narrative Assessment"
							id="field-sun"
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Narrative Assessment
				  </label><br />
				  </div>
      <h1>Enter the number of words:</h1>
      <input type="number" value={words} onChange={handleWordsChange} />
			  <button onClick={calculateTotalMoney}>Calculate total</button>
			  <button onClick={onSubmit}>sss</button>
      {totalMoney && <h1>Total money: {totalMoney}</h1>}
      <button onClick={handlePayment} disabled={!totalMoney}>
        Pay with Razorpay
			  </button>
			  </form>
    </div>
  );
};

export default Payment;