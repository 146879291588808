import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Grid from '@mui/material/Grid';
import { Box, Button, Typography } from "@mui/material";
import Image from "mui-image";
import ResponsiveAppBar from "./menu";
import Footer from "../footer";
import { padding } from "@mui/system";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDevice } from '../responsive/devices'
import { Link} from 'react-router-dom';
export default function Services() {
	const Devices = useDevice();
	const mobile = useMediaQuery('(max-width:600px)');
	const ipad = useMediaQuery('(min-width: 768px) and (max-width: 1180px)');
	return (
		<>
	<ResponsiveAppBar/>
    <Box sx={{ margin:mobile?"0px": "00px",marginTop:mobile?'0px':'80px' }}>
      <Typography variant="h2" sx={{ textAlign: "center",fontSize:mobile?"20px":"36px",fontWeight:"600",marginTop:mobile?"36px":'0px' }}>
        Services Offered
				</Typography>
				<Grid container md={9} sx={{margin:'auto',marginTop:mobile?'36px':'0px'}}>
      <ImageList
        sx={{ margin:mobile?"24px": "0px" }}
        cols={mobile?2:4} gap={25}
        style={{ overflow: "hidden",marginTop:mobile?"0px":"64px" }}
      >
					<>
						
						<ImageListItem >
						<Link to='/editing1' style={{textTransform:"none",textDecoration:'none'}}> 
            <Image Duration={0}
              src="https://drive.google.com/uc?export=view&id=1PoF9e2mIKUtOJpKRYcIv1w3UtLRunnkd"
              style={{ m: "auto",transitionDuration:'0',animation:'0',borderRadius:mobile?'8px':"12px"}}
								/>
							</Link>	
							<Link to='/editing1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
								<Typography variant="subtitle2" sx={{ mt: mobile ? '8px' : "12px", mb: mobile ? '16px' : "32px", fontSize: mobile ? '12px' : "18px", fontWeight: '600', textAlign: 'initial', textDecoration: 'none' }}>Editing</Typography>
							</Link> 
							<Link to='/Ams' style={{textTransform:"none",textDecoration:'none'}}> 
								<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1iSfLAbXy5Am04pQge6HmmwEHEG1777pF" style={{ m: "auto", transitionDuration: '0', animation: '0', borderRadius: mobile ? '8px' : "12px" }} /></Link>
							{mobile ?
								<Link to='/Ams' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
								<Typography variant="subtitle2" sx={{ mt: "8px", mb: "16px", fontSize: "12px", fontWeight: '600', textAlign: 'initial' }}>AMS</Typography></Link>
								:<Link to='/Ams' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
							
								<Typography variant="subtitle2" sx={{ mt: "12px", mb: "32px", fontSize: "18px", fontWeight: '600', textAlign: 'initial' }}>Amazon Marketing Services</Typography> </Link>
		} </ImageListItem>
							
						<ImageListItem>
						<Link to='/Coverdesign1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
							
            <Image  Duration={0}
              src="https://drive.google.com/uc?export=view&id=1Pe9SzyCXRpz1jGUAtXNzBbffUnu72xb4"
              style={{ m: "auto",transitionDuration:'0',animation:'0' }}
								/>
							</Link>
							<Link to='/Coverdesign1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
            <Typography variant="subtitle2" sx={{mt:mobile?'8px':"12px",mb:mobile?'16px':"32px",fontSize:mobile?'12px':"18px",fontWeight:'600',textAlign:'initial'}}>Cover Designing</Typography>
							</Link>
							<Link to='/Bookreviews1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
		    <Image  Duration={0} src="https://drive.google.com/uc?export=view&id=1CfZgdqMSdIiI84Nx0WjtyBsa3baNqZ8J" style={{ m: "auto",transitionDuration:'0',animation:'0',borderRadius:mobile?'8px':"12px"}}/>
							</Link>
							<Link to='/Bookreviews1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
								<Typography variant="subtitle2" sx={{ mt: mobile ? '8px' : "12px", mb: mobile ? '16px' : "32px", fontSize: mobile ? '12px' : "18px", fontWeight: '600', textAlign: 'initial' }}>Book Reviews</Typography>
          </Link>
						</ImageListItem>
						<ImageListItem>
						<Link to='/Translation1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
            <Image Duration={0}
              src="https://drive.google.com/uc?export=view&id=1DBKqfRWk32wF2mVdgElUNoMqt3VNcyLv"
              style={{ m: "auto",transitionDuration:'0',animation:'0',borderRadius:mobile?'8px':"12px"  }}
								/></Link>
							<Link to='/Translation1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
            <Typography variant="subtitle2" sx={{mt:mobile?'8px':"12px",mb:mobile?'16px':"32px",fontSize:mobile?'12px':"18px",fontWeight:'600',textAlign:'initial'}}>Translation</Typography>
							</Link>
							<Link to='/Booktrailer1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
								<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1NL1WJ_sgq6gRnzBj_AmJOy_AfF9LQCjT" style={{ m: "auto", transitionDuration: '0', animation: '0', borderRadius: mobile ? '8px' : "12px" }} />
           </Link>
		   <Link to='/Booktrailer1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
							<Typography variant="subtitle2" sx={{ mt: mobile ? '8px' : "12px", mb: mobile ? '16px' : "32px", fontSize: mobile ? '12px' : "18px", fontWeight: '600', textAlign: 'initial' }}>Book Trailer</Typography>
          </Link>
						</ImageListItem>
						<ImageListItem>
						<Link to='/Ghostwriting1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
            <Image  Duration={0}
              src="https://drive.google.com/uc?export=view&id=1_EwsTrTny4Fn31AqghFgJJofiz1TtiNE"
              style={{ m: "auto",transitionDuration:'0',animation:'0',borderRadius:mobile?'8px':"12px" }}
								/>
							</Link>
							<Link to='/Ghostwriting1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
            <Typography variant="subtitle2" sx={{mt:mobile?'8px':"12px",mb:mobile?'16px':"32px",fontSize:mobile?'12px':"18px",fontWeight:'600',textAlign:'initial'}}>Ghostwriting</Typography>
							</Link>
							<Link to='/Bookstore1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
								<Image Duration={0} src="https://drive.google.com/uc?export=view&id=1gpWNGXq0L6n2ABWqfs-wEL7XX408JKjv" style={{ m: "auto", transitionDuration: '0', animation: '0', borderRadius: mobile ? '8px' : "12px" }} />
							</Link>
							<Link to='/Bookstore1' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
								<Typography variant="subtitle2" sx={{ mt: mobile ? '8px' : "12px", mb: mobile ? '16px' : "32px", fontSize: mobile ? '12px' : "18px", fontWeight: '600', textAlign: 'initial' }}>Bookstore</Typography>
          </Link>
						</ImageListItem>
        </>
					</ImageList>
					</Grid>
				<Grid container md={12} xs={12}>
					<Grid item md={12} xs={12}>
					<Link to='/signup' style={{ textTransform: "none", textDecoration: 'none', color: 'black' }}>
					<Button variant="contained" size='large' sx={{textTransform:'none',width:mobile?"95px":"128px",height:mobile?"42px":"48px",borderRadius:"8px",padding:'12px 20px',fontSize:mobile?"12px":"16px",fontWeight:"500",marginTop:mobile?'15px':'',backgroundColor:'#3A81F3',marginBottom:ipad?'46px':mobile?'30px':'0px'}}>See more</Button>
			</Link>
					</Grid>
				</Grid>
			</Box>
			<Footer/>
			</>
  );
}
