
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dashboard_au from '../Dashboard_author/Dashboard_author';
import { Box } from '@mui/system';
import {useMediaQuery} from '@mui/material';
import {Grid} from '@mui/material';
import {Button} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Image } from 'mui-image';
const ServicesE = [
	{ title: "Editing" ,value:'editing'},
	{ title: "Cover Designing"},
	{ title: "Translation" },
	{ title: "Book Store" },
	{ title: "Book Reviews" },{ title: "Book Trailer" },{ title: "Ghost Writing" },{ title: "Amazon Ads" }
  ];
function Episode7() {
	const [data, setData] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [showGrid, setShowGrid] = useState(false);
	const mobile = useMediaQuery('(max-width:600px)');
	const handleChange11 = (event, value) => {
		setSelectedOptions(value);
		navigate(`../${value}`);
	}
	// useEffect(() => {
	//   const fetchData = async () => {
	// 	const response = await fetch("http://localhost:8080/api/check/signed-url/Episode1.mp4");
	// 	  const jsonData = await response.json();
	// 	  console.log(jsonData)
	// 	setData(jsonData);
	//   };
  
	//   fetchData();
	// }, []);

	const [videoEnded, setVideoEnded] = useState(false);
	const navigate = useNavigate();
	const [signedUrl, setSignedUrl] = useState(null);
	
const [progress, setProgress] = useState(0);
const [canProceed, setCanProceed] = useState(false);
const handleProgress = ({ played }) => {
	const progress = played * 100;
	setProgress(progress);
	if (progress >= 80) {
	  setCanProceed(true);
	} else {
	  setCanProceed(false);
	}
  }
 const config = {
    file: {
      attributes: {
			controlsList: 'nodownload',
		  poster:"https://drive.google.com/uc?export=view&id=1wgHzYo1iqYgoASs0C-W0h_9oVzqvMunS",
        onContextMenu: e => e.preventDefault()
      }
    }
	};
	const handleNext1= () => {
		navigate('/Episode8');
	};
 const handleVideoEnd = () => {
	 setVideoEnded(true);
	 navigate('/Episode8');
  };
 const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
 const handleNext = () => {
	navigate('/Episode6');
 };
	
  return (
	  <>
		  <Dashboard_au />
		  <Box sx={{  backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'auto',height:mobile?'1600px':(showGrid)?"0px":'1150px'}}>
		  {mobile ? '' :<Box sx={{width:"78%",left: mobile?'24px':'272px',position: 'absolute',marginTop:'24px'}}>
				  <Grid container md={12} lg={12} sx={{ marginTop: "20px" }}>
				<Grid container md={6} lg={6}>
					<Grid item md={9}lg={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>MasterClass</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} lg={6} sx={{justifyContent:'end'}}>
					<Grid item md={6} lg={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={3} lg={3} sx={{display:'flex',justifyContent:"center"}}>
					<Button variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
          </Button>
					</Grid>
				</Grid>
				  </Grid>
		  </Box>}
		  
			   <Box  sx={{
				position: 'absolute',
left: mobile?'48px':'272px',
top: mobile?'90px': '115px',
			  }}>
				  <Grid container lg={12} xs={12}>
					<Link to='../Episode6'> <ArrowBackIcon style={{ color:'#333335',
						  marginBottom:"13px"}} /></Link> 
				  </Grid>
        <Grid container lg={12} >
                <Grid container md={12} xs={11} lg={8.5}>
        <Grid item lg={9} md={12} xs={12}   sx={{textAlign: 'center'}}>
          <ReactPlayer
				url='https://drive.google.com/uc?export=view&id=1eOcViB3PMowAgu8cowLQ1DKw5yK_YZJD'
								  onEnded={handleVideoEnd}
								  onProgress={handleProgress}
				config={config}
								  controls={true}
								  width={mobile?'100%':'131%'}
								  height='100%'
			  />
						  </Grid>
				
						  <Grid item lg={7.8} md={12} xs={12} sx={{ textAlign: 'center' }}>
						  {mobile?<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant='h2' sx={{ textAlign: 'initial', fontSize: mobile ? '16px' : '24px', lineHeight: mobile ? '20px' : '40px', fontWeight: mobile ? '700' : '600', marginTop: "20px" }}>Episode7: Building Characters</Typography>
        </AccordionSummary>
        <AccordionDetails>
		<Typography sx={{ textAlign: 'initial', fontSize: mobile ? '12px' : '18px', lineHeight: mobile ? '16px' : '26px', fontWeight: mobile ? '700' : '500', marginTop: mobile ? '12px' : '8px' }}>	
					  In Episode 7 of the writing course, we explore the fundamental role of characters in storytelling. Kevin Missal, a bestselling author and experienced writing teacher, shares his insights and strategies for creating compelling and complex characters that will drive your story forward.

Through this episode, you will learn how to develop well-rounded and relatable characters that readers will care about and root for. Kevin also discusses the importance of character arcs and how to create character-driven stories that are both compelling and impactful.

With practical exercises and examples, Kevin guides you through the process of creating memorable and authentic characters for your writing project. Whether you are writing fiction or non-fiction, this episode is sure to provide you with valuable insights and strategies for developing characters that will form the backbone of your story.

By the end of this episode, you will have a better understanding of how to create compelling and complex characters and how to use those characters to drive your story forward. So, join us in this exciting episode of the writing course and take the next step towards creating stories that will resonate with readers and leave a lasting impact.	  </Typography>
        </AccordionDetails>
      </Accordion>
:""}
							  {mobile ? "" : <Typography variant='h2' sx={{ textAlign: 'initial', fontSize: mobile ? '16px' : '24px', lineHeight: mobile ? '20px' : '40px', fontWeight: mobile ? '700' : '600', marginTop: "20px" }}>Episode7: Building Characters</Typography>
							  }	  </Grid>
				<Grid item lg={4} md={12} xs={12}   sx={{textAlign: 'center',justifyContent:"space-between",display:'flex'}}>
							  <Grid>
							  <Button  variant='contained' sx={{ textAlign: 'initial', fontSize: mobile ? '16px' : '16px', lineHeight: mobile ? '20px' : '40px', fontWeight: mobile ? '700' : '500', backgroundColor: "#3A81F3", width: "100%", height: '40px', marginTop: "20px", textTransform:'none'}} onClick={() => { handleNext() }}>Previous</Button>
        
							  </Grid>
							  <Grid>
							  <Button disabled={!canProceed} variant='contained' sx={{ textAlign: 'initial', fontSize: mobile ? '16px' : '16px', lineHeight: mobile ? '20px' : '40px', fontWeight: mobile ? '700' : '500', backgroundColor: "#3A81F3", width: "110px", height: '40px', marginTop: "20px", textTransform: 'none' }} onClick={() => { handleNext1() }}>Next</Button>
							  </Grid>
							  </Grid>
        </Grid>
        <Grid container md={12} xs={11} lg={3}>
        {mobile?'':<Grid item lg={9} md={12} xs={12}   sx={{textAlign: 'center'}}>
        <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'32px':'24px',lineHeight:mobile?'36px':'40px',fontWeight:mobile?'700':'600'}}>Up next</Typography>
        </Grid>}
        <Grid container md={12} xs={12} lg={12}>
        <Grid item lg={12} md={12} xs={6}   sx={{textAlign: 'center'}}>
          
        <Image Duration={0}
              src="https://drive.google.com/uc?export=view&id=1t5XA36RqqgNjeksNKCiW5bJpYNdHbGh3"
              style={{ transitionDuration:'0',animation:'0',width:mobile?'128px':"100%",height:mobile?'88px':"180px",borderRadius:"12px",marginTop:mobile?'12px':'12px'}}></Image> 
        </Grid>
        <Grid item lg={12} md={12} xs={6}   sx={{textAlign: 'center'}}>
        <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':'22px',fontWeight:mobile?'700':'600',marginTop:mobile?'12px':'8px'}}>Episode 8: Know what is Setting and why it is important</Typography>
        </Grid>
        </Grid>
        <Grid container md={12} xs={12} lg={12}>
        <Grid item lg={12} md={12} xs={6}   sx={{textAlign: 'center'}}>
          
        <Image Duration={0}
              src="https://drive.google.com/uc?export=view&id=1o4xdFu7PXoSm89e351hfL3TuQbAdHLbh"
              style={{ transitionDuration:'0',animation:'0',width:mobile?'128px':"100%",height:mobile?'88px':"180px",borderRadius:"12px",marginTop:mobile?'12px':'26px'}}></Image> 
        </Grid>
        <Grid item lg={12} md={12} xs={6}   sx={{textAlign: 'center'}}>
        <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':'22px',fontWeight:mobile?'700':'600',marginTop:mobile?'12px':'8px'}}>Episode 9: Using Conflict to make your story interesting</Typography>

        </Grid>
        </Grid>
        </Grid>
        
				  </Grid>
				  <Grid container lg={8}>
					  {mobile ? "" : <Typography sx={{ textAlign: 'initial', fontSize: mobile ? '12px' : '18px', lineHeight: mobile ? '16px' : '26px', fontWeight: mobile ? '700' : '500', marginTop: mobile ? '12px' : '8px' }}>
						  In Episode 7 of the writing course, we explore the fundamental role of characters in storytelling. Kevin Missal, a bestselling author and experienced writing teacher, shares his insights and strategies for creating compelling and complex characters that will drive your story forward.

						  Through this episode, you will learn how to develop well-rounded and relatable characters that readers will care about and root for. Kevin also discusses the importance of character arcs and how to create character-driven stories that are both compelling and impactful.

						  With practical exercises and examples, Kevin guides you through the process of creating memorable and authentic characters for your writing project. Whether you are writing fiction or non-fiction, this episode is sure to provide you with valuable insights and strategies for developing characters that will form the backbone of your story.

						  By the end of this episode, you will have a better understanding of how to create compelling and complex characters and how to use those characters to drive your story forward. So, join us in this exciting episode of the writing course and take the next step towards creating stories that will resonate with readers and leave a lasting impact.	  </Typography>
					  } </Grid>
      </Box>
			  </Box>
	  </>
  )
}
export default Episode7;