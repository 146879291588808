import React from 'react'
import { Toolbar,Box } from '@mui/material';
import { AppBar } from '@mui/material';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';


import SettingsIcon from '@mui/icons-material/Settings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import IconButton from '@mui/material/IconButton';
function DashboardFooter() {
	const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
	<AppBar position="fixed" sx={{ top: 'auto', bottom: 0,backgroundColor:"#FFFFFF"}}>
		  <Toolbar>
		  <Tabs value={value} onChange={handleChange}>
      
      <Tab icon={ <HistoryToggleOffOutlinedIcon/>}value='0' style={{textTransform:'none'}} href="/Explore" />
	  <Tab icon={<ShoppingBagOutlinedIcon />}  value='1' style={{textTransform:'none'}} href="/Editing"  />
				  <Tab icon={<HelpOutlineIcon />} value='2' style={{ textTransform: 'none' }} href="/Faq" />
				  <Tab icon={<SettingsIcon  />}  value='3' style={{ textTransform: 'none' }} href="/Setting" />
    </Tabs>
        </Toolbar>
      </AppBar>
  )
}
export default DashboardFooter;
