import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuItem from '@mui/material/MenuItem';
import Image from "mui-image";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import { Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
const pages = [ 'Home','Services','About','Contact','Blog','Sign in'];




function ResponsiveAppBar() {
	const isSmallScreen = useMediaQuery('(max-width:600px)');

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };
  const drawer = (
    <div 
      role="presentation"
      onClick={toggleDrawer(false)}
		  onKeyDown={toggleDrawer(false)}
		  style={{width:'258px'}}
    >
		  <List>
		  <ListItem  style={{justifyContent:'end'}}>
		  <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon />
      </IconButton>
        </ListItem>
        <ListItem   >
				  <ListItemText primary={<Link to='/Home' style={{ textDecoration: 'none',color:'black' }}>Home</Link>} />
        </ListItem>
        <ListItem >
				  <ListItemText primary={<Link to='/Services' style={{ textDecoration: 'none', color: 'black' }}>Services</Link>} />
        </ListItem>
        <ListItem >
          <ListItemText primary={<Link to='/about' style={{ textDecoration: 'none', color: 'black' }}>About</Link>} />
        </ListItem>
        <ListItem >
          <ListItemText primary={<Link to='/contact' style={{ textDecoration: 'none', color: 'black' }}>Contact</Link>} />
        </ListItem>
        <ListItem >
          <ListItemText primary={<Link to='/Blog' style={{ textDecoration: 'none', color: 'black' }}>Blog</Link>} />
        </ListItem>
      </List>
    </div>
  );
  return (
    <AppBar position='static' component='nav' sx={{ backgroundColor: '#FFFFFF',width:'auto' }}>	
     
		  <Box sx={{ color: '#FFFFFF', display: { xs: 'flex', md: 'flex' },flexGrow:1,justifyContent:'space-between' }}>
		  <Box sx={{  display: { xs: 'flex', md: 'flex' },margin:isSmallScreen?"unset":'auto' }}>
			 
		  {isSmallScreen ?<>
			 <IconButton edge="start" onClick={toggleDrawer(true)} style={{margin:'auto'}}>
          <MenuIcon />
      </IconButton>
      <Drawer open={isOpen} onClose={toggleDrawer(false)} style={{width:"250px"}}>
			  {drawer}
			  </Drawer>
			  </>: ''} 
				  <Link to='/'> 
          <Image duration={0} src="https://drive.google.com/uc?export=view&id=1DAyaOiRTn1fi2eVSohkY6-2XgHxuwLd5" style={{width:isSmallScreen?'63px':'103px',height:isSmallScreen?'56px':'103px',margin:'auto',marginTop:'9px'}} />
				  </Link>
				  </Box>
			  <Box sx={{ color: '#FFFFFF', display: { xs: 'flex', md: 'flex' }, margin: isSmallScreen?"inherit":'auto', }}>
			  {pages.map((page) => (
				  isSmallScreen ? "" :
					  <Button
					key={page}
                sx={{ my: 1, color: 'black', display: 'block',mx:2,fontSize:"16px",textAlign:'center',textTransform:'none'}}
              >			
					<Link to={`/${page}`} style={{textDecoration:'none',color:'#333335',fontSize:'16px',fontWeight:550}}>{page}</Link>
              </Button>
			))}
        <Link to='/SignUp' style={{ textDecoration: 'none', fontWeight: 550,margin:'auto' }}>
          <Button variant='contained' sx={{ margin: isSmallScreen ? "12px" : "0px", textTransform: 'none',borderRadius:isSmallScreen?"8px":"8px",backgroundColor:'#3A81F3'}} >Sign Up </Button> 
			  </Link>
			  </Box>
   
		 
       
		 
			  </Box>
    </AppBar>
  );
}
export default ResponsiveAppBar;
