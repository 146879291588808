import './App.css';
import LandingPage from './landingpage';
import ResponsiveAppBar from './components/menu'
import Services from './components/Services';
import About from './components/About';
import  Editing  from './Dashboard_author/Editing';
import Appl from './components/stepper';
import Contact from './components/contact';
import Home from './components/signup';
import Signin from './components/signin';
import Signup from './components/signup';
import { useEffect, useState } from "react";
import Blog from './components/Blog';
import Partnerus from './components/Partnerus';
import BookNewsFeed from './Dashboard_author/Newsfeed';
import { Route, Routes ,Navigate} from 'react-router-dom';
import Dashboard_au from './Dashboard_author/Dashboard_author';
import CoverDesigning from './Dashboard_author/CoverDesigning';
import Translation from './Dashboard_author/Translation';
import GhostWriting from './Dashboard_author/GhostWriting';
import AmazonAds from './Dashboard_author/AmazonAds';
import BookReviews from './Dashboard_author/BookReviews';
import BookTrailer from './Dashboard_author/BookTrailer';
import BookStore from './Dashboard_author/BookStore';
import Blog1 from './components/Blog1';
import Blog3 from './components/Blog3';
import Blog2 from './components/Blog2';
import Blog4 from './components/Blog4';
import Privacy from './components/privacy';
import Faq from './Dashboard_author/Faq';
import Setting from './Dashboard_author/Setting';
import  Explore  from './Dashboard_author/Explore';
import TermsandCondition from './components/Termsandcondition';
import ForgotPassword from './components/forgetPassword'
import Setting1 from './Dashboard_author/Setting1';
import Masterclass from './MasterClass/Masterclass';
import Playlist from './MasterClass/Playlist';
import Instructor from './MasterClass/Instructor'
import Learnbest from './MasterClass/learnbest';
import Episode1 from './MasterClass/Episode1';
import Episode2 from './MasterClass/Episode2';
import  Episode3  from './MasterClass/Episode3';

import  Episode4  from './MasterClass/Episode4';
import  Episode5  from './MasterClass/Episode5';
import  Episode6  from './MasterClass/Episode6';

import  Episode7  from './MasterClass/Episode7';

import  Episode8 from './MasterClass/Episode8';
import  Episode9 from './MasterClass/Episode9';
import  Episode10  from './MasterClass/Episode10';
import  Episode11  from './MasterClass/Episode11';

import  Episode12  from './MasterClass/Episode12';
import  Episode13  from './MasterClass/Episode13';
import  Episode14  from './MasterClass/Episode14';
import  Episode15 from './MasterClass/Episode15';
import  Episode16  from './MasterClass/Episode16';

import Publish from './Dashboard_author/Publish'
import Episode17 from './MasterClass/Episode17';
import Episode18 from './MasterClass/Episode18';
import  Episode19  from './MasterClass/Episode19';
import Editing1 from './components/Editing1';
import Bookreviews1 from './components/Bookreviews1';
import Booktrailer1 from './components/Booktrailer1';
import Bookstore1 from './components/Bookstore1';
import Coverdesign1 from './components/Coverdesign1';
import Translation1 from './components/Translation1';
import Ghostwriting1 from './components/Ghostwriting1';
import Payment from './Dashboard_author/Payment';
import Ams from './components/Ams';
import ImageUpload from './Dashboard_author/userprofile';
import CSignup from './Contest/signupc';
import CLogin from './Contest/Signc';
import  Terms  from './Contest/terms';
import ImageCarousel from './components/ImageC';
function App() {
	const user = localStorage.getItem("token");
	const google = localStorage.getItem('user');
	const [use, setUser] = useState(null);
	console.log(google, 'adada');
	
  return (
	  <div className="App">
		  <Routes>
			  {user && (<>
		  <Route path="/" exact element={user?<Explore />:<LandingPage/>} /> 
			   <Route path="Editing" element={<Editing />} />
			   <Route path="CoverDesigning" element={<CoverDesigning />} />
			   <Route path="Cover Designing" element={<CoverDesigning />} />
			   <Route path="Translation" element={<Translation />} />
			   <Route path="GhostWriting" element={<GhostWriting />} />
			   <Route path="Ghost Writing" element={<GhostWriting />} />
			   <Route path="AmazonAds" element={<AmazonAds />} />
			   <Route path="Amazon Ads" element={<AmazonAds />} />
			   <Route path="BookReviews" element={<BookReviews />} />
			   <Route path="Book Reviews" element={<BookReviews />} />
			   <Route path="BookTrailer" element={<BookTrailer />} />
			   <Route path="Book Trailer" element={<BookTrailer />} />
			   <Route path="Book Store" element={<BookStore />} />
			   <Route path="NewsFeed" element={<BookNewsFeed />} />
				  <Route path="BookStore" element={<BookStore />} />
				  <Route path='Explore' element={<Explore />} />
			  <Route path='Setting' element={<Setting />} />
			   </>  ) 
			  }
			  {google && (<>
			  <Route path="/" exact element={google ? <Explore /> : <LandingPage />} /> 
			 <Route path="Editing" element={<Editing />} />
			 <Route path="CoverDesigning" element={<CoverDesigning />} />
			 <Route path="Cover Designing" element={<CoverDesigning />} />
			 <Route path="Translation" element={<Translation />} />
			 <Route path="GhostWriting" element={<GhostWriting />} />
			 <Route path="Ghost Writing" element={<GhostWriting />} />
			 <Route path="AmazonAds" element={<AmazonAds />} />
			 <Route path="Amazon Ads" element={<AmazonAds />} />
			 <Route path="BookReviews" element={<BookReviews />} />
			 <Route path="Book Reviews" element={<BookReviews />} />
			 <Route path="BookTrailer" element={<BookTrailer />} />
			 <Route path="Book Trailer" element={<BookTrailer />} />
			 <Route path="Book Store" element={<BookStore />} />
			 <Route path="NewsFeed" element={<BookNewsFeed />} />
				<Route path="BookStore" element={<BookStore />} />
			
			<Route path='Setting' element={<Setting />} />
		</>	  )}
			  
			   {user && <Route path="/signin" exact element={<Signin />} /> 
			  }

			  
			  <Route path='/' element={<LandingPage />} />
			  <Route path='Home' element={<LandingPage />} />
			  <Route path='Services' element={<Services />} />
			  <Route path='Sign in' element={use?<Signin />:<Signin />} />
			  <Route path='Blog' element={<Blog />} />
			  <Route path='About' element={<About />} />
			  <Route path='Signup' element={<Signup />} />
			  <Route path='Contact' element={<Contact />} />
			  <Route path='Partnerus' element={<Partnerus />} />
			  <Route path='dashboard_au' element={<Dashboard_au />} />
			  <Route path='Explore' element={<Explore />} />
			  <Route path="Editing" element={<Editing />} />
			  <Route path="blog1" element={<Blog1 />} />
			  <Route path="blog3" element={<Blog3 />} />
			  <Route path="blog2" element={<Blog2 />} />
			  <Route path="blog4" element={<Blog4 />} />
			  <Route path="Faq" element={<Faq />} />
			  <Route path='privacy' element={<Privacy />} />
			  <Route path="CoverDesigning" element={<CoverDesigning />} />
			 <Route path="Cover Designing" element={<CoverDesigning />} />
			 <Route path="Translation" element={<Translation />} />
			 <Route path="GhostWriting" element={<GhostWriting />} />
			 <Route path="Ghost Writing" element={<GhostWriting />} />
			 <Route path="AmazonAds" element={<AmazonAds />} />
			 <Route path="Amazon Ads" element={<AmazonAds />} />
			 <Route path="BookReviews" element={<BookReviews />} />
			 <Route path="Book Reviews" element={<BookReviews />} />
			 <Route path="BookTrailer" element={<BookTrailer />} />
			 <Route path="Book Trailer" element={<BookTrailer />} />
			 <Route path="Book Store" element={<BookStore />} />
			 <Route path="NewsFeed" element={<BookNewsFeed />} />
			  <Route path="BookStore" element={<BookStore />} />
			  <Route path="ImageUpload" element={<ImageUpload />} />
			  <Route path='termsandcondition' element={<TermsandCondition />} />
			  <Route path='forgotpassword' element={<ForgotPassword />} />
			  <Route path='MasterClass' element={<Masterclass />} />
			  <Route path='Playlist' element={<Playlist />} />
			  <Route path='instructor' element={<Instructor />} />
			  <Route path='learnbest' element={<Learnbest />} />
			  <Route path='Episode1' element={<Episode1 />} />
			  <Route path='Episode2' element={<Episode2 />} />
			  <Route path='Episode3' element={<Episode3 />} />
			  <Route path='Episode4' element={<Episode4 />} />
			<Route path='editing1' element={<Editing1/>} />
			  <Route path='Episode5' element={<Episode5 />} />
			  <Route path='Episode6' element={<Episode6 />} />
			  <Route path='Episode7' element={<Episode7 />} />
			  <Route path='Episode8' element={<Episode8/>} />
			  <Route path='Episode9' element={<Episode9 />} />

			  <Route path='Episode10' element={<Episode10 />} />
			  <Route path='Episode11' element={<Episode11 />} />

			  <Route path='Episode12' element={<Episode12 />} />
			  <Route path='Episode13' element={<Episode13 />} />
			  <Route path='Episode14' element={<Episode14 />} />

			  <Route path='Episode15' element={<Episode15 />} />
			  <Route path='Episode16' element={<Episode16 />} />
			  <Route path='Episode17' element={<Episode17 />} />
			  <Route path='Episode18' element={<Episode18 />} />
			  <Route path='Episode19' element={<Episode19 />} />
			  <Route path='Coverdesign1' element={<Coverdesign1 />} />
			  <Route path='Translation1' element={<Translation1 />} />
			  <Route path='ca' element={<ImageCarousel />} />
			  <Route path='Ghostwriting1' element={<Ghostwriting1 />} />
			  <Route path='Ams' element={<Ams />} />
			  <Route path='Bookreviews1' element={<Bookreviews1 />} />
			  <Route path='Booktrailer1' element={<Booktrailer1 />} />
			  <Route path='Bookstore1' element={<Bookstore1 />} />
			  <Route path='payment' element={<Payment />} />
			  <Route path='Setting1' element={<Setting1 />} />
			  <Route path='publishnow' element={<Publish />} />
			  <Route path='CLogin' element={<CLogin/>} />
			  <Route path='CSignup' element={<CSignup />} />
			  <Route path='terms' element={<Terms />} />
 		</Routes> 
		  
		 
    </div>
  );
}

export default App;
