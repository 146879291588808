import React from 'react'
//import * as React from 'react';
import { useRef } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import Autocomplete from "@mui/material/Autocomplete";
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ResponsiveAppBar from '../components/menu';
import Image from "mui-image";
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link, Navigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import DashboardFooter from './DashboardFooter';
import { useState } from 'react';
import { borderColor } from '@mui/system';
import axios from 'axios';
import { Email_URL,BASE_URL } from '../components/helper'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { green, blue, grey, red } from "@mui/material/colors";
import Dashboard_au from './Dashboard_author';
import { EventRepeat } from '@mui/icons-material';
import jwt_decode from "jwt-decode";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// const pages = ['Home', 'Services', 'About', 'Contact', 'Blog', 'Signin'];
// const dashboard1=['Editing','CoverDesigning','Translation','GhostWriting','AmazonAds','BookReviews','BookTrailer','BookStore']
// const drawerWidth = 240;
const ServicesE = [
	{ title: "Editing" ,value:'editing'},
	{ title: "Cover Designing"},
	{ title: "Translation" },
	{ title: "Book Store" },
	{ title: "Book Reviews" },{ title: "Book Trailer" },{ title: "Ghost Writing" },{ title: "Amazon Ads" }
  ];


function Translation(props, status, r, profile) {
	const { register, watch,control,handleSubmit,setValue,reset, formState: { errors } } = useForm();
	const { window } = props;
	const navigate = useNavigate();
	const form = useRef();
	const [checkb, setcheckb] = useState([]);
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [adventurous, setAdventurous] = useState("");
	const [Autobiographical, setAutobiographical] = useState("");
	const [Biography, setBiography] = useState("");
	const [Candid, setCandid] = useState("");
	const mobile = useMediaQuery('(max-width:600px)');
	const [Classic, setClassic] = useState("");
	const [Coming, setComing] = useState("");
	const [Dark, setDark] = useState("");
	const [Deep, setDeep] = useState("");
	const [Diverse, setDiverse] = useState("");
	const [Educational, setEducational] = useState("");
	const [Empowering, setEmpowering] = useState("");
	const [Fictional, setFictional] = useState("");
	const [Futuristic, setFuturistic] = useState("");
	const [Funny, setFunny] = useState("");
	const [Emotional,setEmotional] = useState("");
	const [selectedOption, setSelectedOption] = useState("");
	const [showDialog, setShowDialog] = useState(false);
	const [textInput, setTextInput] = useState([]);
	const [user, setUser] = useState({
		to: "rajeevkhanduja@gmail.com",
		subject: "",
		description: "",
		
	});
	const [open, setOpen] = React.useState(false);
	const phoneNumber = '+91-7060495034';
	const emailAddress = 'Abhijeet@Hubhawks.com';
	const handleOpen = () => {
		setShowDialog(true);
	 }
	const handleDialog = () => {
		setShowDialog(false);
	 }
	const handleClose = () => {
		setOpen(false);
		reset();
	};
// 	const [color, setColor] = useState("");
	const [write, setWrite] = useState("");
	const [msg,setMsg] = useState('');
//   const handleColor = () => {
//     setColor("#3A81F3");
//     setTextColor("white");
//   };

	const handleTextInputChange = (event) => {
		const value = event.target.value;
		const checked = event.target.checked;
		console.log(value, checked);
		if (checked)
		{
			setTextInput([...textInput,value]);
		}
		else {
			setTextInput(textInput.filter((event) => (event !== value)));
		}
    
	};
	const handlewrite = (event) => {
		setWrite(event.target.value);
	}
	const getUserFromToken = () => {
		const token = localStorage.getItem("token");
		console.log(token, 'token');
		if (!token)
			return null;
		try {
			const decoded = jwt_decode(token);
			console.log(decoded, 'toksen');
		  return decoded;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	};
	const getUserFromToken1 = () => {
		const gogle = localStorage.getItem("user");
		console.log(gogle, 'token');
		if (!gogle)
			return null;
		try {
			const decoded = jwt_decode(gogle);
			console.log(decoded, 'toksen');
		  return decoded;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	  };

	const onSubmit = async (data,e) => {
		const formData = new FormData();
		console.log(data);
		setOpen(true);
		e.preventDefault();
		const user1 = getUserFromToken1();
		const user = getUserFromToken();
		if (user) {
			
			if (!user) return;
			const response = await axios.post(`${Email_URL}/api/email/translation`, {
				...data, user
			
			}).then((res) => res.json()).then(async (res) => {
				const resData = await res;
				console.log(resData);
				if (resData.status === "success") {
					alert("Message Sent");
				} else if (resData.status === "fail") {
					alert("Message failed to send");
				}
			})
			console.log(JSON.stringify(data), user, 'rajivk');
		}
		else {
			
			if (user1) {
				const user1 = getUserFromToken1();
			if (!user1) return;
			const response = await axios.post(`${Email_URL}/api/email/translationG`, {
				...data, user1
			
			}).then((res) => res.json()).then(async (res) => {
				const resData = await res;
				console.log(resData);
				if (resData.status === "success") {
					alert("Message Sent");
				} else if (resData.status === "fail") {
					alert("Message failed to send");
				}
			})
			console.log(JSON.stringify(data), user1, 'rajivk');
			}
		}
		console.log(JSON.stringify(data));
		console.log(FormData, 'hi');
	  };
	function colorForStatus(status) {
		switch (status) {
		  case "Adventurous":
		  case "Autobiographical":
			case "Biography":
			case "Candid":
			case "Classic":
			case "Coming":
			case "Dark":
			case "Deep":
			case "Diverse":
			case "Educational":
			case "Empowering":
			case "Fictional":
			case "Futuristic":
			case "Funny":
			case "Emotional":
				
				
			return blue;
		  
		  default:
			return grey;
		}
	}
	const handleLogout = () => {
		localStorage.removeItem("token");
		
		
	};
	const [value1, setValue1] = React.useState(2);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const handleChange11 = (event, value) => {
		setSelectedOptions(value);
		navigate(`../${value}`);
	}
  const handleChange = (event, newValue) => {
    setValue1(newValue);
  };
	const onValueChange=(event)=> {
		setSelectedOption(event.target.value);
		console.log(event.target.value);	
	  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
	};
	const handleClick = () => {
		console.info('You clicked the Chip.');
	  };

  const container = window !== undefined ? () => window().document.body : undefined;

	return (<>
		<Dashboard_au />
		<Box sx={{  backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'auto',height:mobile?'1500px':'1890px'}}>
			{mobile ? '' :
				<Box sx={{ width: "78%", left: mobile ? '24px' : '272px', position: 'absolute', marginTop: '24px' }}>
				<Grid container md={12} sx={{ marginTop: "20px" }}>
				<Grid container md={6}>
					<Grid item md={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>Marketplace</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} sx={{justifyContent:'end'}}>
					<Grid item md={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={4} sx={{display:'flex',justifyContent:"center"}}>
					<Button onClick={handleOpen} variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
						</Button>
						<Dialog open={showDialog} onClose={handleClose} sx={{width:'500px',height:'280px',justifyContent:'center',margin:'auto',borderRadius:'0px 0px 12px 12px'}}>
						<DialogTitle sx={{ background: '#3A81F3', color: '#FFFFFF', textAlign: 'center' }}>
						<Grid container lg={12} xs={12}>
							<Grid item lg={10} xs={10}>
							Contact Us
							</Grid>
							<Grid item lg={2} xs={2}>
							<Button onClick={handleDialog}><CloseIcon sx={{color:'#FFFFFF'}} /></Button>
			 
								</Grid>
								</Grid></DialogTitle>
        <DialogContent >
							  <DialogContentText>
								<Grid container lg={12}>
									<Grid container lg={10} sx={{margin:'auto'}}>
									
									<Grid item lg={10} sx={{margin:'auto'}}>
	  <Button  href={`mailto:${emailAddress}`} style={{color:"black",textTransform:'none',fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><EmailOutlinedIcon />Abhijeet@Hubhawks.com</Button>
	  </Grid>
	  		<Grid item lg={8} sx={{margin:'auto'}}>
	  <Button href={`tel:${phoneNumber}`} style={{color:"black",fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><PhoneInTalkOutlinedIcon/>+91-7060495034</Button>
	  </Grid>
									</Grid>
								</Grid>
								  
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
					</Grid>
				</Grid>
					</Grid>
			</Box>}
			<Paper component='form' width="1152px" height='950px' sx={{
				position: 'absolute',
width:mobile?'272px': '75%',
height: mobile?'auto':'auto',
left: mobile?'24px':'32px',marginLeft:mobile?'24px':'240px',
top:mobile?'90px': '115px',
background: '#FFFFFF',
				borderRadius: '12px'
			}}>
				{mobile?<Tabs
					value={value1}
					onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
       	<Tab label="Editing" value1='0' style={{textTransform:'none'}} href="/Editing"  />
        <Tab label="Cover Designing" value1='1' style={{textTransform:'none'}}href="/CoverDesigning" />
        <Tab label="Translation" value1='2' style={{textTransform:'none'}}href="/Translation"/>
        <Tab label="Ghost Writing"value1='3'style={{textTransform:'none'}} href= "/GhostWriting"/>
        <Tab label="Amazon Ads"value1='4' style={{textTransform:'none'}}href=  "/AmazonAds"/>
        <Tab label="Book Reviews"value1='5' style={{textTransform:'none'}} href= "/BookReviews"/>
		<Tab label="Book Trailer"value1='6' style={{textTransform:'none'}}href="/BookTrailer" />
		<Tab label="Book store"value1='7' style={{textTransform:'none'}}href= "/Bookstore" />
		</Tabs>
	
					: 
			  <Tabs style={{marginTop:"26px",marginLeft:'38px'}}
							value={value1}
							onChange={handleChange}
				variant="scrollable"
				scrollButtons
				allowScrollButtonsMobile
				aria-label="scrollable force tabs example"
			  >
					<Tab label="Editing" value1='0' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link} to="/Editing"  />
						<Tab label="Cover Designing" value1='1' style={{ textTransform: 'none', color: '#000000', fontSize: '16px', fontWeight: 400 }} component={Link} to="/CoverDesigning" />
							<Tab label="Translation" value1='2' style={{ textTransform: 'none', color: '#000000', fontSize: '16px', fontWeight: 600 }} component={Link } to="/Translation"/>
        <Tab label="Ghostwriting"value1='3'style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to= "/GhostWriting"/>
        <Tab label="Amazon Ads"value1='4' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to=  "/AmazonAds"/>
        <Tab label="Book Reviews"value1='5' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to= "/BookReviews"/>
		<Tab label="Book Trailer"value1='6' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link }to="/BookTrailer" />
		<Tab label="Bookstore"value1='7' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to= "/Bookstore" />
			</Tabs>
				  
				  }
    <Box component='form' encType="multipart/form-data"
      method="post"   >
		
				<Grid container xs={12}>
					<Grid item md={12} sx={{textAlign:'initial'}}>
							<Typography sx={{ marginTop: mobile ? "16px":'32px', marginLeft: mobile ? "16px":"84px",fontSize: mobile ? "12px":'18px'}}>What language is  your content in? </Typography>
					</Grid>
					<Grid item md={6} sx={{textAlign:'initial',marginTop:'16px'}} >
					<label htmlFor="field-rain" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("content")}
                        type="radio"
                        value="English"
									id="field-rain"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    English
                </label><br/>
                <label htmlFor="field-wind"style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("content")}
                        type="radio"
                        value="Hindi"
									id="field-wind"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Hindi
                </label><br/>
                <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("content")}
                        type="radio"
                        value="Tamil"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Tamil
					  </label><br/>
					  <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("content")}
                        type="radio"
                        value="Marathi"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Marathi
					  </label><br/>
					  <label htmlFor="field-sun"style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("content")}
                        type="radio"
                        value="Malayalam"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Malayalam
					  </label><br/>
					  <label htmlFor="field-sun"style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("content")}
                        type="radio"
                        value="Telugu"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Telugu
				</label><br/>
				<label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("content")}
                        type="radio"
                        value="Others"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Others
					  </label>
					  </Grid>
		
				
				  </Grid>
				<Grid container xs={12}>
					<Grid item md={12} sx={{textAlign:'initial'}}>
					<Typography sx={{marginTop:'32px',marginLeft:mobile?'16px':"84px",fontSize:mobile?'12px': '18px'}}>What is the genre of your book?</Typography>
					</Grid>
					<Grid item md={6} sx={{textAlign:'initial',marginTop:'16px'}} >
					<label htmlFor="field-rain"style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("genre")}
                        type="checkbox"
                        value="Literary  fiction"
								id="field-rain"
									onChange={handleTextInputChange}
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Literary  fiction
                </label><br/>
                <label htmlFor="field-wind" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("genre")}
                        type="checkbox"
                        value="Mystery"
								id="field-wind"
									onChange={handleTextInputChange}
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Mystery
                </label><br/>
                <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                       {...register("genre")}
                        type="checkbox"
                        value="Thriller"
								id="field-sun"
									onChange={handleTextInputChange}
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Thriller
					  </label><br/>
					  <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                       {...register("genre")}
                        type="checkbox"
                        value=" Horror"
								id="field-sun"
									onChange={handleTextInputChange}
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Horror
					  </label><br/>
					  <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                       {...register("GetLanguage1")}
                        type="checkbox"
                        value="Historical"
								id="field-sun"
									onChange={handleTextInputChange}
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Historical
					  </label><br/>
					  <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                          {...register("genre")}
                        type="checkbox"
                        value="Romance"
								id="field-sun"
									onChange={handleTextInputChange}
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Romance
						</label><br />
						
				<label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                         {...register("genre")}
                        type="checkbox"
                        value=" Western"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Western
						</label>
						<br />
						
				<label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                         {...register("genre")}
                        type="checkbox"
                        value="Bildungsroman"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Bildungsroman
						</label>
						<br />
						
				<label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                         {...register("genre")}
                        type="checkbox"
                        value="Speculative Fiction"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Speculative Fiction
					  </label>
					  <br />
						
				<label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                         {...register("genre")}
                        type="checkbox"
                        value="Science Fiction"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Science Fiction
					  </label>
					  <br />
						
				<label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                         {...register("genre")}
                        type="checkbox"
                        value=" Fantasy"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Fantasy
					  </label>
					  <br />
						
				<label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                         {...register("genre")}
                        type="checkbox"
                        value="  Dystopian"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Dystopian
					  </label>

					  <br />
						
				<label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                         {...register("genre")}
                        type="checkbox"
                        value=" Magical Realism"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Magical Realism
					  </label>
					  <br />
						
				<label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                         {...register("genre")}
                        type="checkbox"
                        value="Realist Literature"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Realist Literature
					  </label>
					  <br />
					 </Grid>
				 
				  </Grid>
				  <Grid container xs={12}>
					<Grid item md={12} xs={12} sx={{textAlign:'initial'}}>
					<Typography sx={{marginTop:'32px',marginLeft:mobile?'16px':"84px",fontSize: mobile?'12px':'18px'}}>What is the word count of your book?</Typography>
					</Grid>
						<Grid item md={6} xs={10} sx={{ marginLeft: mobile ? "16px" : "84px", marginTop: mobile ? '12px' : "32px", '& .MuiTextField-root': {  width: mobile ? "222px":'582px',height:'40px' },}} >
						
					<TextField placeholder='writehere'
							  id="Wordsbook"
							  name="Wordsbook"
							  
							  fullWidth
							  margin="dense"
							  {...register('count')}
							  error={errors.Comments ? true : false}
							  inputProps={{ style: { padding:mobile?'8px 8px':'14.5px 16px' } }}
							 />
						  
					  </Grid>
			</Grid>
				<Grid container xs={12}>
					<Grid item md={12} sx={{textAlign:'initial'}}>
					  <Typography sx={{marginTop:'32px',marginLeft:mobile?'16px':"84px",fontSize: mobile?'12px':'18px'}} >What language do you want to get the translation in?</Typography>
					</Grid>
					<Grid item md={6} sx={{textAlign:'initial',marginTop:'16px'}} >
					<label htmlFor="field-rain" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("GetLanguage")}
                        type="checkbox"
                        value="English"
									id="field-rain"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
								onChange={handleTextInputChange}
                    />
                    English
                </label><br/>
                <label htmlFor="field-wind" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("GetLanguage")}
                        type="checkbox"
                        value="Hindi"
									id="field-wind"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
								onChange={handleTextInputChange}
                    />
                    Hindi
                </label><br/>
                <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                       {...register("GetLanguage")}
                        type="checkbox"
                        value="Tamil"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
								onChange={handleTextInputChange}
                    />
                    Tamil
					  </label><br/>
					  <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                       {...register("GetLanguage")}
                        type="checkbox"
                        value="Marathi"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
								onChange={handleTextInputChange}
                    />
                    Marathi
					  </label><br/>
					  <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                       {...register("GetLanguage")}
                        type="checkbox"
                        value="Malayalam"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
								onChange={handleTextInputChange}
                    />
                    Malayalam
					  </label><br/>
					  <label htmlFor="field-sun"style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                          {...register("GetLanguage")}
                        type="checkbox"
                        value="Telugu"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
								onChange={handleTextInputChange}
                    />
                    Telugu
				</label><br/>
				<label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                         {...register("GetLanguage")}
                        type="checkbox"
                        value="Others"
									id="field-sun"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    /> 
                    Others
					  </label>
					</Grid>
					</Grid>
					<Grid container xs={12}>
					<Grid item md={12} sx={{textAlign:'initial'}}>
					<Typography sx={{marginTop:'32px',marginLeft:mobile?'16px':"84px",fontSize:mobile?'12px': '18px'}} >What kind of translation do you want?  </Typography>
					</Grid> 
					<Grid item md={6} sx={{textAlign:'initial',marginTop:'16px'}}>
					<label htmlFor="field-rain" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("translation")}
                        type="radio"
                        value="Word-to-word translation"
									id="field-rain"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Word-to-word translation
                </label><br/>
                <label htmlFor="field-wind" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("translation")}
                        type="radio"
                        value="Creative translation"
									id="field-wind"
									style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Creative translation
                </label>
                
					</Grid>  		  
		
				  
					  </Grid>
					  <Grid container md={12} xs={12}>
				<Grid item md={12} sx={{ textAlign: 'initial' }}>
					
					<Typography sx={{ml:mobile?"16px":'84px',marginTop:mobile?'12px':'56px',fontSize:mobile?'12px': '18px'}}>Additional comments (Optional)</Typography>
				</Grid>
					<Grid item md={6} xs={10} sx={{marginLeft:mobile?"16px":"84px",marginTop:mobile?'12px':"32px"}}>
						  <TextField 
							 required
							 id="Comments"
							 name="Comments"
							 placeholder="Write additional comments here "
							fullWidth	
							
						multiline
						rows={mobile?2:4}
							 margin="dense"
							 {...register('Comments')}
							 error={errors.Comments ? true : false}
        />
					  </Grid>
					  </Grid>
				
					
					 
					
					  <Grid container md={12}>
					  <Button
              variant="contained"
              color="primary"
				onClick={handleSubmit(onSubmit)}
				sx={{backgroundColor:'#3A81F3',marginTop:mobile?'12px':'56px',marginLeft:mobile?'16px':'82px',textTransform: 'none',fontWeight:'500',fontSize:mobile?'12px':'16px',borderRadius:'8px',width: mobile?'52px':'91px',
				height: mobile?'36px':'48px',marginBottom:"40px"}}
            >
              Submit
						</Button>
						<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Order Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <CheckCircleOutlineIcon /> Thank you for your order! We have
              received your order and will process it shortly.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
			</Grid>	
			
			</Box>
			</Paper>
			{mobile?<DashboardFooter />:''}	
			</Box>
		</>
  );
}
export default Translation;