
import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dashboard_au from '../Dashboard_author/Dashboard_author';
import { Box } from '@mui/system';
import {useMediaQuery} from '@mui/material';
import {Grid} from '@mui/material';
import {Button} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { Typography,Tooltip,LinearProgress } from '@mui/material';
import {TextField} from '@mui/material';
import { Image } from 'mui-image';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const ServicesE = [
	{ title: "Editing" ,value:'editing'},
	{ title: "Cover Designing"},
	{ title: "Translation" },
	{ title: "Book Store" },
	{ title: "Book Reviews" },{ title: "Book Trailer" },{ title: "Ghost Writing" },{ title: "Amazon Ads" }
  ];
function Episode1() {
	const [data, setData] = useState([]);
	const videoRef = useRef(null);
  const [progress, setProgress] = useState(0);
	const [canProceed, setCanProceed] = useState(false);
	const handleProgress = ({ played }) => {
		const progress = played * 100;
		setProgress(progress);
		if (progress >= 80) {
		  setCanProceed(true);
		} else {
		  setCanProceed(false);
		}
	  }
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [showGrid, setShowGrid] = useState(false);
	const mobile = useMediaQuery('(max-width:600px)');
	const handleChange11 = (event, value) => {
		setSelectedOptions(value);
		navigate(`../${value}`);
	}
	// useEffect(() => {
	//   const fetchData = async () => {
	// 	const response = await fetch("http://localhost:8080/api/check/signed-url/Episode1.mp4");
	// 	  const jsonData = await response.json();
	// 	  console.log(jsonData)
	// 	setData(jsonData);
	//   };
  
	//   fetchData();
	// }, []);

	const [videoEnded, setVideoEnded] = useState(false);
	const navigate = useNavigate();
	const [signedUrl, setSignedUrl] = useState(null);
	
 const config = {
    file: {
      attributes: {
			controlsList: 'nodownload',
		  poster:"https://drive.google.com/uc?export=view&id=1pAQzoyrZegi-EHsOIkzuNHvqf9PXkgfo",
        onContextMenu: e => e.preventDefault()
      }
    }
	};
	const handleNext1= () => {
		navigate('/episode2');
	};
 const handleVideoEnd = () => {
	 setVideoEnded(true);
	 setCanProceed(true);
	 navigate('/episode2');
  };
 const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
 const handleNext = () => {
    setCurrentVideoIndex((prevIndex) => prevIndex + 1);
 };
	
  return (
	  <>
		  <Dashboard_au />
		  <Box sx={{  backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'auto',height:mobile?'1600px':(showGrid)?"0px":'1150px'}}>
		  {mobile ? '' :<Box sx={{width:"78%",left: mobile?'24px':'272px',position: 'absolute',marginTop:'24px'}}>
				  <Grid container md={12} lg={12} sx={{ marginTop: "20px" }}>
				<Grid container md={6} lg={6}>
					<Grid item md={9}lg={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>MasterClass</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} lg={6} sx={{justifyContent:'end'}}>
					<Grid item md={6} lg={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={3} lg={3} sx={{display:'flex',justifyContent:"center"}}>
					<Button variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
          </Button>
					</Grid>
				</Grid>
				  </Grid>
		  </Box>}
		  
			   <Box  sx={{
				position: 'absolute',
left: mobile?'48px':'272px',
top: mobile?'90px': '115px',
			  }}>
				  <Grid container lg={12} xs={12}>
					<Link to='../Masterclass'> <ArrowBackIcon style={{ color:'#333335',
						  marginBottom:"13px"}} /></Link> 
				  </Grid>
        <Grid container lg={12} >
                <Grid container md={12} xs={11} lg={8.5}>
        <Grid item lg={9} md={12} xs={12}   sx={{textAlign: 'center'}}>
          <ReactPlayer
				url='https://drive.google.com/uc?export=view&id=1BX__q0IeFKNUiWDLn0fyiWFC2LwGwJow'
				onEnded={handleVideoEnd}
								  config={config}
								  onProgress={handleProgress}
								  controls={true}
								  width={mobile?'100%':'131%'}
								  height='100%'
							  />
							
						  </Grid>
				
						  <Grid item lg={6.8} md={12} xs={12} sx={{ textAlign: 'center' }}>
						  {mobile?<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Episode 1: Introduction</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
		  The course covers a range of topics including developing a writing routine, creating compelling characters, crafting a gripping plot, and mastering the art of dialogue. Kevin's engaging teaching style and practical advice make this course ideal for writers of all levels.

Whether you are a beginner looking to start your writing journey or an experienced writer looking to hone your skills, this course has something for everyone. With Kevin's guidance, you will gain the confidence and tools you need to bring your writing to the next level.

So, join us in this video to learn more about the Writing lessons by Kevin Missal and how you can benefit from this course.
          </Typography>
        </AccordionDetails>
      </Accordion>
:""}			
      {mobile?"":  <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'16px':'24px',lineHeight:mobile?'20px':'40px',fontWeight:mobile?'700':'600',marginTop:"20px"}}>Episode 1: Introduction</Typography>
					}	  </Grid>
						 <Grid item lg={5} md={12} xs={12} sx={{ textAlign: 'center', justifyContent: "end", display: 'flex' }}>
						 <Tooltip title={canProceed ? "" : "Watch the complete video until you can proceed"}>
        <span>
							  <Button disabled={!canProceed} variant='contained' sx={{ textAlign: 'initial', fontSize: mobile ? '16px' : '16px', lineHeight: mobile ? '20px' : '40px', fontWeight: mobile ? '700' : '500', backgroundColor: "#3A81F3", width: "128px", height: '40px', marginTop: "20px", textTransform: 'none' }} onClick={() => { handleNext1() }}>Next</Button>
							  </span>
      </Tooltip>  </Grid>
						  
        </Grid>
					  <Grid container md={12} xs={11} lg={3}>
						  {mobile ? '' : <Grid item lg={9} md={12} xs={12} sx={{ textAlign: 'center' }}>
							  <Typography variant='h2' sx={{ textAlign: 'initial', fontSize: mobile ? '32px' : '24px', lineHeight: mobile ? '36px' : '40px', fontWeight: mobile ? '700' : '600' }}>Up next</Typography>
						  </Grid>}
						  <Grid container md={12} xs={12} lg={12}>
							  <Grid item lg={12} md={12} xs={6} sx={{ textAlign: 'center', display: 'flex', justifyContent: 'initial' }}>
          
								  <Image Duration={0}
									  src="https://drive.google.com/uc?export=view&id=1lIXWIcxe3w_Uos-bZdxkV0vWpDmwUu0Q"
									  style={{ transitionDuration: '0', animation: '0', width: mobile ? '128px' : "100%", height: mobile ? '88px' : "180px", borderRadius: "12px", marginTop: mobile ? '12px' : '12px' }}></Image>
							  </Grid>
							  <Grid item lg={12} md={12} xs={6} sx={{ textAlign: 'center' }}>
								  <Typography variant='h2' sx={{ textAlign: 'initial', fontSize: mobile ? '12px' : '18px', lineHeight: mobile ? '16px' : '22px', fontWeight: mobile ? '700' : '600', marginTop: mobile ? '12px' : '8px' }}>Episode 2: What is this course about?</Typography>
							  </Grid>
						  </Grid>
						  <Grid container md={12} xs={12} lg={12}>
							  <Grid item lg={12} md={12} xs={6} sx={{ textAlign: 'center' }}>
          
								  <Image Duration={0}
									  src="https://drive.google.com/uc?export=view&id=1Cqa_EKmPCzDlxdpyUqPeEiOizBK6qSZ3"
									  style={{ transitionDuration: '0', animation: '0', width: mobile ? '128px' : "100%", height: mobile ? '88px' : "180px", borderRadius: "12px", marginTop: mobile ? '12px' : '26px' }}></Image>
							  </Grid>
							  <Grid item lg={12} md={12} xs={6} sx={{ textAlign: 'center' }}>
								  <Typography variant='h2' sx={{ textAlign: 'initial', fontSize: mobile ? '12px' : '18px', lineHeight: mobile ? '16px' : '22px', fontWeight: mobile ? '700' : '600', marginTop: mobile ? '12px' : '8px' }}>Episode 3: Where to get ideas from?</Typography>

							  </Grid>
						  </Grid>
					  </Grid>
        
				  </Grid>
				  {mobile ? "" : <Grid container lg={8}>
					  <Typography sx={{ textAlign: 'initial', fontSize: mobile ? '12px' : '18px', lineHeight: mobile ? '16px' : '26px', fontWeight: mobile ? '700' : '500', marginTop: mobile ? '12px' : '8px' }}>			  In this video, we are excited to introduce the Writing lessons by Kevin Missal in their course. Kevin Missal is a well-known author who has published several popular books and has a wealth of experience in writing. In this course, he shares his expertise and insights on the craft of writing with aspiring writers who are looking to improve their skills.
						  The course covers a range of topics including developing a writing routine, creating compelling characters, crafting a gripping plot, and mastering the art of dialogue. Kevin's engaging teaching style and practical advice make this course ideal for writers of all levels.

						  Whether you are a beginner looking to start your writing journey or an experienced writer looking to hone your skills, this course has something for everyone. With Kevin's guidance, you will gain the confidence and tools you need to bring your writing to the next level.

						  So, join us in this video to learn more about the Writing lessons by Kevin Missal and how you can benefit from this course.
					  </Typography>
				  </Grid>}
      </Box>
			  </Box>
	  </>
  )
}
export default Episode1;