import React from 'react'
//import * as React from 'react';
import { useRef } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller, FormProvider } from "react-hook-form";
import {  useNavigate } from 'react-router-dom';
import Autocomplete from "@mui/material/Autocomplete";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ResponsiveAppBar from '../components/menu';
import Image from "mui-image";
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link, Navigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { borderColor } from '@mui/system';
import axios from 'axios';
import { Email_URL } from '../components/helper';
import {  blue, grey, common} from "@mui/material/colors";
import Dashboard_au from './Dashboard_author';
import useMediaQuery from '@mui/material/useMediaQuery';
import jwt_decode from "jwt-decode";
import DashboardFooter from './DashboardFooter';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// const pages = ['Home', 'Services', 'About', 'Contact', 'Blog', 'Signin'];
// const dashboard1=['Editing','CoverDesigning','Translation','GhostWriting','AmazonAds','BookReviews','BookTrailer','BookStore']
// const drawerWidth = 240;
const ServicesE = [
	{ title: "Editing" ,value:'editing'},
	{ title: "Cover Designing"},
	{ title: "Translation" },
	{ title: "Book Store" },
	{ title: "Book Reviews" },{ title: "Book Trailer" },{ title: "Ghost Writing" },{ title: "Amazon Ads" }
  ];


function AmazonAds(props, status, r, profile,dash) {
	const { register, watch,control,handleSubmit,setValue,reset, formState: { errors } } = useForm();
	const { window } = props;
	const form = useRef();
	const navigate = useNavigate();
	const mobile = useMediaQuery('(max-width:600px)');
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [adventurous, setAdventurous] = useState(null);
	const [Autobiographical, setAutobiographical] = useState(null);
	const [Biography, setBiography] = useState(null);
	const [Candid, setCandid] = useState(null);
	const [Classic, setClassic] = useState(null);
	const [Coming, setComing] = useState(null);
	const [Dark, setDark] = useState(null);
	const [Deep, setDeep] = useState(null);
	const [Diverse, setDiverse] = useState(null);
	const [Educational, setEducational] = useState(null);
	const [Empowering, setEmpowering] = useState(null);
	const [Fictional, setFictional] = useState(null);
	const [Futuristic, setFuturistic] = useState(null);
	const [Funny, setFunny] = useState(null);
	const [Emotional, setEmotional] = useState(null);
	const [Historical, setHistorical] = useState(null);
	const [Inspiring, setInspiring] = useState(null);
	const [modern, setModern] = useState(null);
	const [nonFiction, setFiction] = useState(null);
	const [spiritual, setSpiritual] = useState(null);
	const [thriller, setThriller] = useState(null);
	const [witty, setWitty] = useState(null);
	const [mythological, setMythological] = useState(null);
	const [selectedOption, setSelectedOption] = useState("");
	const [textInput, setTextInput] = useState("");
	const [showGrid, setShowGrid] = useState(false);
	const [showGriddesk, setshowGriddesk] = useState(false);
	const [open, setOpen] = React.useState(false);
	
	const [showDialog, setShowDialog] = useState(false);
	const phoneNumber = '+91-7060495034';
	const emailAddress = 'Abhijeet@Hubhawks.com';
	const handleOpen = () => {
		setShowDialog(true);
	 }
	const handleDialog = () => {
		setShowDialog(false);
	 }
	const getUserFromToken = () => {
		const token = localStorage.getItem("token");
		console.log(token, 'token');
		if (!token)
			return null;
		try {
			const decoded = jwt_decode(token);
			console.log(decoded, 'toksen');
		  return decoded;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	};
	const getUserFromToken1 = () => {
		const gogle = localStorage.getItem("user");
		console.log(gogle, 'token');
		if (!gogle)
			return null;
		try {
			const decoded = jwt_decode(gogle);
			console.log(decoded, 'toksen');
		  return decoded;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	  };
	
  
	const handleClose = () => {
		setOpen(false);
		setAdventurous(null);
		setAutobiographical(null);
		setBiography(null);
		setCandid(null);
		setComing(null);
		setDark(null);
		setDeep(null);
		setDiverse(null);
		setEducational(null);
		setEmotional(null);
		setEmpowering(null);
		setFiction(null);
		setFictional(null);
		setFunny(null);
		setFuturistic(null);
		setHistorical(null);
		setInspiring(null);
		setMythological(null);
		setSpiritual(null);
		setClassic(null);
		setThriller(null);
		setWitty(null);
		setModern(null);
		reset();
	};
	const [user, setUser] = useState({
		to: "rajeevkhanduja@gmail.com",
		subject: "",
		description: "",
		
	  });
// 	const [color, setColor] = useState("");
	const [write, setWrite] = useState("");
	const [msg,setMsg] = useState('');
//   const handleColor = () => {
//     setColor("#3A81F3");
//     setTextColor("white");
//   };
const [selectedOptions, setSelectedOptions] = useState([]);
const handleChange11 = (event, value) => {
	setSelectedOptions(value);
	navigate(`../${value}`);
}
const handleTextInputChange = (event) => {
    setTextInput(event.target.value);
	};
	const handlewrite = (event) => {
		setWrite(event.target.value);
	}
	const [value1, setValue1] = React.useState(4);

	const handleChange = (event, newValue) => {
	  setValue1(newValue);
	};
	const onSubmit = async (data, e) => {
		setOpen(true);
		e.preventDefault();
		const user1 = getUserFromToken1();
		const user = getUserFromToken();
		if (user) {
			
			if (!user) return;
			const response = await axios.post(`${Email_URL}/api/email/amazonAds`, {
				...data, user
			
			}).then((res) => res.json()).then(async (res) => {
				const resData = await res;
				console.log(resData);
				if (resData.status === "success") {
					alert("Message Sent");
				} else if (resData.status === "fail") {
					alert("Message failed to send");
				}
			})
			console.log(JSON.stringify(data), user, 'rajivk');
		}
		else {
			
			if (user1) {
				const user1 = getUserFromToken1();
			if (!user1) return;
			const response = await axios.post(`${Email_URL}/api/email/amazonAdsg`, {
				...data, user1
			
			}).then((res) => res.json()).then(async (res) => {
				const resData = await res;
				console.log(resData);
				if (resData.status === "success") {
					alert("Message Sent");
				} else if (resData.status === "fail") {
					alert("Message failed to send");
				}
			})
			console.log(JSON.stringify(data), user1, 'rajivk');
			}
		}
	  };
	  function colorForStatus(status) {
		switch (status) {
		  case "Adventurous":
		  case "Autobiographical":
			case "Biography":
			case "Candid":
			case "Classic":
			case "Coming-of-age":
			case "Dark":
			case "Deep":
			case "Diverse":
			case "Educational":
			case "Empowering":
			case "Fictional":
			case "Futuristic":
			case "Funny":
			case "Emotional":
			case "Emotional":
				case "Inspiring":
					case "Historical":
						case"modern":
					case "nonFiction":
					case "spiritual":
					case "witty":
					case "mythological":
						case "thriller":
				
				
				return common;
			
		  
		  default:
			return grey;
		}
	}
	function colorForStatus(dash) {
		switch (dash) {
		  case "Adventurous":
		  case "Autobiographical":
			case "Biography":
			case "Candid":
			case "Classic":
			case "Coming-of-age":
			case "Dark":
			case "Deep":
			case "Diverse":
			case "Educational":
			case "Empowering":
			case "Fictional":
			case "Futuristic":
			case "Funny":
			case "Emotional":
			case "Inspiring":
			case "Historical":
				case"modern":
			case "nonFiction":
			case "spiritual":
			case "witty":
			case "mythological":
				case "thriller":
				
				return common;
			
		  
		  default:
			return grey;
		}
	}
	const handleLogout = () => {
		localStorage.removeItem("token");
		
		
	};
	const onValueChange=(event)=> {
		setSelectedOption(event.target.value);
		console.log(event.target.value);	
	  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
	};
	const handleClick = () => {
		console.info('You clicked the Chip.');
	  };

//   const drawer = (
//     <div>
// 		  <Toolbar />
// 		  <Box sx={{m:"auto"}}>
// 			  <Image duration={0} src="https://drive.google.com/uc?export=view&id=1DAyaOiRTn1fi2eVSohkY6-2XgHxuwLd5" style={{
//     width: '98px',
//     height: '105px'
// 			  }}></Image>
		  
//           </Box>
// 	  <Divider />
//       <List>
//         {["Progress", "Marketplace", "FAQ","setting"].map((text, index) => (
// 			<>
				
// 		  <ListItem key={text} disablePadding>
//             <ListItemButton>
//               <ListItemIcon>
//                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//               </ListItemIcon>
//               <ListItemText primary={text} />
//             </ListItemButton>
// 			</ListItem>
// 				<Divider />
				
// 				</>
// 		))}
			  
// 			  <button onClick={handleLogout}>
// 				<Link to ='/'>Logout</Link>
// 				</button>
//       </List>
//     </div>
//   );

  const container = window !== undefined ? () => window().document.body : undefined;

	return (<>
		<Dashboard_au />

		<Box sx={{ backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'auto',height:mobile?(showGrid)?"1000px":'912px':'1500px'}}>
		{mobile ? '' :
				<Box sx={{ width: "78%", left: mobile ? '24px' : '272px', position: 'absolute', marginTop: '24px' }}>
				<Grid container md={12} sx={{ marginTop: "20px" }}>
				<Grid container md={6}>
					<Grid item md={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>Marketplace</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} sx={{justifyContent:'end'}}>
					<Grid item md={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={4} sx={{display:'flex',justifyContent:"center"}}>
					<Button onClick={handleOpen} variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
						</Button>
						<Dialog open={showDialog} onClose={handleClose} sx={{width:'500px',height:'280px',justifyContent:'center',margin:'auto',borderRadius:'0px 0px 12px 12px'}}>
						<DialogTitle sx={{ background: '#3A81F3', color: '#FFFFFF', textAlign: 'center' }}>
						<Grid container lg={12} xs={12}>
							<Grid item lg={10} xs={10}>
							Contact Us
							</Grid>
							<Grid item lg={2} xs={2}>
							<Button onClick={handleDialog}><CloseIcon sx={{color:'#FFFFFF'}} /></Button>
			 
								</Grid>
								</Grid></DialogTitle>
        <DialogContent >
							  <DialogContentText>
								<Grid container lg={12}>
									<Grid container lg={10} sx={{margin:'auto'}}>
									
									<Grid item lg={10} sx={{margin:'auto'}}>
	  <Button  href={`mailto:${emailAddress}`} style={{color:"black",textTransform:'none',fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><EmailOutlinedIcon />Abhijeet@Hubhawks.com</Button>
	  </Grid>
	  		<Grid item lg={8} sx={{margin:'auto'}}>
	  <Button href={`tel:${phoneNumber}`} style={{color:"black",fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><PhoneInTalkOutlinedIcon/>+91-7060495034</Button>
	  </Grid>
									</Grid>
								</Grid>
								  
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
					</Grid>
				</Grid>
					</Grid>
			</Box>}
			<Paper component='form' width="1152px" height='950px' sx={{
				position: 'absolute',
width:mobile?'272px': '75%',
height: mobile?(showGrid)?"950px":'800px':'1308px',
left: mobile?'24px':'32px',marginLeft:mobile?'24px':'240px',
top:mobile?'90px': '115px',
background: '#FFFFFF',
				borderRadius: '12px'
			}}>
				{mobile?<Tabs
					value={value1}
					onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
					aria-label="scrollable force tabs example"
					
      >
       	<Tab label="Editing" value1='0' style={{textTransform:'none'}} href="/Editing"  />
        <Tab label="Cover Designing" value1='1' style={{textTransform:'none'}}href="/CoverDesigning" />
        <Tab label="Translation" value1='2' style={{textTransform:'none'}}href="/Translation"/>
        <Tab label="Ghost Writing"value1='3'style={{textTransform:'none'}} href= "/GhostWriting"/>
        <Tab label="Amazon Ads"value1='4' style={{textTransform:'none'}}href=  "/AmazonAds"/>
        <Tab label="Book Reviews"value1='5' style={{textTransform:'none'}} href= "/BookReviews"/>
		<Tab label="Book Trailer"value1='6' style={{textTransform:'none'}}href="/BookTrailer" />
		<Tab label="Bookstore"value1='7' style={{textTransform:'none'}}href= "/Bookstore" />
		</Tabs>
		
					: 
			  <Tabs style={{marginTop:"26px",marginLeft:'38px'}}
							value={value1}
							onChange={handleChange}
				variant="scrollable"
				scrollButtons
				allowScrollButtonsMobile
				aria-label="scrollable force tabs example"
			  >
				<Tab label="Editing" value1='0' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link} to="/Editing"  />
						<Tab label="Cover Designing" value1='1' style={{ textTransform: 'none', color: '#000000', fontSize: '16px', fontWeight: 400 }} component={Link} to="/CoverDesigning" />
							<Tab label="Translation" value1='2' style={{ textTransform: 'none', color: '#000000', fontSize: '16px', fontWeight: 400 }} component={Link } to="/Translation"/>
        <Tab label="Ghostwriting"value1='3'style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to= "/GhostWriting"/>
        <Tab label="Amazon Ads"value1='4' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:600}} component={Link } to=  "/AmazonAds"/>
        <Tab label="Book Reviews"value1='5' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to= "/BookReviews"/>
		<Tab label="Book Trailer"value1='6' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link }to="/BookTrailer" />
		<Tab label="Bookstore"value1='7' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to= "/Bookstore" />
		</Tabs>
				  
				 }
   
		
			<Grid container md={12}>
			{/* <Grid item>
						  <Typography>What is the author’s background?</Typography>
						  <TextField 
							 required
							 id="Summary"
							 name="Summary"
							 label="Summary"
							 fullWidth
							 margin="dense"
							 {...register('Summary')}
							 error={errors.Summary ? true : false}
        />
				</Grid> */}
		
				  </Grid>
				  <Grid container md={12} xs={12}>
					<Grid item md={12} xs={12} sx={{textAlign:'initial'}}>
					  <Typography sx={{ml:mobile?"16px":'84px',marginTop:mobile?'12px':'56px',fontSize:mobile?'12px': '18px'}} >What keywords describe your book the best?</Typography>
				</Grid>
				
				<Grid item md={12} xs={12} sx={{justifyContent:'initial',marginTop:'16px'}}>
				<Grid item lg={10.5} xs={11} sx={{margin:'auto',textAlign:'left'}}>
						<Button label="Adventurous" variant='outlined'
							 style={{
								backgroundColor: colorForStatus((status = adventurous))[100],
								color:colorForStatus((dash = adventurous))[900], width: mobile?'73px':'134px',
								height:mobile?'24px': '40px',borderRadius:'20px',marginLeft:mobile?"0px":'16px',fontSize:mobile?'10px':"18px",textTransform:'none'
								,minWidth:"40px"  }} 
								  value={user.description}
							  onClick={(e) => {
								  if (adventurous === null) {
      setAdventurous("Adventurous");
      setValue("words1", "Adventurous");
    } else {
      setAdventurous(null);
      setValue("words1", null);
    }
								  
							  }} >Adventurous </Button>
						  <Button variant='outlined' 
							  style={{
								backgroundColor: colorForStatus((status = Autobiographical))[100],
								
								color:colorForStatus((dash = Autobiographical))[900], width:  mobile?'92px':'168px',
								height: mobile?'24px': '40px',borderRadius:'20px',marginLeft:mobile?"6px":'16px',fontSize:mobile?'10px':'18px',textTransform:'none'
								,minWidth:"40px" }} 
							  label="Autobiographical"
							onClick={
								(e) => {
									if (Autobiographical === null) {
									  setAutobiographical("Autobiographical");
									  setValue("words2", "Autobiographical");
									} else {
									  setAutobiographical(null);
									  setValue("words2", null);
									}
								  }
							} >
							Autobiographical
							 </Button>
						
						  <Button label="Biography" variant='outlined' 
							  style={{
								backgroundColor: colorForStatus((status = Biography))[100],
								
								color:colorForStatus((dash = Biography))[900], width:  mobile?'61px':'113px',
								height: mobile?'24px': '40px',borderRadius:'20px',marginLeft:mobile?"6px":'16px',fontSize:mobile?'10px':'18px',textTransform:'none'
								,minWidth:"40px" }}
							onClick={(e) => {
								if (Biography === null) {
									setBiography("Biography");
									setValue("words3", "Biography");
								  } else {
									setBiography(null);
									setValue("words2", null);
								  }
								  }} >Biography</Button>
						  <Button label="Candid" variant='outlined' 
							  style={{
								backgroundColor: colorForStatus((status = Candid))[100],
								
								color:colorForStatus((dash = Candid))[900], width:  mobile?'48px':'89px',
								height:  mobile?'24px':'40px',borderRadius:'20px',marginLeft:mobile?"0px":'16px',fontSize:mobile?'10px':'18px',textTransform:'none',marginTop:mobile?"6px":"0px"
								,minWidth:"40px"
							}} onClick={(e) => {
								if (Candid === null) {
									setCandid("Candid");
							  setValue("word4","Candid")  
								}
								else {
									setCandid(null);
							  setValue("word4",null)
								}
								  }} >Candid</Button>
						  
						  <Button  variant='outlined' label="Classic"
							  style={{
								backgroundColor: colorForStatus((status = Classic))[100],
								
								color:colorForStatus((dash = Classic))[900], width:mobile?'40px': '91px',
								height: mobile?'24px':'40px',borderRadius:'20px',marginLeft:mobile?"3px":'16px',fontSize:mobile?'10px':'18px',textTransform:'none',marginTop:mobile?"6px":"0px"
								,minWidth:"40px"
							}} onClick={(e) => {
								if (Classic === null) {
									setClassic("Classic");
									setValue("word5","Classic")
								}
								else {
									setClassic(null);
								  setValue("word5",null)
								}
								
							  }} >Classic</Button>
						  <Button variant='outlined' 
							  style={{
								backgroundColor: colorForStatus((status = Coming))[100],
								
								color:colorForStatus((dash = Coming))[900], width: mobile?'102px':'162px',
								  height:mobile?'24px': '40px', borderRadius: '20px', marginLeft:mobile?"3px": '16px', fontSize:mobile?'10px': '18px', textTransform: 'none',marginTop:mobile?"6px":"0px",
								  minWidth:"40px"
							  }}
							onClick={(e) => {
								if (Coming == null) {
									setComing("Coming-of-age");
									setValue("word6", "Coming-of-age")
								}
								else {
									setComing(null);
								setValue("word6", null)
								}
							}} >Coming-of-age</Button>
						
						{mobile ? '' :<>
						<Button label="Dark" variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Dark))[100],
						 
						  color:colorForStatus((dash = Dark))[900], width: '69px',
						  height: mobile?'24px':'40px',borderRadius:'20px',marginLeft:mobile?"6px":'16px',fontSize:mobile?'10px':'18px',textTransform:'none'
						  ,minWidth:"40px"}}
								onClick={(e) => {
									if (Dark === null) {
										setDark("Dark");
										setValue("word7","Dark")
									}
									else {
										setDark(null);
						setValue("word7",null)
									}
							}} >Dark</Button>
					<Button label="Deep"  variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Deep))[100],
						  
						  color:colorForStatus((dash = Deep))[900], width: '74px',
						  height: mobile?'24px':'40px',borderRadius:'20px',marginLeft:'16px',fontSize:mobile?'10px':'18px',textTransform:'none'
						  ,minWidth:"40px"}}
								onClick={(e) => {
									if (Deep === null) {
										setDeep("Deep");
										setValue("word8", "Deep")
									}
									else {
										setDeep(null);
					 					setValue("word8", null)
									}
							
				  }} >Deep</Button>
				  
				 
					<Button label="Diverse" variant='outlined'
					   style={{
						  backgroundColor: colorForStatus((status = Diverse))[100],
						 
						  color:colorForStatus((dash = Diverse))[900], width: '92px',
						   height:mobile?'24px': '40px', borderRadius: '20px', marginLeft:  '16px',fontSize:mobile?'10px':'18px',textTransform:'none',marginTop:'10px',marginBottom:"10px"
										, minWidth: "40px"
									}} onClick={(e) => {
										if (Diverse === null) {
											setDiverse("Diverse");
											setValue("word9","Diverse")
										} else {
											setDiverse(null);
											setValue("word9",null)	
							   }
							}} >Diverse</Button>
					<Button label="Educational" variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Educational))[100],
						 
						  color:colorForStatus((dash = Educational))[900], width: '127px',
						  height:mobile?'24px': '40px',borderRadius:'20px',marginLeft:'16px',fontSize:mobile?'10px':'18px',textTransform:'none'
										, minWidth: "40px"
									}} onClick={(e) => {
										if (Educational === null) {
											setEducational("Educational");
											setValue("word10","Educational")
										}
										else {
											setEducational(null);
							setValue("word10",null)
							  }
							}}>Educational</Button>
					<Button label="Empowering"  variant='outlined'
					  style={{
						  backgroundColor: colorForStatus((status = Empowering))[100],
						
						  color:colorForStatus((dash = Empowering))[900],width: '133px',
						  height:mobile?'24px': '40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						  
						}} onClick={(e) => {
							if (Empowering === null) {
								setEmpowering("Empowering");
							setValue("words11", "Empowering");
							}
							else {
								setEmpowering(null);
							setValue("words11", null);
							}
						}} >Empowering</Button>
				  <Button label="Emotional" variant='outlined'
					  style={{
						  backgroundColor: colorForStatus((status = Emotional))[100],
						  
						  color:colorForStatus((dash = Emotional))[900],width: '113px',
						  height:mobile?'24px': '40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						}} onClick={(e) => {
							if (Emotional === null) {
								setEmotional("Emotional");
							setValue("word12", "Emotional");
							}
							else {
								setEmotional(null);
								setValue("word12", null);
							}
						}} >Emotional</Button>
					<Button label="Fictional" variant='outlined'
					   style={{
						  backgroundColor: colorForStatus((status = Fictional))[100],
						  
						  color:colorForStatus((dash = Fictional))[900],width: '101px',
						  height:mobile?'24px': '40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						}} onClick={(e) => {
							if (Fictional === null) {
								setFictional("Fictional");
							setValue("words13", "Fictional");
							}
							else {
								setFictional(null);
								setValue("words13", null);
							}
									}} >Fictional</Button>
					<Button label="Funny"  variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Funny))[100],
					
						  color:colorForStatus((dash = Funny))[900],width: '81px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'12px':"18px",textTransform:'none',marginLeft:'16px'
						}} onClick={(e) => {
							if (Funny === null) {
								setFunny("Funny");
						setValue("words14","Funny")
										}
							else {
								setFunny(null);
						        setValue("words14",null)
									}}}>Funny </Button>
					<Button label="Futuristic" variant='outlined'
						style={{backgroundColor: colorForStatus((status = Futuristic))[100],
						  
						  color:colorForStatus((dash = Futuristic))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (Futuristic === null) {
								setFuturistic("Futuristic");
							  setValue("words15", "Futuristics")
							}
							else {
								setFuturistic(null);
							  setValue("words15", null)
							}
									}}>Futuristic</Button>
								{!showGriddesk && <Button style={{ width: '70px', height: "6px", fontSize: "16px", marginTop: '6px', textTransform: "none" }} onClick={() => setshowGriddesk(!showGriddesk)}>See all</Button>
								}	</>}
							{showGriddesk && <>
								<Button label="Historical" variant='outlined'
						style={{backgroundColor: colorForStatus((status = Historical))[100],
						  
						  color:colorForStatus((dash = Historical))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px',marginTop:'10px',marginBottom:'10px'
						   
						}} onClick={(e) => {
							if (Historical === null) {
								setHistorical("Historical");
							  setValue("words16", "Historical")
							}
							else {
								setHistorical(null);
							  setValue("words16", null)
							}
									}}>Historical</Button>
								<Button label="inspiring" variant='outlined'
						style={{backgroundColor: colorForStatus((status = Inspiring))[100],
						  
						  color:colorForStatus((dash = Inspiring))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (Inspiring === null) {
								setInspiring("Inspiring");
							  setValue("words17", "Inspiring")
							}
							else {
								setInspiring(null);
							  setValue("words17", null)
							}
									}}>Inspiring</Button>
								<Button label="modern" variant='outlined'
						style={{backgroundColor: colorForStatus((status = modern))[100],
						  
						  color:colorForStatus((dash = modern))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (modern === null) {
								setModern("modern");
							  setValue("words18", "modern")
							}
							else {
								setModern(null);
							  setValue("words18", null)
							}
									}}>Modern</Button>
								<Button label="mythological" variant='outlined'
						style={{backgroundColor: colorForStatus((status = mythological))[100],
						  
						  color:colorForStatus((dash = mythological))[900],width: '137px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (mythological === null) {
								setMythological("mythological");
							  setValue("words19", "mythological")
							}
							else {
								mythological(null);
							  setValue("words19", null)
							}
									}}>Mythological</Button>
								<Button label="Non-fiction" variant='outlined'
						style={{backgroundColor: colorForStatus((status =nonFiction ))[100],
						  
						  color:colorForStatus((dash = nonFiction))[900],width: '150px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (nonFiction === null) {
								setFiction("nonFiction");
							  setValue("words20", "nonFiction")
							}
							else {
								setFiction(null);
							  setValue("words20", null)
							}
									}}>Non-fiction
								</Button>
								<Button label="Non-fiction" variant='outlined'
						style={{backgroundColor: colorForStatus((status =witty ))[100],
						  
						  color:colorForStatus((dash = witty))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (witty === null) {
								setWitty("witty");
							  setValue("words23", "Witty")
							}
							else {
								setWitty(null);
							  setValue("words23", null)
							}
									}}>Witty
								</Button>
								<Button label="Spiritual" variant='outlined'
						style={{backgroundColor: colorForStatus((status = spiritual ))[100],
						  
						  color:colorForStatus((dash = spiritual))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px'
						   
						}} onClick={(e) => {
							if (spiritual === null) {
								setSpiritual("spiritual");
							  setValue("words21", "Spiritual")
							}
							else {
								setSpiritual(null);
							  setValue("words21", null)
							}
									}}>Spiritual
								</Button>
								<Button label="thriller" variant='outlined'
						style={{backgroundColor: colorForStatus((status = thriller))[100],
						  
						  color:colorForStatus((dash = thriller))[900],width: '107px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'16px',marginTop:"10px"
						   
						}} onClick={(e) => {
							if (thriller === null) {
								setThriller("thriller");
							  setValue("words22", "thriller")
							}
							else {
								setThriller(null);
							  setValue("words22", null)
							}
									}}>Thriller
								</Button>
							</>}
							
						{mobile &&
							<Button   style={{width:'30px',height:"6px",fontSize:"10px",marginTop:'6px',textTransform:"none",marginLeft:"-12px"}} onClick={() => setShowGrid(!showGrid)}>See all</Button>	
						}
						{(mobile && showGrid) &&<>
						
							<Grid item md={12}   sx={{ marginTop: mobile?"6px":'22px' }}>
							<Button label="Dark" variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Dark))[100],
							
							minWidth:"40px",
						  color:colorForStatus((dash = Dark))[900], width: '49px',
						  height: mobile?'24px':'40px',borderRadius:'20px',marginLeft:'6px',fontSize:mobile?'10px':'18px',textTransform:'none'
						}}
									onClick={(e) => {
										if (Dark === null) {
											setDark("Dark");
											setValue("word7", "Dark")
											
										}
										else {
											setDark(null);
						setValue("word7",null)
										}
							}} >Dark</Button>
					<Button label="Deep"  variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Deep))[100],
							
							minWidth:"40px",
						  color:colorForStatus((dash = Deep))[900], width: '54px',
						  height: mobile?'24px':'40px',borderRadius:'20px',marginLeft:'6px',fontSize:mobile?'10px':'18px',textTransform:'none'
						}}
									onClick={(e) => {
										if (Deep === null) {
											setDeep("Deep");
											setValue("word8", "Deep")
										}
										else {
											setDeep(null);
					                    setValue("word8", null)
										}
							
				  }} >Deep</Button>
					<Button label="Diverse" variant='outlined'
					   style={{
						  backgroundColor: colorForStatus((status = Diverse))[100],
						   
						   minWidth:"40px",
						  color:colorForStatus((dash = Diverse))[900], width: '52px',
						   height:mobile?'24px': '40px', borderRadius: '20px', marginLeft:'6px',fontSize:mobile?'10px':'18px',textTransform:'none'
									}} onClick={(e) => {
										if (Diverse === null) {
											setDiverse("Diverse");
											setValue("word9","Diverse")
										}
										else {
											setDiverse(null);
											setValue("word9", null)
										}
							}} >Diverse</Button>
					<Button label="Educational" variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Educational))[100],
							
							minWidth:"40px",
						  color:colorForStatus((dash = Educational))[900], width: '72px',
						  height:mobile?'24px': '40px',borderRadius:'20px',marginLeft:'6px',fontSize:mobile?'10px':'18px',textTransform:'none',amrginTop:"10px"
									}} onClick={(e) => {
										if (Educational === null) {
											setEducational("Educational");
											setValue("word10", "Educational")
										}
										else {
											setEducational(null);
										setValue("word10", null)
										}
							
									}}>Educational</Button>
								
					<Button label="Empowering"  variant='outlined'
					  style={{
						  backgroundColor: colorForStatus((status = Empowering))[100],
						
						  minWidth:"40px",
						  color:colorForStatus((dash = Empowering))[900],width: '72px',
						  height:mobile?'24px': '40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'6px',marginTop:"6px"
						  
									}} onClick={(e) => {
										if (Empowering === null) {
											setEmpowering("Empowering");
											setValue("words11", "Empowering");
										}
										else {
											setEmpowering(null);
							setValue("words11", null);
										}
							
						}} >Empowering</Button>
				  <Button label="Emotional" variant='outlined'
					  style={{
						  backgroundColor: colorForStatus((status = Emotional))[100],
						 
						  minWidth:"40px",
						  color:colorForStatus((dash = Emotional))[900],width: '73px',
						  height:mobile?'24px': '40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'6px',marginTop:"6px"
									}} onClick={(e) => {
										if (Emotional === null) {
											setEmotional("Emotional");
											setValue("word12", "Emotional");
										}
										else {
											setEmotional(null);
							setValue("word12", null);
										}
							
						}} >Emotional</Button>
					<Button label="Fictional" variant='outlined'
					   style={{
						  backgroundColor: colorForStatus((status = Fictional))[100],
						   
						   minWidth:"40px",
						  color:colorForStatus((dash = Fictional))[900],width: '71px',
						  height:mobile?'24px': '40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'6px',marginTop:"6px"
									}} onClick={(e) => {
										if (Fictional === null) {
											setFictional("Fictional");
											setValue("words13", "Fictional");
										}
										else {
											setFictional(null);
										setValue("words13", null);
										}
							          	
									}} >Fictional</Button>
					<Button label="Funny"  variant='outlined'
						style={{
						  backgroundColor: colorForStatus((status = Funny))[100],
							
							minWidth:"40px",
						  color:colorForStatus((dash = Funny))[900],width: '51px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':"18px",textTransform:'none',marginLeft:'6px',marginTop:"6px"
									}} onClick={(e) => {
										if (Funny === null) {
											setFunny("Funny");
											setValue("words14","Funny")
										}
										else {
											setFunny(null);
						setValue("words14",null)
										}
							}}>Funny </Button>
					<Button label="Futuristic" variant='outlined'
						style={{backgroundColor: colorForStatus((status = Futuristic))[100],
						
							minWidth:"40px",
						  color:colorForStatus((dash = Futuristic))[900],width: '71px',
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'6px',marginTop:"6px"
						   
									}} onClick={(e) => {
										if (Futuristic === null) {
											setFuturistic("Futuristic");
											setValue("words15", "Futuristics")
										}
										else {
											setFuturistic(null);
											setValue("words15", null);
										}
							
										}}>Futuristic</Button>
									<Button label="Historical" variant='outlined'
						style={{backgroundColor: colorForStatus((status = Historical))[100],
						  
						  color:colorForStatus((dash = Historical))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px',marginTop:'10px',marginBottom:'10px'
						   
						}} onClick={(e) => {
							if (Historical === null) {
								setHistorical("Historical");
							  setValue("words16", "Historical")
							}
							else {
								setHistorical(null);
							  setValue("words16", null)
							}
									}}>Historical</Button>
								<Button label="inspiring" variant='outlined'
						style={{backgroundColor: colorForStatus((status = Inspiring))[100],
						  
						  color:colorForStatus((dash = Inspiring))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px'
						   
						}} onClick={(e) => {
							if (Inspiring === null) {
								setInspiring("Inspiring");
							  setValue("words17", "Inspiring")
							}
							else {
								setInspiring(null);
							  setValue("words17", null)
							}
									}}>Inspiring</Button>
								<Button label="modern" variant='outlined'
						style={{backgroundColor: colorForStatus((status = modern))[100],
						  
						  color:colorForStatus((dash = modern))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px'
						   
						}} onClick={(e) => {
							if (modern === null) {
								setModern("modern");
							  setValue("words18", "modern")
							}
							else {
								setModern(null);
							  setValue("words18", null)
							}
									}}>Modern</Button>
								<Button label="mythological" variant='outlined'
						style={{backgroundColor: colorForStatus((status = mythological))[100],
						  
						  color:colorForStatus((dash = mythological))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px',marginBottom:"8px"
						   
						}} onClick={(e) => {
							if (mythological === null) {
								setMythological("mythological");
							  setValue("words19", "mythological")
							}
							else {
								mythological(null);
							  setValue("words19", null)
							}
									}}>Mythological</Button>
								<Button label="Non-fiction" variant='outlined'
						style={{backgroundColor: colorForStatus((status =nonFiction ))[100],
						  
						  color:colorForStatus((dash = nonFiction))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px',marginBottom:"8px"
						   
						}} onClick={(e) => {
							if (nonFiction === null) {
								setFiction("nonFiction");
							  setValue("words20", "nonFiction")
							}
							else {
								setFiction(null);
							  setValue("words20", null)
							}
									}}>Non-fiction
								</Button>
								<Button label="Non-fiction" variant='outlined'
						style={{backgroundColor: colorForStatus((status =witty ))[100],
						  
						  color:colorForStatus((dash = witty))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px'
						   
						}} onClick={(e) => {
							if (witty === null) {
								setWitty("witty");
							  setValue("words23", "Witty")
							}
							else {
								setWitty(null);
							  setValue("words23", null)
							}
									}}>Witty
								</Button>
								<Button label="Spiritual" variant='outlined'
						style={{backgroundColor: colorForStatus((status = spiritual ))[100],
						  
						  color:colorForStatus((dash = spiritual))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px'
						   
						}} onClick={(e) => {
							if (spiritual === null) {
								setSpiritual("spiritual");
							  setValue("words21", "Spiritual")
							}
							else {
								setSpiritual(null);
							  setValue("words21", null)
							}
									}}>Spiritual
								</Button>
								<Button label="thriller" variant='outlined'
						style={{backgroundColor: colorForStatus((status = thriller))[100],
						  
						  color:colorForStatus((dash = thriller))[900],
						  height: mobile?'24px':'40px',borderRadius:'20px',fontSize:mobile?'10px':'18px',textTransform:'none',marginLeft:'8px',marginTop:"10px"
						   
						}} onClick={(e) => {
							if (thriller === null) {
								setThriller("thriller");
							  setValue("words22", "thriller")
							}
							else {
								setThriller(null);
							  setValue("words22", null)
							}
									}}>Thriller
								</Button>

								</Grid>
								
						  </>}
						  </Grid>
						
						
						
    </Grid>
				  </Grid>
				  <Grid container  xs={12}>
					<Grid item md={12} xs={12} sx={{textAlign:'initial'}} >
			<Typography sx={{marginTop:mobile?'16px':'62px',marginLeft:mobile?'16px':"84px",fontSize:mobile?'12px':'18px'}}>Do you want your own dashboard? </Typography>		  
		
						</Grid>
					<Grid item md={6} xs={6} sx={{textAlign:'initial',marginTop:'16px'}} >
						<label htmlFor="field-rain" style={{ marginLeft: mobile ? '16px' : '82px', fontSize:mobile?'12px':'18px', lineHeight: mobile ? '16px' : "28px", marginTop: mobile ? '2px' : '8px' }}>
                    <input
                        {...register("dashboard")}
                        type="radio"
                        value="yes"
								id="field-rain"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    yes
                </label><br/>
                <label htmlFor="field-wind" style={{ marginLeft: mobile ? '16px' : '82px', fontSize:mobile?'12px':'18px', lineHeight: mobile ? '16px' : "28px", marginTop: mobile ? '2px' : '8px' }}>
                    <input
                        {...register("dashboard")}
                        type="radio"
                        value="no"
								id="field-wind"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    no
							</label><br/>
						</Grid>
						</Grid>
				<Grid container xs={12}	>
					<Grid item md={12} sx={{textAlign:'initial'}}>
			<Typography sx={{marginTop:mobile?'12px':'62px',marginLeft:mobile?'16px':"84px",fontSize:mobile?'12px': '18px'}}>What are you looking for ?</Typography>	
                
			</Grid>
			<Grid item md={6} sx={{textAlign:'initial',marginTop:mobile?'8px':'16px'}} >
			<label htmlFor="looking" style={{ marginLeft: mobile ? '16px' : '82px', fontSize:mobile?'12px':'18px', lineHeight: mobile ? '16px' : "28px", marginTop: mobile ? '2px' : '8px' }}>
                    <input 
                        {...register("look")}
                        type="radio"
                        value="Brand Awareness"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Brand Awareness
					  </label><br/>
					  <label htmlFor="looking" style={{ marginLeft: mobile ? '16px' : '82px', fontSize:mobile?'12px':'18px', lineHeight: mobile ? '16px' : "28px", marginTop: mobile ? '2px' : '8px' }}>
                    <input
                        {...register("look")}
                        type="radio"
                        value="Sales"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Sales
					  </label><br/>
					  <label htmlFor="looking" style={{ marginLeft: mobile ? '16px' : '82px', fontSize:mobile?'12px':'18px', lineHeight: mobile ? '16px' : "28px", marginTop: mobile ? '2px' : '8px' }}>
                    <input
                        {...register("look")}
                        type="radio"
                        value="Both"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Both
			</label><br/>
			</Grid>
			</Grid>
			<Grid container xs={12}>
			<Typography sx={{marginTop:mobile?'12px':'56px',marginLeft:mobile?'16px':"84px",fontSize:mobile?'12px': '18px'}}>What is your approximate advertising budget?</Typography>
			</Grid>
			<Grid item md={6} xs={6} sx={{textAlign:'initial',marginTop:mobile?'8px':'16px',marginLeft:mobile?'16px':'84px'}} >
					<label htmlFor="advt" style={{ marginLeft: mobile ? '0px' : '0px', fontSize:mobile?'12px':'18px', lineHeight: mobile ? '16px' : "28px", marginTop: mobile ? '2px' : '8px' }}>
                    <input
                        {...register("budget")}
                        type="radio"
                        value="10-50k (10,000 impressions)"
							id="field-sun"
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    10-50k (10,000 impressions)
					</label><br />
					
					<label htmlFor="advt" style={{ marginLeft: mobile ? '0px' : '0px', fontSize:mobile?'12px':'18px', lineHeight: mobile ? '16px' : "28px", marginTop: mobile ? '2px' : '8px' }}>
                    <input
                        {...register("budget")}
                        type="radio"
						value="50k-1Lac (20,000 impressions)"
							id="field-sun"
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    50k-1Lac (20,000 impressions)
					</label><br />
					<label htmlFor="advt" style={{ marginLeft: mobile ? '0px' : '0px', fontSize:mobile?'12px':'18px', lineHeight: mobile ? '16px' : "28px", marginTop: mobile ? '2px' : '8px' }}>
                    <input
                        {...register("budget")}
                        type="radio"
                        value=" 1Lac + (More than 30,000 impressions)"
						id="field-sun"
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                   1Lac + (More than 30,000 impressions)
			</label><br/>
					
				
					  </Grid>
					  <Grid container md={12}>
					 
					 <Grid item md={12} sx={{textAlign:'initial'}}>
						 <Typography  sx={{marginTop:mobile?'12px':'56px',marginLeft:mobile?'16px':'84px',fontSize:mobile?'12px':"18px"}}>Additional comments (Optional)</Typography>
					 </Grid>
					 <Grid item md={6} sx={{textAlign:'initial',marginLeft:mobile?'16px':'84px',marginTop:mobile?'12px':'32px'}}>
							   <TextField 
								  required
								  id="Comments"
								  name="Comments"
							placeholder='Write additional comments here'
							 fullWidth
								 size="small"
								 rows={mobile?3:4}
									 multiline
									 sx={{width: mobile?'242px':'679px'
									 ,height:mobile?'90px': '130px'}}
								  margin="dense"
								  {...register('Comments')}
								  error={errors.Comments ? true : false}
			 />
					 </Grid>
					 
						   </Grid>
					 
					
						   <Grid container md={12}>
					  <Button
              variant="contained"
              color="primary"
				onClick={handleSubmit(onSubmit)}
				sx={{backgroundColor:'#3A81F3',marginTop:mobile?'12px':'56px',marginLeft:mobile?'16px':'82px',textTransform: 'none',fontWeight:'500',fontSize:mobile?'12px':'16px',borderRadius:'8px',width: mobile?'52px':'91px',
				height: mobile?'36px':'48px'}}
            >
              Submit
					</Button>
					<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Order Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <CheckCircleOutlineIcon /> Thank you for your order! We have
              received your order and will process it shortly.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
			</Grid>	
			
				
			</Paper>
	{/* {mobile?"<DashboardFooter />":''}		 */}
			</Box>
		</>
  );
}
export default AmazonAds;