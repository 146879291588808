import React from 'react'
import { Paper,Box, Typography, Button } from '@mui/material';
import Dashboard_menu from './Dashboard_menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import DashboardFooter from './DashboardFooter';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
function Explore() {
	const mobile = useMediaQuery('(max-width:600px)');
	const navigate = useNavigate();
	const handleExplore =  (e) => {
		navigate('/Editing')
	};
	const emailhandle = () => {
		const emailAddress = 'Abhijeet@Hubhawks.com';
		window.location.href = `mailto:${emailAddress}`;
	};
	const phonehandle = () => {
		const Phone = '+91-7060495034';
		window.location.href = `tel:${Phone}`;
	  };
	const styles = {
		paperContainer: {
			backgroundImage: mobile?`url(https://drive.google.com/uc?export=view&id=19eIDGRfBybfg2NM3sqpc5st9g-Wgw7YA),url(https://drive.google.com/uc?export=view&id=10AfCZlkqBJNXIqQmUSA1fV5sWtNfj242)`:`url(https://drive.google.com/uc?export=view&id=11K46_gkQ5voIg-2ia3SwujJLoDtrbIQ2),url(https://drive.google.com/uc?export=view&id=12vB0TKVYwiTQHlHQuXqcOcjwDui3fspu)`,
			backgroundSize: mobile?'30%,38%':'11%,15%',
			backgroundPosition:'top left,bottom Right'
		}
	}
  return (
	  <>
		  <Dashboard_menu />
		  <Box sx={{backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'100%':'100%',height:mobile?'100vh':'115vh'}}>
    <Paper   sx={{position: 'absolute',
width:mobile?'289px': '72.96%',
height: mobile?'179px':'auto',backgroundPosition:mobile?'bottom right':'right',backgroundSize:'44%',
left: mobile?'24px':'32px',marginLeft:mobile?'24px':'240px',marginTop:mobile?"50px":"0px",
top:mobile?'90px': '70px',backgroundImage: "url(https://drive.google.com/uc?export=view&id=1XD9BM3-J3wosCLhg_BUoMS7d7SpEbYps)", backgroundRepeat: 'no-repeat',
				borderRadius: '12px'
			  }}>
				  <Grid container md={8} xs={12} sx={{}}>
					  <Grid item md={11} xs={11} sx={{margin:'auto',marginTop:mobile?'0px':"12px"}}>
					  <Typography sx={{fontSize:mobile?'14px':"24px",fontWeight:"600",textAlign:"initial",marginLeft:mobile?'16px':"26px",marginTop:mobile?'16px':"26px",lineHeight:"12px"}}>Explore Our Services
				  </Typography>  
					  </Grid>
					  <Grid item md={10} xs={11} sx={{margin:'auto'}}>
					  <Typography sx={{fontSize:mobile?'12px':"18px",textAlign:"initial",marginLeft:mobile?'16px':"0px",marginTop:mobile?'8px':"24px",lineHeight:mobile?"16px":'26px',color:'#333335'}}>We assist you in selecting the finest options for your book. Our services, which include editing, cover design, public relations, social media marketing, and many more, are intended to accentuate your literary experience.
				  </Typography>
					  </Grid>
					  <Grid item md={4} xs={4} sx={{margin:'initial',marginTop:mobile?'0px':"12px",marginBottom:mobile?"0px":"20px"}}>
				  <Button
              variant="contained"
              color="primary"
				sx={{marginTop:mobile?'8px':'0px',marginLeft:mobile?'16px':'0px',textTransform: 'none',fontWeight:'500',fontSize:mobile?'10px':'16px',borderRadius:'8px',width: mobile?'92px':'128px',
								  height: mobile ? '27px' : '48px', marginRight: mobile ? '141px' : '0px',backgroundColor:"#3A81F3"
							  }}
							  onClick={handleExplore}
            >
              Explore now
					  </Button>
					  </Grid>
				  </Grid> 
				  
			  </Paper>
			  
			  <Paper style={styles.paperContainer}  sx={{position: 'absolute',margin:'auto',
width:mobile?'289px': '72.96%',
height: mobile?'189px':'250px',backgroundPosition:mobile?'top left':'inherit',backgroundSize:mobile?'91px':'50%',
left: mobile?'24px':'32px',marginLeft:mobile?'24px':'240px',
top:mobile?'260px': '363px', backgroundRepeat: 'no-repeat',marginTop:mobile?"89px":"0px",

				borderRadius: '12px'
			  }}>
				  
				   <Grid container md={8} xs={12} lg={12} sx={{margin:'auto',marginTop:mobile?"0px":'56px'}}>
					  <Grid item md={8} xs={11} lg={11} sx={{margin:'auto',marginTop:mobile?"39px":"0px",marginBottom:mobile?"7px":"26px"}}>
						  <Typography sx={{
							  fontSize: mobile ? '20px' : "36px", fontWeight: "600", textAlign: "center", marginTop: mobile ? '16px' : "6px", lineHeight: mobile?"23px":"12px", marginRight:mobile?"0px":'27px'
						  }}>For Custom Order
				  </Typography>  
					  </Grid>
					  <Grid item md={8} xs={8} lg={11} sx={{marginTop:mobile?"9px":"26px",margin:mobile?'auto':'initial',marginBottom:mobile?"0px":"0px"}}>
					  <Button onClick={emailhandle} style={{ color: "#333335", textTransform: 'none', fontSize: mobile ? '14px' : "18px", fontWeight: '600' }}>
  <EmailOutlinedIcon style={{ color: "#333335", marginRight: '8px' }} />
  abhijeet@hubhawks.com
</Button>  </Grid>
					  <Grid item md={8} xs={7} lg={10} sx={{margin:'auto',marginTop:mobile?"0px":"0px",margin:mobile?'auto':'initial',marginBottom:mobile?"":"0px",marginLeft:mobile?'46px':"0px"}}>
					 
					  <Button onClick={phonehandle } style={{color:"#333335",fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><PhoneInTalkOutlinedIcon style={{color:"#333335", marginRight: '8px'}} /> +91-7060495034</Button>
					  </Grid>
					  
				
					
					  </Grid>
					  <Box style={{backgroundImage:`url(https://drive.google.com/uc?export=view&id=)`,backgroundRepeat:'no-repeat',backgroundPosition:mobile?'bottom Right':'inherit',backgroundSize:mobile?'91px':'50%'}}>
				
				  </Box>    
			  </Paper>
			  {/* {mobile?<DashboardFooter />:''}	 */}
			  </Box>
	  </>
  )
}
export default Explore;
