import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Box } from '@mui/system';
import Dashboard_au from './Dashboard_author';
import TextField from '@mui/material/TextField';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Paper, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionR from './Accordion';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from "@mui/material/Autocomplete";
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import DashboardFooter from './DashboardFooter';
import useMediaQuery from '@mui/material/useMediaQuery';

const questions = [
	{
	  id: 1,
	  question: "How can a professional editor help?",
		answer: "How can a professional editor help? ",
	  answer2:'There are different types of editing services available on Hubhawks. Here we define what is meant be “copy writing”, as they don’t always mean the same thing in the US as they do in the Uk and elsewhere.'
	},
	{
	  id: 2,
	  question: "Who will be doing the work for me?",
	  answer:"Who will be doing the work for me?",
	answer2:' A freelancer will be assigned to work on your project on the basis of the information you provide in the order form.Service I am looking for is not available on the websiteYou can connect with us on sales@hubhawks.com for any services that are not mentioned on the platform, our team will get in touch with you'
	},
	{
	  id: 3,
		question: "Can I get more than one service at a time?",
	  answer2:'Yes! We can create a custom order for you with all the services needed.',
	  answer:
			"Can I get more than one service at a time?"
	  
	},
	{
	  id: 4,
	  question: "How will work get assigned?",
	  answer:
			"How will work get assigned?",
	  answer2:"Projects will be assigned to you on the basis of the information you provide us in the onboarding form."
	},
	{
	  id: 5,
		question: "When will I get the payment?",
	  answer:'When will I get the payment?',
	  answer2:
		"The payment for the project will be credited in your wallet after the project completion is approved by the author."
	},
	{
	  id: 6,
	  question:
		" What will be the payment structure?",
	  answer:
			"What will be the payment structure?",
	  answer2:'The payment for the project will be credited in your wallet after the project completion is approved by the author. '
	}
  ];
  
function Faq() {
	const [isTextVisible, setIsTextVisible] = useState(true);
	const [isTextViible, setIsTextViible] = useState(true);
	const [isText,setText]=useState(true);
	const[ isText1,setText1]=useState(true);
	const[isText2,setText2]=useState(true);
	const [isText3, setText3] = useState(true);
	const [showDialog, setShowDialog] = useState(false);
	const [value, setValue] = React.useState(0);
	const mobile = useMediaQuery('(max-width:600px)');
	const [expanded, setExpanded] = React.useState(false);
	const [activeAccordion, setActiveAccordion] = React.useState(null);
	const [openAccordionId, setOpenAccordionId] = React.useState(null);
	const toggleAccordion = (id) => {
		setOpenAccordionId(id === openAccordionId ? null : id);
	
	};
  
	const ServicesE = [
		{ title: "Editing" ,value:'editing'},
		{ title: "Cover Designing"},
		{ title: "Translation" },
		{ title: "Book Store" },
		{ title: "Book Reviews" },{ title: "Book Trailer" },{ title: "Ghost Writing" },{ title: "Amazon Ads" }
	  ];
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
	};
	const phoneNumber = '+91-7060495034';
	const emailAddress = 'Abhijeet@Hubhawks.com';
	const handleOpen = () => {
		setShowDialog(true);
	 }
	const handleDialog = () => {
		setShowDialog(false);
	}
	
//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };
	const toggleText = () => {
		setIsTextVisible(!isTextVisible);
	};
	const toggleTxt = () => {
		setIsTextViible(!isTextViible);
	};
	const [selectedOptions, setSelectedOptions] = useState([]);
	const navigate = useNavigate();
	const handleChange11 = (event, value) => {
		setSelectedOptions(value);
		navigate(`../${value}`);
	}
	const text=()=>{
		setText(!isText)
	};
	const text1=()=>{
		setText1(!isText1)
	};
	const text2=()=>{
		setText2(!isText2)
	};
	const text3=()=>{
		setText3(!isText3)
	}
	return (<>
		
		<Dashboard_au />
		{mobile ? 
		<Box sx={{ backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background: mobile ? '' : '#F3F5F9', backgroundSize: mobile ? "100%" : '', width: mobile ? 'auto' : 'auto', height: mobile ? '912px' : '1000px' }}>
		  
		  {mobile ? '' :
				<Box sx={{ width: "78%", marginLeft: mobile ? '24px' : '272px', marginTop: '0px' }}>
					<Grid container md={12} lg={12} sx={{ marginTop: "0px" }}>
				<Grid container md={6} lg={6}>
					<Grid item md={9} lg={9} sx={{display:'flex',justifyContent:'start',marginTop:'24px'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>FAQ</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} lg={6} sx={{justifyContent:'end'}}>
					<Grid item md={6} lg={6} sx={{marginTop:'24px'}}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={4} lg={4} sx={{display:'flex',justifyContent:"center",marginTop:'24px'}}>
								<Button onClick={handleOpen} variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
								</Button>
								<Dialog open={showDialog} onClose={handleDialog}>
        <DialogContent style={{backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)"}}>
							  <DialogContentText>
								  <Typography variant='h5'>Get in touch with us:</Typography>
	  <Button  href={`mailto:${emailAddress}`} style={{color:"black",textTransform:'none',fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><EmailOutlinedIcon />Abhijeet@Hubhawks.com</Button><br/>
	  		
	  <Button href={`tel:${phoneNumber}`} style={{color:"black",fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><PhoneInTalkOutlinedIcon/>+91-7060495034</Button>
					
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)"}}>
          <Button onClick={handleDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
					</Grid>
				</Grid>
					</Grid>
				</Box>}
		  <Box  sx={{
width:mobile?'272px': '768px',
height: mobile?'48px':'96px',marginLeft:mobile?'24px':'260px',
				  top: mobile ? '90px' : '103px',
				  backgroundImage: `url(https://drive.google.com/uc?export=view&id=1tU5E3yVMy-ayoQsh1cOkb7uKZIwaA99J)`,
marginTop:mobile?"50px":"35px",
				borderRadius: '12px'
			  }}>
				  <Grid container xs={12} >
				  <Typography sx={{textAlign:"initial",color:"#FFFFFF",fontSize:mobile?"20px":"40px",marginLeft:"12px",marginTop:"10px"}}>FAQ</Typography> 
				  </Grid>
			  </Box>
		  <Accordion style={{width:mobile?"284px":"768px",marginLeft:mobile?'26px':'260px',marginTop:"30px",borderRadius:'12px'}}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{fontSize:mobile?'14px':'18px',lineHeight:mobile?'16px':'28px',textAlign:'initial'}}>
		  How can a professional editor help?	    </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontSize:mobile?'14px':'18px',textAlign:'center',lineHeight:'28px',textAlign:'initial'}}>
		  There are different types of editing services available on Hubhawks. Here we define what is meant be “copy writing”, as they don’t always mean the same thing in the US as they do in the Uk and elsewhere.
        
          </Typography>
        </AccordionDetails>
		  </Accordion>
		  <Box  sx={{marginTop:"20px",
width:mobile?'272px': '768px',
height: mobile?'48px':'96px',marginLeft:mobile?'24px':'266px',
top:mobile?'90px': '395px',
backgroundImage: `url(https://drive.google.com/uc?export=view&id=1tU5E3yVMy-ayoQsh1cOkb7uKZIwaA99J)`,
				borderRadius: '12px'
			  }}>
				<Grid container xs={12}>
				  <Typography sx={{textAlign:"initial",color:"#FFFFFF",fontSize:mobile?"20px":"40px",marginLeft:"12px",marginTop:"10px"}}>Author FAQ</Typography> 
				  </Grid> </Box>
      <Accordion style={{width:mobile?"284px":"768px",marginLeft:mobile?"26px":"260px",marginTop:"20px",borderRadius:'12px'}}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          
          <Typography sx={{fontSize:mobile?'14px':'18px',lineHeight:'28px',textAlign:'initial'}}>
		  Who will be doing the work for me?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography  sx={{fontSize:mobile?'14px':'18px',lineHeight:'28px',textAlign:'initial'}}>
		  A freelancer will be assigned to work on your project on the basis of the information you provide in the order form.

Service I am looking for is not available on the website.
You can connect with us on sales@hubhawks.com for any services that are not mentioned on the platform, our team will get in touch with you
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{width:mobile?"328px":"768px",marginLeft:mobile?"26px":"260px",borderRadius:'12px',marginTop:"30px"}}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          
          <Typography  sx={{fontSize:mobile?'14px':'18px',textAlign:'initial',lineHeight:'28px'}}>
		  Can I get more than one service at a time?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography  sx={{fontSize:mobile?'14px':'18px',textAlign:'initial',lineHeight:'28px'}}>
		  Yes! We can create a custom order for you with all the services needed.
          </Typography>
        </AccordionDetails>
		  </Accordion>
		  <Box  sx={{
width:mobile?'272px': '768px',
height: mobile?'48px':'96px',marginLeft:mobile?'24px':'260px',
marginTop:"20px",
backgroundImage: `url(https://drive.google.com/uc?export=view&id=1tU5E3yVMy-ayoQsh1cOkb7uKZIwaA99J)`,

				borderRadius: '12px'
			  }}>
			<Grid container xs={12}>
				  <Typography sx={{textAlign:"initial",color:"#FFFFFF",fontSize:mobile?"20px":"40px",marginLeft:"12px",marginTop:"10px"}}>Collaborators</Typography> 
				  </Grid>  </Box>
      <Accordion   style={{width:mobile?"284px":"768px",marginLeft:mobile?"26px":"260px",borderRadius:'12px',marginTop:"30px"}}
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  sx={{fontSize:mobile?'14px':'18px',textAlign:'initial',lineHeight:'28px'}}>
		  How will work get assigned?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography  sx={{fontSize:mobile?'14px':'18px',textAlign:'initial',lineHeight:'28px'}}>
		  Projects will be assigned to you on the basis of the information you provide us in the onboarding form.
          </Typography>
        </AccordionDetails>
		  </Accordion>
		  <Accordion   style={{width:mobile?"284px":"768px",marginLeft:mobile?"26px":"260px",borderRadius:'12px',marginTop:"30px"}}
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography  sx={{fontSize:mobile?'14px':'18px',textAlign:'initial',lineHeight:'28px'}}>
		  When will I get the payment?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography  sx={{fontSize:mobile?'14px':'18px',textAlign:'initial',lineHeight:'28px'}}>
		  The payment for the project will be credited in your wallet after the project completion is approved by the author.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion   style={{width:mobile?"284px":"768px",marginLeft:mobile?"26px":"260px",borderRadius:'12px',marginTop:"30px"}}
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
         
          <Typography sx={{fontSize:mobile?'14px':'18px',textAlign:'initial',lineHeight:'28px'}}>
		  What will be the payment structure?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography  sx={{fontSize:mobile?'14px':'18px',textAlign:'initial',lineHeight:'28px'}}>
          The payment for the project will be credited in your wallet after the project completion is approved by the author. 
          </Typography>
        </AccordionDetails>
      </Accordion>
     
	
			</Box> : <Box sx={{background: '#F3F5F9',height:'1000px'}}>
			{mobile ? '' :
				<Box sx={{ width: "78%", marginLeft: mobile ? '24px' : '272px', marginTop: '0px'
			}}>
					<Grid container md={12} lg={12} sx={{ marginTop: "0px" }}>
				<Grid container md={6} lg={6}>
					<Grid item md={9} lg={9} sx={{display:'flex',justifyContent:'start',marginTop:'24px'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>FAQ</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} lg={6} sx={{justifyContent:'end'}}>
					<Grid item md={6} lg={6} sx={{marginTop:'24px'}}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={4} lg={4} sx={{display:'flex',justifyContent:"center",marginTop:'24px'}}>
								<Button onClick={handleOpen} variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
								</Button>
								<Dialog open={showDialog} onClose={handleDialog}>
        <DialogContent style={{backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)"}}>
							  <DialogContentText>
								  <Typography variant='h5'>Get in touch with us:</Typography>
	  <Button  href={`mailto:${emailAddress}`} style={{color:"black",textTransform:'none',fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><EmailOutlinedIcon />Abhijeet@Hubhawks.com</Button><br/>
	  		
	  <Button href={`tel:${phoneNumber}`} style={{color:"black",fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><PhoneInTalkOutlinedIcon/>+91-7060495034</Button>
					
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)"}}>
          <Button onClick={handleDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
					</Grid>
				</Grid>
					</Grid>
					</Box>
				}
				<Box  sx={{
width:mobile?'272px': '80%',
height: mobile?'48px':'96px',marginLeft:mobile?'24px':'260px',
				  top: mobile ? '90px' : '103px',
				 
marginTop:mobile?"50px":"35px",position:'absolute',
				borderRadius: '12px'
			  }}>
				
      
      
        {questions.map((q) => (
          <AccordionR
				key={q.id}
				accordionId={q.id}
            question={q.question}
				answer={q.answer}
				answer2={q.answer2}
				openAccordionId={openAccordionId}
				toggleAccordion={toggleAccordion}
			setOpenAccordionId={setOpenAccordionId}
          />
        ))}
     
					
					</Box>
			</Box>}
		</>
  )
}
export default Faq;