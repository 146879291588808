import React from 'react'
//import * as React from 'react';
import { useRef } from "react";
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { useForm , Controller, FormProvider } from "react-hook-form";
import CssBaseline from '@mui/material/CssBaseline';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import Autocomplete from "@mui/material/Autocomplete";
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ResponsiveAppBar from '../components/menu';
import Image from "mui-image";
import Button from '@mui/material/Button';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import jwt_decode from "jwt-decode";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link, Navigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { borderColor } from '@mui/system';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Email_URL } from '../components/helper'
import { green, blue, grey, red } from "@mui/material/colors";
import Dashboard_au from './Dashboard_author';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DashboardFooter from './DashboardFooter';
// const pages = ['Home', 'Services', 'About', 'Contact', 'Blog', 'Signin'];
// const dashboard1=['Editing','CoverDesigning','Translation','GhostWriting','AmazonAds','BookReviews','BookTrailer','BookStore']
// const drawerWidth = 240;

const ServicesE = [
	{ title: "Editing" ,value:'editing'},
	{ title: "Cover Designing"},
	{ title: "Translation" },
	{ title: "Book Store" },
	{ title: "Book Reviews" },{ title: "Book Trailer" },{ title: "Ghost Writing" },{ title: "Amazon Ads" }
  ];

function BookReviews(props, status, r, profile) {
	const { register, watch,control,handleSubmit,setValue,reset, formState: { errors } } = useForm();
	const { window } = props;
	const form = useRef();
	const navigate = useNavigate();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const mobile = useMediaQuery('(max-width:600px)');
	const [adventurous, setAdventurous] = useState("");
	const [Autobiographical, setAutobiographical] = useState("");
	const [Biography, setBiography] = useState("");
	const [Candid, setCandid] = useState("");
	const [Classic, setClassic] = useState("");
	const [Coming, setComing] = useState("");
	const [Dark, setDark] = useState("");
	const [Deep, setDeep] = useState("");
	const [Diverse, setDiverse] = useState("");
	const [Educational, setEducational] = useState("");
	const [Empowering, setEmpowering] = useState("");
	const [Fictional, setFictional] = useState("");
	const [Futuristic, setFuturistic] = useState("");
	const [Funny, setFunny] = useState("");
	const [Emotional,setEmotional] = useState("");
	const [selectedOption, setSelectedOption] = useState("");
	const [textInput, setTextInput] = useState("");
	const [showDialog, setShowDialog] = useState(false);
	const [user, setUser] = useState({
		to: "rajeevkhanduja@gmail.com",
		subject: "",
		description: "",
		
	});
	const [open, setOpen] = React.useState(false);

	
	const getUserFromToken = () => {
		const token = localStorage.getItem("token");
		console.log(token, 'token');
		if (!token)
			return null;
		try {
			const decoded = jwt_decode(token);
			console.log(decoded, 'toksen');
		  return decoded;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	};
	const getUserFromToken1 = () => {
		const gogle = localStorage.getItem("user");
		console.log(gogle, 'token');
		if (!gogle)
			return null;
		try {
			const decoded = jwt_decode(gogle);
			console.log(decoded, 'toksen');
		  return decoded;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	  };
	const handleClose = () => {
		setOpen(false);
		reset();
	};
// 	const [color, setColor] = useState("");
	const [write, setWrite] = useState("");
	const [msg,setMsg] = useState('');
//   const handleColor = () => {
//     setColor("#3A81F3");
//     setTextColor("white");
//   };
const [value1, setValue1] = React.useState(5);

const handleChange = (event, newValue) => {
  setValue1(newValue);
	};
	const [selectedOptions, setSelectedOptions] = useState([]);
	const handleChange11 = (event, value) => {
		setSelectedOptions(value);
		navigate(`../${value}`);
	}
const handleTextInputChange = (event) => {
    setTextInput(event.target.value);
	};
	const handlewrite = (event) => {
		setWrite(event.target.value);
	}

	const onSubmit = async (data, e) => {
		setOpen(true);
		const formData = new FormData();
		console.log(data);
		e.preventDefault();
		const user1 = getUserFromToken1();
		const user = getUserFromToken();
		if (user) {
			
			if (!user) return;
			const response = await axios.post(`${Email_URL}/api/email/BookReviews`, {
				...data, user
			
			}).then((res) => res.json()).then(async (res) => {
				const resData = await res;
				console.log(resData);
				if (resData.status === "success") {
					alert("Message Sent");
				} else if (resData.status === "fail") {
					alert("Message failed to send");
				}
			})
			console.log(JSON.stringify(data), user, 'rajivk');
		}
		else {
			
			if (user1) {
				const user1 = getUserFromToken1();
			if (!user1) return;
			const response = await axios.post(`${Email_URL}/api/email/BookReviewsG`, {
				...data, user1
			
			}).then((res) => res.json()).then(async (res) => {
				const resData = await res;
				console.log(resData);
				if (resData.status === "success") {
					alert("Message Sent");
				} else if (resData.status === "fail") {
					alert("Message failed to send");
				}
			})
			console.log(JSON.stringify(data), user1, 'rajivk');
			}
		}
		console.log(JSON.stringify(data));
		console.log(FormData, 'hi');
	  };
	function colorForStatus(status) {
		switch (status) {
		  case "Adventurous":
		  case "Autobiographical":
			case "Biography":
			case "Candid":
			case "Classic":
			case "Coming":
			case "Dark":
			case "Deep":
			case "Diverse":
			case "Educational":
			case "Empowering":
			case "Fictional":
			case "Futuristic":
			case "Funny":
			case "Emotional":
				
				
			return blue;
		  
		  default:
			return grey;
		}
	}
	const handleLogout = () => {
		localStorage.removeItem("token");
		
		
	};
	const phoneNumber = '+91-7060495034';
	const emailAddress = 'Abhijeet@Hubhawks.com';
	const handleOpen = () => {
		setShowDialog(true);
	 }
	const handleDialog = () => {
		setShowDialog(false);
	 }
	const onValueChange=(event)=> {
		setSelectedOption(event.target.value);
		console.log(event.target.value);	
	  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
	};
	const handleClick = () => {
		console.info('You clicked the Chip.');
	  };

//   const drawer = (
//     <div>
// 		  <Toolbar />
// 		  <Box sx={{m:"auto"}}>
// 			  <Image duration={0} src="https://drive.google.com/uc?export=view&id=1DAyaOiRTn1fi2eVSohkY6-2XgHxuwLd5" style={{
//     width: '98px',
//     height: '105px'
// 			  }}></Image>
		  
//           </Box>
// 	  <Divider />
//       <List>
//         {["Progress", "Marketplace", "FAQ","setting"].map((text, index) => (
// 			<>
				
// 		  <ListItem key={text} disablePadding>
//             <ListItemButton>
//               <ListItemIcon>
//                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//               </ListItemIcon>
//               <ListItemText primary={text} />
//             </ListItemButton>
// 			</ListItem>
// 				<Divider />
				
// 				</>
// 		))}
			  
// 			  <button onClick={handleLogout}>
// 				<Link to ='/'>Logout</Link>	
// 				</button>
//       </List>
//     </div>
//   );

  const container = window !== undefined ? () => window().document.body : undefined;

	return (<>
		<Dashboard_au />
		<Box sx={{ backgroundImage: "url(https://drive.google.com/uc?export=view&id=1a9C2MSUDCGw-b7DDDTyCRZAcspkMvKWA)", background:mobile?'':'#F3F5F9',backgroundSize:mobile?"100%":'',display:"flex",width:mobile?'auto':'auto',height:mobile?'812px':'1250px'}}>
		{mobile ? '' :
				<Box sx={{ width: "78%", left: mobile ? '24px' : '272px', position: 'absolute', marginTop: '24px' }}>
				<Grid container md={12} sx={{ marginTop: "20px" }}>
				<Grid container md={6}>
					<Grid item md={9} sx={{display:'flex',justifyContent:'start'}}>
					<Typography sx={{fontSize:'36px',color:'#BB0000',fontWeight: '600'}}>Marketplace</Typography>	
					</Grid>
				</Grid>
				<Grid container md={6} sx={{justifyContent:'end'}}>
					<Grid item md={6}>
						<Autocomplete
							disableClearable
							value={ServicesE.value}
							onChange={handleChange11}
							style={{ backgroundColor: '#FFFFFF' }}
        options={ServicesE.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
				placeholder="Search for book services"	
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
		
					</Grid>
					<Grid item md={4} sx={{display:'flex',justifyContent:"center"}}>
					<Button onClick={handleOpen} variant="contained" color="primary" sx={{backgroundColor:'#3A81F3',textDecoration:'none',textTransform:'none',borderRadius:"8px",width:'119px',height:"48px"}}>
            Contact Us
						</Button>
						<Dialog open={showDialog} onClose={handleClose} sx={{width:'500px',height:'280px',justifyContent:'center',margin:'auto',borderRadius:'0px 0px 12px 12px'}}>
						<DialogTitle sx={{ background: '#3A81F3', color: '#FFFFFF', textAlign: 'center' }}>
						<Grid container lg={12} xs={12}>
							<Grid item lg={10} xs={10}>
							Contact Us
							</Grid>
							<Grid item lg={2} xs={2}>
							<Button onClick={handleDialog}><CloseIcon sx={{color:'#FFFFFF'}} /></Button>
			 
								</Grid>
								</Grid></DialogTitle>
        <DialogContent >
							  <DialogContentText>
								<Grid container lg={12}>
									<Grid container lg={10} sx={{margin:'auto'}}>
									
									<Grid item lg={10} sx={{margin:'auto'}}>
	  <Button  href={`mailto:${emailAddress}`} style={{color:"black",textTransform:'none',fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><EmailOutlinedIcon />Abhijeet@Hubhawks.com</Button>
	  </Grid>
	  		<Grid item lg={8} sx={{margin:'auto'}}>
	  <Button href={`tel:${phoneNumber}`} style={{color:"black",fontSize:mobile?'14px':"18px",fontWeight:'600'}} ><PhoneInTalkOutlinedIcon/>+91-7060495034</Button>
	  </Grid>
									</Grid>
								</Grid>
								  
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
					</Grid>
				</Grid>
					</Grid>
			</Box>}
			<Paper component='form' width="1152px" height='950px' sx={{
				position: 'absolute',
width:mobile?'272px': '75%',
height: mobile?'650px':'1098px',
left: mobile?'24px':'32px',marginLeft:mobile?'24px':'240px',
top:mobile?'90px': '115px',
background: '#FFFFFF',
				borderRadius: '12px'
			}}>
				{mobile?<Tabs
					value={value1}
					onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        	<Tab label="Editing" value1='0' style={{textTransform:'none'}} href="/Editing"  />
        <Tab label="Cover Designing" value1='1' style={{textTransform:'none'}}href="/CoverDesigning" />
        <Tab label="Translation" value1='2' style={{textTransform:'none'}}href="/Translation"/>
        <Tab label="Ghost Writing"value1='3'style={{textTransform:'none'}} href= "/GhostWriting"/>
        <Tab label="Amazon Ads"value1='4' style={{textTransform:'none'}}href=  "/AmazonAds"/>
        <Tab label="Book Reviews"value1='5' style={{textTransform:'none'}} href= "/BookReviews"/>
		<Tab label="Book Trailer"value1='6' style={{textTransform:'none'}}href="/BookTrailer" />
		<Tab label="Book store"value1='7' style={{textTransform:'none'}}href= "/Bookstore" />
		</Tabs>
					: 
			  <Tabs
			  style={{marginTop:"26px",marginLeft:'38px'}}	value={value1}
							onChange={handleChange}
				variant="scrollable"
				scrollButtons
				allowScrollButtonsMobile
				aria-label="scrollable force tabs example"
			  >
					<Tab label="Editing" value1='0' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link} to="/Editing"  />
						<Tab label="Cover Designing" value1='1' style={{ textTransform: 'none', color: '#000000', fontSize: '16px', fontWeight: 400 }} component={Link} to="/CoverDesigning" />
							<Tab label="Translation" value1='2' style={{ textTransform: 'none', color: '#000000', fontSize: '16px', fontWeight: 400 }} component={Link } to="/Translation"/>
        <Tab label="Ghostwriting"value1='3'style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to= "/GhostWriting"/>
        <Tab label="Amazon Ads"value1='4' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to=  "/AmazonAds"/>
        <Tab label="Book Reviews"value1='5' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:600}} component={Link } to= "/BookReviews"/>
		<Tab label="Book Trailer"value1='6' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link }to="/BookTrailer" />
		<Tab label="Bookstore"value1='7' style={{textTransform:'none',color:'#000000',fontSize:'16px',fontWeight:400}} component={Link } to= "/Bookstore" />
			</Tabs>
				  
				 }
    <Box component='form' encType="multipart/form-data"
      method="post"  onSubmit={handleSubmit} >
		
			<Grid container md={12}>
					<Grid item md={12}  sx={{textAlign:'initial'}}>
					<Typography sx={{marginTop:mobile?'16px':'56px',marginLeft:mobile?'16px':'84px',fontSize:mobile?'12px': "18px"}}>Choose the preferred platform </Typography>			
		</Grid>
					<Grid item md={6} sx={{textAlign:'initial',marginLeft:mobile?'16px':'84px',marginTop:'16px'}}>
					<label htmlFor="field-rain" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}>
                    <input
                        {...register("platform")}
                        type="radio"
                        value="Instagram"
								id="field-rain"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Instagram
                </label><br/>
                <label htmlFor="field-wind" style={{marginLeft:'2px',fontSize:mobile?'12px': '18px',lineHeight:"28px",marginTop:'8px'}}>
                    <input
                        {...register("platform")}
                        type="radio"
                        value="Goodreads"
								id="field-wind"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Goodreads
			</label><br/>
			<label htmlFor="field-wind" style={{marginLeft:'2px',fontSize:mobile?'12px':'18px',lineHeight:"28px",marginTop:'8px'}}>
                    <input
                        {...register("platform")}
                        type="radio"
                        value="Amazon"
								id="field-wind"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    Amazon
			</label>	
		</Grid>
				  </Grid>
				  
				<Grid container md={12} xs={12} >
					<Grid item md={12}xs={12}  sx={{textAlign:'initial'}}>
					<Typography  sx={{marginTop:mobile?'12px': '56px',marginLeft:mobile?'16px': '84px',fontSize:mobile?'12px':"18px"}}>How many reviews would you like to have?</Typography>
					</Grid>
					<Grid item md={6} xs={10} sx={{textAlign:'initial',marginLeft:mobile?'16px': '84px',marginTop:'16px'}}>
					<label htmlFor="looking" style={{fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("reviews")}
                        type="radio"
                        value="50"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    50
					  </label><br/>
					  <label htmlFor="looking" style={{fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("reviews")}
                        type="radio"
                        value="100"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    100
					  </label><br/>
					  <label htmlFor="looking" style={{fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("reviews")}
                        type="radio"
                        value="150"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    150
			</label><br/>
			<label htmlFor="looking" style={{fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("reviews")}
                        type="radio"
                        value="200"
								id="looking"
								style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:'12px'}}
                    />
                    200
			</label>
					</Grid>
				  </Grid>
		
				
                
				<Grid container md={12}>
				<Grid item md={12} sx={{textAlign:'initial'}}>
				<Typography  sx={{marginTop:mobile?'16px':'56px',marginLeft:mobile?'16px':'84px',fontSize:mobile?'12px':"18px"}} >Drop your amazon book link </Typography>
					</Grid>
					<Grid item md={6}sx={{textAlign:'initial',marginLeft:mobile?'16px':'84px',marginTop:mobile?'12px':'32px'}}>
						  <TextField 
							 required
							 id="Comments"
							 name="Comments"
							placeholder='Drop URL here'
							 fullWidth
							margin="dense"
								size="small"
								multiline
								
							 {...register('link')}
							 error={errors.Comments ? true : false}
        />
				</Grid>	
			</Grid>
			
				
				
				  <Grid container md={12}>
					 
				<Grid item md={12} sx={{textAlign:'initial'}}>
						  <Typography sx={{marginTop:mobile?'16px':'56px',marginLeft:mobile?'16px':'84px',fontSize:mobile?'12px':"18px"}}>Additional comments (Optional)</Typography>
					</Grid>
					<Grid item md={6} sx={{marginLeft:mobile?'16px':"84px",marginTop:mobile?'12px':"32px"}}>
						<TextField 
							 required
							 id="Comments"
							 name="Comments"
							 placeholder="Write additional comments here"
							 fullWidth
							 margin="dense"
							{...register('Comments')}
							rows={mobile?3:4}
								sx={{width: mobile?'242px':'679px'
								,height:mobile?'90px': '130px'}}
							multiline
							 error={errors.Comments ? true : false}
        />
				</Grid>
				
					  </Grid>
					 
					
					  
					  <Grid container md={12}>
					  <Button
              variant="contained"
              color="primary"
				onClick={handleSubmit(onSubmit)}
				sx={{backgroundColor:'#3A81F3',marginTop:mobile?'24px':'56px',marginLeft:mobile?'16px':'82px',textTransform: 'none',fontWeight:'500',fontSize:mobile?'12px':'16px',borderRadius:'8px',width: mobile?'52px':'91px',
				height: mobile?'36px':'48px'}}
            >
              Submit
						</Button>
						<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Order Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <CheckCircleOutlineIcon /> Thank you for your order! We have
              received your order and will process it shortly.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
			</Grid>	
			
			</Box>
			</Paper>
			{/* {mobile?<DashboardFooter />:''}	 */}
			</Box>
		</>
  );
}
export default BookReviews;