import React from 'react'
import Dashboard_au from '../Dashboard_author/Dashboard_author'
import { Box } from '@mui/system';
import {useMediaQuery} from '@mui/material';
import {Grid} from '@mui/material';
import {Button} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {TextField} from '@mui/material';
import {Image} from 'mui-image';
import Footer from '../footer';
import ResponsiveAppBar from './menu';
import CardMedia from '@mui/material/CardMedia';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import {Paper} from '@mui/material';
function Ghostwriting1  ()  {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showGrid, setShowGrid] = useState(false);
  const mobile = useMediaQuery('(max-width:600px)');
    const ipad = useMediaQuery('(min-width: 768px) and (max-width: 1180px)');
    const handleChange11 = (event, value) => {
      setSelectedOptions(value);
      navigate(`../${value}`);
  }
  const navigate = useNavigate();
  const handleExplore =  (e) => {
  navigate('/Signup')
};
  const ServicesE = [
      
    ];
	return (
	  
   <>
     <ResponsiveAppBar/>
			<Box sx={{ bgcolor: '#F3F5F9', width: mobile?"100%":"75%",height:mobile?'880px':'800px' }}>
				<Grid container lg={12}>
     <Grid item lg={12} md={12} xs={12}   sx={{textAlign: 'center'}}>
        <Typography variant='h2' sx={{textAlign:'center',fontSize:mobile?'20px':'36px',lineHeight:mobile?'24px':'48px',fontWeight:mobile?'600':'600',marginTop:"80px"}}>Ghostwriting</Typography>
        </Grid>
		</Grid>
<Grid container lg={8.5} xs={10}  sm={10} md={10} sx={{ margin:'auto',marginTop:'0px'}}>

                <Grid container md={7} xs={11} lg={9.5} sm={7} >
        
        
        <Grid container md={12} xs={12} lg={10.5}>
        <Grid item lg={12} md={12} xs={12}   sx={{textAlign: 'center'}}>
        <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'26px':'56px'}}>Are you looking for someone familiar with the subject of your book, and who is willing to spend the time to get to know your vision for the project?</Typography>
        </Grid>
        <Grid item lg={12} md={12} xs={12}   sx={{textAlign: 'center'}}>
        <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'16px':'26px'}}>Many best-selling publications, including fiction, business books, and autobiographies of famous people, are ghostwritten. You can employ a ghostwriter to write your book if you have a significant narrative to share but lack the writing ability (or perhaps the time) to put it on paper.</Typography>
        </Grid>
        <Grid item lg={11} md={12} xs={12}   sx={{textAlign: 'center'}}>
        <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'16px':'26px'}}>You've arrived at the right location. The writers at  Hubhawks have the required experience to complement the tone and personality of your book.</Typography>
        </Grid>
       
        <Grid item lg={11} md={12} xs={12}   sx={{textAlign: 'center'}}>
        <Typography variant='h2' sx={{textAlign:'initial',fontSize:mobile?'14px':'18px',lineHeight:mobile?'20px':'28px',fontWeight:mobile?'400':'400',marginTop:mobile?'16px':'26px'}}>Now, you can tell your tale just the way you like. Our  expert team immediately makes you feel secure and prepared to confide the innermost details of your narrative to the ghostwriter.</Typography>
        </Grid>
     
					{mobile?<Grid item xs={12} sx={{marginTop:'56px'}}>
					<Image src="https://drive.google.com/uc?export=view&id=1rJBih_1qdC5b43V0NjzQLY_t9fQOEGb4"
								style={{
									justifyContent: 'space-around', width: mobile ? '228px' : ipad ? "345px" : '483px', height:mobile?"170px":'233px', borderRadius:mobile?'0px': "36px",
									marginLeft: mobile ? '31px' : ipad ? "88px" : '0px', marginTop: mobile ? '-2px' : ipad ? "60px" : '18px', zIndex: '1'
								}}
								/>
								<Image height={0} src="https://drive.google.com/uc?export=view&id=1q_ploOzSWjm9XQc-7A5-5JOLlYVtAG55"
								style={{
									justifyContent: 'space-around', width: mobile ? '100px' : ipad ? "345px" : '195px', height: mobile ? '100px' : ipad ? "309px" : '195px', position: 'absolute', borderRadius: "36px",
									marginLeft: mobile ? '31px' : ipad ? "88px" : '0px', marginTop: mobile ? '-2px' : ipad ? "60px" : '18px', top: mobile?"auto":'590px', left: mobile?"1.5vh":'48%'
								}}
							/>
							</Grid>:''}	
        </Grid>
        </Grid>
       
     
        
					{mobile ? "" : <Grid container md={5} xs={11} lg={1} sm={5} sx={{ marginTop: '56px' }}>
        
						<Grid item lg={12} md={12} xs={12} sx={{ textAlign: 'center', }}>
        
				
							<Image src="https://drive.google.com/uc?export=view&id=1rJBih_1qdC5b43V0NjzQLY_t9fQOEGb4"
								style={{
									justifyContent: 'space-around', width: mobile ? '260px' : ipad ? "345px" : '483px', height: mobile ? '233px' : ipad ? "309px" : '417px', position: 'absolute', borderRadius:mobile?'0px': "0px",
									marginLeft: mobile ? '31px' : ipad ? "88px" : '0px', marginTop: mobile ? '-2px' : ipad ? "60px" : '18px', top: '300px', left: '55%', zIndex: '1'
								}}
							/>

							<Image height={0} src="https://drive.google.com/uc?export=view&id=1q_ploOzSWjm9XQc-7A5-5JOLlYVtAG55"
								style={{
									justifyContent: 'space-around', width: mobile ? '260px' : ipad ? "345px" : '195px', height: mobile ? '233px' : ipad ? "309px" : '195px', position: 'absolute', borderRadius: "36px",
									marginLeft: mobile ? '31px' : ipad ? "88px" : '0px', marginTop: mobile ? '-2px' : ipad ? "60px" : '18px', top: '590px', left: '48%'
								}}
							/>
			
         
						</Grid>
       
					</Grid>}
        </Grid>
		</Box>
        <Grid container lg={12}>
        <Button
  variant="contained"
  color="primary"
  sx={{
    textTransform: 'none',
    fontWeight: '500',
    fontSize: mobile ? '12px' : '16px',
    borderRadius: '8px',
    height: mobile ? '40px' : '48px',
    display: 'flex',
    margin: '56px auto',
  }}
  onClick={handleExplore}
  endIcon={<KeyboardArrowRightIcon />}
>
  Explore now
</Button>
            </Grid>
      
     <Footer/>
    
		</>
  )
}

export default Ghostwriting1