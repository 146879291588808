import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils';
import { Image } from 'mui-image';
import { useMediaQuery } from '@mui/material';
import {Box} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import 'react-swipeable-views/lib/styles.css';
import { Grid, IconButton, Typography } from '@mui/material';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));
const AutoPlaySwipeableViews = autoPlay(VirtualizeSwipeableViews);

const CarauselComponent = () => {
	const [activeSlide, setActiveSlide] = useState(0);
	const [size, setSize] = useState('0.6');
	const [width1, setWidth] = useState('50px');
	const mobile = useMediaQuery('(max-width:600px)');
  const slides = [
	{
		url: 'https://drive.google.com/uc?export=view&id=16ZQe1vtAQwdxEXB30tTLqjOtIdITSohy',
		width: '283px',
		  height: '192px',
		grid:'3.8'
	  },
	  {
		url: 'https://drive.google.com/uc?export=view&id=1B6ggZjpxIcqQ4ioAFU2ZwrTdUfScRbRV',
		width: '283px',
		  height: '192px',
		  grid:'3.8'
	  },
	  {
		url: 'https://drive.google.com/uc?export=view&id=1BCGwNd53FMr6IrnF26H1_rSSWINB3Z1R',
		width: '283px',
		  height: '192px',
		  
		  grid:'3.8'
	  },
	  {
		url: 'https://drive.google.com/uc?export=view&id=1BCGwNd53FMr6IrnF26H1_rSSWINB3Z1R',
		width: width1,
		height: '192px',
		grid:size
	  },
	  {
		url: 'https://drive.google.com/uc?export=view&id=16ZQe1vtAQwdxEXB30tTLqjOtIdITSohy',
		width: width1,
		height: '192px',
		grid:size
	  },
	  {
		url: 'https://drive.google.com/uc?export=view&id=1B6ggZjpxIcqQ4ioAFU2ZwrTdUfScRbRV',
		width: '283px',
		  height: '192px',
		  grid:'3.8'
	  },
	  {
		url: 'https://drive.google.com/uc?export=view&id=1BCGwNd53FMr6IrnF26H1_rSSWINB3Z1R',
		width: '283px',
		  height: '192px',
		  
		  grid:'3.8'
	  },
	  {
		url: 'https://drive.google.com/uc?export=view&id=1BCGwNd53FMr6IrnF26H1_rSSWINB3Z1R',
		width: width1,
		height: '192px',
		grid:size
	  },
	  {
		url: 'https://drive.google.com/uc?export=view&id=16ZQe1vtAQwdxEXB30tTLqjOtIdITSohy',
		width: '283px',
		  height: '192px',
		grid:'3.8'
	  },
	  {
		url: 'https://drive.google.com/uc?export=view&id=1B6ggZjpxIcqQ4ioAFU2ZwrTdUfScRbRV',
		width: '283px',
		  height: '192px',
		  grid:'3.8'
	  },
	  {
		url: 'https://drive.google.com/uc?export=view&id=1BCGwNd53FMr6IrnF26H1_rSSWINB3Z1R',
		width: '283px',
		  height: '192px',
		  
		  grid:'3.8'
	  },
	  {
		url: 'https://drive.google.com/uc?export=view&id=1BCGwNd53FMr6IrnF26H1_rSSWINB3Z1R',
		width: width1,
		height: '192px',
		grid:size
	  },
	  

    // 'https://drive.google.com/uc?export=view&id=1B6ggZjpxIcqQ4ioAFU2ZwrTdUfScRbRV',
    // 'https://drive.google.com/uc?export=view&id=1BCGwNd53FMr6IrnF26H1_rSSWINB3Z1R',
    // 'https://drive.google.com/uc?export=view&id=15OmNiKpMNUpUenNPBgC48J2gko96ypxR',
    // 'https://drive.google.com/uc?export=view&id=17A5F4ikjcb8_bNOMi_kqhSTJAxbAYfaf',
    // 'https://drive.google.com/uc?export=view&id=1AuMPaOGsDET-TS1mp-G4uKl5IbFGImHM',
    // 'https://drive.google.com/uc?export=view&id=1PPrepkJwQ1QnGGq8z1KJNiBNQHcyF_AT',
    // 'https://drive.google.com/uc?export=view&id=1CnD3N_7YOLVkRB5lq5hw449aCHuQXm0a',
    // Add more image URLs as needed
  ];

  const handleNextSlide = () => {
	  setActiveSlide((prevSlide) => (prevSlide + 1) % slides.length);
	  setSize(3.8);
	  
	  setWidth('283px');
	  if (activeSlide >1) {
		  setSize(0.6);
		  setWidth('50px');
	  }
  };

  const handlePrevSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };


  const totalSteps = 6; // Replace with the actual number of images in your carousel

  return (<>
	<Grid container lg={12} xs={10} sx={{margin:'auto',marginTop:mobile?'-80px':'0px'}} justifyContent="center">
	<Grid item  md={9} xs={11} lg={11} sx={{borderRadius:"16px 16px 0px 0px",marginBottom:mobile?'0px':'30px', }}>
			  <Grid container lg={12} xs={12} alignItems="center"sx={{marginBottom:mobile?'20px':'30px'}} >
				  
				  
            <Grid item lg={0.5} xs={0.5}>
            {!mobile && activeSlide !== 0 && (  <IconButton onClick={handlePrevSlide}>
                <ArrowLeft />
              </IconButton>)}
              {mobile && activeSlide !== 0 && (  <IconButton onClick={handlePrevSlide}>
                <ArrowLeft />
              </IconButton>)}
            </Grid>
          
		<Grid item xs={9.5} lg={11} container spacing={2}>
		  {slides.slice(activeSlide, activeSlide + (mobile ? 3 : 4)).map((slide, index) => (
			<Grid key={index} item xs={3} lg={slide.grid} sm={6} md={3} sx={{margin:mobile?'auto':''}}>
			  <img src={slide.url} alt={`Slide ${index + 1}`} style={{ width:slide.width, height:slide.height,marginLeft:mobile?'30px':'',borderRadius: '12px'}} />
			</Grid>
		  ))}
					  
				  </Grid>
				 
		<Grid item lg={0.5} xs={0.4}>
		  <IconButton onClick={handleNextSlide}>
			<ArrowRight />
		  </IconButton>
		</Grid>
	  </Grid>
		  </Grid>
		
	  </Grid>
	    
	  </>
		  
  );
};

export default CarauselComponent;