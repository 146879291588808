import React, { useState } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import { Box} from '@mui/material';
import {Grid} from '@mui/material';
import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Email_URL,BASE_URL } from '../components/helper'
import { useNavigate } from 'react-router-dom';
function ForgotPassword() {
	const mobile = useMediaQuery('(max-width:600px)');
	const navigate = useNavigate();
  const [email, setEmail] = useState('');
	const [otp, setOtp] = useState('');
	const [verify, SetVerify] = useState('');
	const [isOtpSent, setIsOtpSent] = useState(true);
	const [newPassword, setNewPassword] = useState("");
	const [passwordMatch, setPasswordMatch] = useState(true);
	const [pass, setPass] = useState(false);
  const [verificationSuccessful, setVerificationSuccessful] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Check if user exists in the database
      const userRes = await axios.post(`${Email_URL}/api/email/forgot-password`, { email }).then(async (res) => {
		const resData = await res;
		  console.log(resData,'response1');
		if (resData.status === 200) {
			alert("Check Your Mail for Otp");
			SetVerify(true);
			setIsOtpSent(false);
		} else if (resData.status === 404) {
			alert("User Not Found Please Check it Again");
		}
	})
      setUserExists(userRes.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      // Send a request to the server to verify the OTP
      const res = await axios.post(`${Email_URL}/api/email/verify-otp`, { email, otp }).then(async (res) => {
		const resData = await res;
		  console.log(resData,'response2');
		if (resData.status === 200) {
			alert("Verified User");
			SetVerify(false);
			setPass(true);
		} else if (resData.status === 404) {
			alert("Wrong OTP Entered");
		}
	})
      console.log(res,'sssseee');
    } catch (err) {
      console.error(err);
    }
  };
  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    if (event.target.value !== newPassword) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
	};
	const setPassword = async(e) => {
		e.preventDefault();
		try {
		  // Send a request to the server to verify the OTP
		  const res = await axios.post(`${Email_URL}/api/email/update-password`, { email, otp,newPassword }).then(async (res) => {
			const resData = await res;
			  console.log(resData,'response1');
			if (resData.status === 200) {
				alert("Password Updated");
				navigate('../Sign in')
			} else if (resData.status === "fail") {
				alert("Message failed to send");
			}
		})
		  console.log(res,'sssseee');
		} catch (err) {
		  console.error(err);
		}
		// Code to set the new password
		console.log('yes');
	  };
  return (
    <div>
 <Box   sx={{
        width: mobile?"300px":'527px',
        height: mobile?"295px":'404px',
        backgroundColor: '#F3F5F9',
        borderRadius:'20px',
        display:'flex',
        marginLeft:mobile?"30px":'456px',marginTop:'126px',marginBottom:mobile?"10px":'127px'
        
      }}>
        {isOtpSent ? (
            <Grid container md={8} xs={12} sx={{margin:'auto'}}>
                <form onSubmit={handleSubmit}>
         <Grid item md={12} xs={10} sx={{margin:mobile?'auto':''}}>
         <Typography sx={{marginLeft:'0px',fontSize:mobile?"24px":'36px',lineHeight:'48px',textAlign:'center',fontWeight:'600',marginTop:'28px'}}>
        Forgot password?
         </Typography>
         <Grid item md={8} xs={10}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:'15px',fontFamily: 'Roboto', fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>Email</Typography>
			</Grid>
            <Grid item md={12} xs={10}>
         <label htmlFor="field-rain" style={{fontSize:'18px',lineHeight:"28px",marginTop:'8px'}}></label>
             <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
			style={{width:mobile?"250px":'363px',
							height:mobile?"40px":'40px',marginRight:mobile?'6px':'12px'}}					  />
								  </Grid>
</Grid>
				
          <Grid item md={8} xs={10} sx={{margin:mobile?'auto':""}}>
							  <Button onClick={handleSubmit} type="submit" fullWidth variant="contained" sx={{ marginTop: '40px', borderRadius: '8px', width: '168px', background: '#3A81F3', textTransform: 'none', marginLeft:mobile?"0px": '82px', height: 'px', width: '208px' }}>Reset Password</Button>
						  </Grid>
          
        </form>
            </Grid>
       
         
        
      ) : (
        // <form onSubmit={handleVerify}>
        //   <input
        //     type="text"
        //     placeholder="Enter the OTP"
        //     value={otp}
        //     onChange={(e) => setOtp(e.target.value)}
        //   />
        //   <button type="submit">Verify OTP</button>
        // </form>
				  '')}
			  {verify?
					  <>
	 <Grid container md={10} xs={12} sx={{margin:'auto'}}>	
<Grid item md={8} xs={10}>
<Typography variant='h4' sx={{marginLeft:'0px',fontSize:mobile?"24px":'36px',lineHeight:'48px',textAlign:'center',fontWeight:'600',marginTop:'28px'}}>One Time Password</Typography>
<Grid item md={8} xs={10}sx={{textAlign:'initial'}}>
			<Typography variant='h4' sx={{ marginLeft:'0px',marginTop:'15px',fontFamily: 'Roboto', fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>OTP</Typography>
			</Grid>
								  <label htmlFor="field-rain" style={{ fontSize: '18px', lineHeight: "28px", marginTop: '8px' }}></label>
								  <Grid item md={8} xs={10}sx={{textAlign:'initial',margin:mobile?'auto':""}}>   
							  <input
type="text"
placeholder="Enter the OTP"
value={otp}
onChange={(e) => setOtp(e.target.value)}
style={{width:mobile?"250px":'363px',
height:mobile?"40px":'40px',marginRight:mobile?'6px':'12px'}}							  />
							 </Grid>
								  <Grid item md={12} xs={8} >
							  <Button onClick={handleVerify} type="submit" fullWidth variant="contained" sx={{ marginTop: '40px', borderRadius: '8px', width: '168px', background: '#3A81F3', textTransform: 'none', marginLeft: mobile?'0px':'82px', height: 'px', width: '208px' }}>Verify</Button>
						  </Grid>

						  </Grid>
						  
						  </Grid>
						  </>:""}
			  {pass ? <>
				  <Grid container xs={12} md={12} sx={{margin:'auto'}}>
					  <Grid item md={12} xs={12}>
					  <Typography sx={{marginLeft:'0px',fontSize:mobile?'24px':'36px',lineHeight:'48px',textAlign:'center',fontWeight:'600',marginTop:mobile?'0px':'28px'}}>
        Reset password?
						  </Typography>
					  </Grid>
					  <Grid item md={8} xs={10} sx={{ textAlign: 'initial', margin:'auto'}}>
						  <Typography variant='h4' sx={{ marginLeft: '0px', marginTop: '15px', fontFamily: 'Roboto', fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>New Password</Typography>
					  </Grid>
					  <Grid item md={12} xs={10} sx={{margin:mobile?"auto":''}}>
						  <input
type="text"
placeholder="Enter your new password"
value={newPassword}
onChange={handlePasswordChange}
style={{width:mobile?"250px":'363px',
height:mobile?"40px":'40px',marginRight:mobile?'6px':'12px'}}		/>
	</Grid>
<Grid item md={8} xs={10} sx={{ textAlign: 'initial', margin:'auto'}}>
<Typography variant='h4' sx={{marginTop:mobile?"15px":'30px',fontFamily: 'Roboto', fontSize: '18px', fontWeight: '600', fontStyle: 'normal' }}>Confirm Password</Typography>
					  </Grid>
					  <Grid item md={8} xs={10} sx={{ textAlign: 'initial', margin:'auto'}}>				  
						  <input
type="text"
placeholder="Enter your password"
onChange={handleConfirmPasswordChange}
style={{width:mobile?"250px":'363px',
height:mobile?"40px":'40px',marginRight:mobile?'6px':'12px'}}		/>
	{!passwordMatch && (
<div>Passwords do not match</div>
)}
{passwordMatch && (
							  
							  <Button onClick={setPassword} type="submit" fullWidth variant="contained" sx={{ marginTop: mobile?"15px":'40px', borderRadius: '8px', width: '168px', background: '#3A81F3', textTransform: 'none', marginLeft:mobile?'0px': '82px', height: 'px', width: '208px' }}>Set password</Button>
		)}
					  </Grid>
				  </Grid>
			  </> : ''}
    
 </Box>

      
    </div>
  );
}

export default ForgotPassword;
